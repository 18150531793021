import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import SSRContext from './service/SSRContext';
import { LANGUAGE } from './resources/constants';
import { LanguageResponse } from './generated';
import PhoenixTranslationFetcher from './service/PhoenixTranslationFetcher';

const defaultLanguage = 'eng';
const defaultNamespace = 'translation';
const supportedLngs = ['eng', 'por', 'spa', 'fra'];

export default function initializeI18n() {
    const language = (SSRContext.get(LANGUAGE) as LanguageResponse)?.code || defaultLanguage;

    // Initialize i18n if it is not yet initialized
    if (!i18n.isInitialized) {
        i18n.use(initReactI18next)
            .use(new PhoenixTranslationFetcher({}))
            .init(
                {
                    lng: language,
                    fallbackLng: defaultLanguage,
                    supportedLngs: supportedLngs,
                    ns: [], // No namespace
                    defaultNS: defaultNamespace,
                    react: {
                        useSuspense: SSRContext.isInClient(),
                    },
                    interpolation: {
                        escapeValue: false,
                    },
                    resources: SSRContext.getSSRTranslationResources(), // Pass the resources
                    initImmediate: SSRContext.isInClient(),
                },
                (err, t) => {
                    if (err) {
                        console.error('Failed to initialize i18next:', err);
                    }
                }
            );
    } // Check if the language is different from the currently loaded language
    else if (i18n.language !== language) {
        // Remove all existing bundles/resources to free memory
        Object.keys(i18n.store.data).forEach((lang) => {
            i18n.removeResourceBundle(lang, defaultNamespace); // Remove resources for the given language
        });

        // Load new resources for the new language from SSRContext
        const resources = SSRContext.getSSRTranslationResources();

        // Add the new resource bundle to i18next
        if (resources) {
            Object.keys(resources).forEach((lang) => {
                i18n.addResourceBundle(lang, defaultNamespace, resources[lang][defaultNamespace], true, true);
            });
        } else {
            console.warn(`No resources found for language "${language}".`);
        }

        // Set the current language to the new language
        i18n.changeLanguage(language);
    }

    i18n.on('failedLoading', (lng, ns, msg) => {
        console.error(`Failed to load translation for language "${lng}" and namespace "${ns}": ${msg}`);
    });

    i18n.on('missingKey', (lng, ns, key, res) => {
        console.warn(`Missing key "${key}" in namespace "${ns}" for language "${lng}"`);
    });

    if (SSRContext.isInServer()) {
        //@ts-ignore
        window.i18n = i18n;
    }
}
