import React, { useContext, useEffect, useState } from 'react';
import './Cart.scss';
import { Row, Col, Stack, Button, Form, Placeholder, Spinner } from 'react-bootstrap';
import { CartContext } from '../../provider/CartProvider';
import { TbShoppingBag, TbShoppingCartDiscount } from 'react-icons/tb';
import { ReactComponent as Mastercard } from '../../resources/image/payment/Mastercard-logo.svg';
import { ReactComponent as AmericanExpress } from '../../resources/image/payment/American_Express-Logo.wine.svg';
import { ReactComponent as Visa } from '../../resources/image/payment/Visa_2021.svg';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import SSRContext from '../../service/SSRContext';
import config from '../../resources/config.json';
import { PaymentControllerApi } from '../../generated';
import { API_CONFIG } from '../../resources/constants';
import { useTranslation } from 'react-i18next';

const paymentClient = new PaymentControllerApi(API_CONFIG);

export function Checkout() {
    const [fetchingCheckoutId, setFetchingCheckoutId] = useState<boolean>(false);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [coupon, setCoupon] = useState<string>('');

    const { isLoading, cart, applyCoupon } = useContext(CartContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (SSRContext.isInClient()) {
            // Load the Stripe library asynchronously when the component is mounted
            loadStripe(config.stripe.publicKey).then((stripeInstance) => {
                setStripe(stripeInstance);
            });
        }
    }, []);

    const handleCheckout = () => {
        if (cart?.cartItems && cart?.cartItems?.length > 0) {
            setFetchingCheckoutId(true);
            paymentClient
                .createCheckoutSession({
                    couponCode: coupon,
                })
                .then((sessionId) => {
                    if (!stripe) {
                        console.error('Stripe has not loaded');
                        return;
                    }
                    // Redirect to Stripe Checkout
                    stripe.redirectToCheckout({ sessionId });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setFetchingCheckoutId(false);
                });
        }
    };

    return (
        <div className="bg-light shadow p-3">
            <h3 className="display-4 fs-4 fw-normal mt-2">{t('CHECKOUT.orderDetails')}</h3>
            <hr />
            {isLoading ? (
                <LoadingCheckout />
            ) : (
                <>
                    <Form.Group as={Row} className="mb-3 mt-4">
                        <Form.Label className="fs-6" column xs="6">
                            {t('CHECKOUT.shipTo')}
                        </Form.Label>
                        <Col xs="6">
                            <Form.Select aria-label="Default select example">
                                <option value="pt">Portugal</option>
                                <option value="sp">Spain</option>
                                <option value="fr">France</option>
                                <option value="uk">United Kingdom</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Row className="mb-1">
                        <Col xs="6">{t('CHECKOUT.itemsTotal')}</Col>
                        <Col xs="6" className="text-end no-wrap">
                            {cart?.currencySymbol} {cart?.subtotal}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs="8">{t('CHECKOUT.shippingHandling')}</Col>
                        <Col xs="4" className="text-end no-wrap">
                            {cart?.currencySymbol} 0
                        </Col>
                    </Row>
                    <hr />
                    {(cart?.discountPrice ?? 0) > 0 && (
                        <Row className="mb-2">
                            <Col xs="6" style={{ display: 'inline-grid' }}>
                                <p className="m-0">{t('CHECKOUT.discountTotal')}</p>
                                <small className="text-muted fs-7">{cart?.coupon}</small>
                            </Col>
                            <Col xs="6" className="text-end no-wrap">
                                {cart?.currencySymbol} {cart?.discountPrice}
                            </Col>
                        </Row>
                    )}
                    <Row className="mb-1">
                        <Col xs="4">{t('CHECKOUT.orderTotal')}</Col>
                        <Col xs="8" className="text-end">
                            <Stack>
                                <strong className="no-wrap">
                                    {cart?.currencySymbol} {cart?.totalPrice}
                                </strong>
                                <small className="text-muted fs-7">{t('CHECKOUT.allTaxesIncluded')}</small>
                            </Stack>
                        </Col>
                    </Row>
                    <hr />
                    <Form.Group className="mt-4 mb-4 ">
                        <Form.Label>{t('CHECKOUT.discountCode')}</Form.Label>
                        <div className="d-flex flex-row">
                            <Form.Control type="text" onChange={(e) => setCoupon(e.target.value)} />
                            <Button
                                className="w-fit-content no-wrap ms-2"
                                onClick={() => {
                                    if (coupon) applyCoupon(coupon);
                                }}
                            >
                                <TbShoppingCartDiscount className="pb-1" size={20} /> {t('CHECKOUT.apply')}
                            </Button>
                        </div>
                    </Form.Group>
                    <hr />
                    <Button className="w-100" size="lg" onClick={handleCheckout} disabled={fetchingCheckoutId}>
                        {fetchingCheckoutId ? (
                            <Spinner />
                        ) : (
                            <>
                                <TbShoppingBag className="pb-1" size={25} /> {t('CHECKOUT.checkout')}
                            </>
                        )}
                    </Button>
                </>
            )}
            <Stack
                direction="horizontal"
                style={{
                    maxHeight: '30px',
                }}
            >
                <div className="ms-auto" />
                <div className="me-1">
                    <Visa
                        className="payment-method-logo mt-3"
                        width="45px"
                        style={{
                            padding: '6px',
                        }}
                    />
                </div>
                <div className="me-1">
                    <Mastercard className="payment-method-logo mt-3" width="45px" />
                </div>
                <div>
                    <AmericanExpress className="payment-method-logo mt-3" width="45px" />
                </div>
                <div className="ms-auto" />
            </Stack>
            <div className="text-center mt-1 text-muted">
                <small className="fs-7">
                    {t('CHECKOUT.transactionSecuredBy')} <strong>Stripe</strong>
                </small>
            </div>
        </div>
    );
}

function LoadingCheckout() {
    return (
        <>
            <Placeholder as={Row} animation="glow" className="mb-4 mt-4">
                <Col xs={6}>
                    <Placeholder className="w-100 rounded placeholder-md" bg="primary" />
                </Col>
                <Col xs={6}>
                    <Placeholder className="w-100 rounded placeholder-md" bg="secondary" />
                </Col>
            </Placeholder>
            <Placeholder as={Row} animation="glow" className="mb-2">
                <Col xs={6}>
                    <Placeholder className="w-100 rounded placeholder-md" bg="primary" />
                </Col>
                <Col xs={6} className="text-end">
                    <Placeholder className="w-100 rounded placeholder-md" bg="secondary" />
                </Col>
            </Placeholder>
            <Placeholder as={Row} animation="glow" className="mb-4">
                <Col xs={8}>
                    <Placeholder className="w-100 rounded placeholder-md" bg="primary" />
                </Col>
                <Col xs={4} className="text-end">
                    <Placeholder className="w-100 rounded placeholder-md" bg="secondary" />
                </Col>
            </Placeholder>
            <hr />
            <Placeholder as={Row} animation="glow" className="mb-1">
                <Col xs={4}>
                    <Placeholder className="w-100 rounded placeholder-md" bg="primary" />
                </Col>
                <Col xs={8} className="text-end">
                    <Placeholder className="w-100 rounded placeholder-md" bg="secondary" />
                </Col>
            </Placeholder>
            <hr />
            <Placeholder as={Row} animation="glow" className="mt-4 mb-4">
                <Col>
                    <Placeholder className="w-100 rounded placeholder-md" bg="primary" />
                </Col>
                <Col xs={3} md={4} className="align-self-end">
                    <Placeholder className="w-100 rounded placeholder-md" bg="secondary" size="sm" />
                </Col>
            </Placeholder>
            <hr />
            <Placeholder animation="glow" className="rounded w-100 mb-4">
                <Placeholder className="w-100 rounded  placeholder-lg" />
            </Placeholder>
        </>
    );
}
