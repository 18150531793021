/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNewsletterRequest,
  NewsletterResponse,
  SubscribeToNewsletterRequest,
} from '../models/index';
import {
    CreateNewsletterRequestFromJSON,
    CreateNewsletterRequestToJSON,
    NewsletterResponseFromJSON,
    NewsletterResponseToJSON,
    SubscribeToNewsletterRequestFromJSON,
    SubscribeToNewsletterRequestToJSON,
} from '../models/index';

export interface CreateNewsletterOperationRequest {
    createNewsletterRequest: CreateNewsletterRequest;
}

export interface SubscribeToNewsletterOperationRequest {
    newsletterName: string;
    subscribeToNewsletterRequest: SubscribeToNewsletterRequest;
}

export interface UnsubscribeFromNewsletterRequest {
    newsletterName: string;
    subscribeToNewsletterRequest: SubscribeToNewsletterRequest;
}

/**
 * 
 */
export class NewsletterControllerApi extends runtime.BaseAPI {

    /**
     */
    async createNewsletterRaw(requestParameters: CreateNewsletterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterResponse>> {
        if (requestParameters['createNewsletterRequest'] == null) {
            throw new runtime.RequiredError(
                'createNewsletterRequest',
                'Required parameter "createNewsletterRequest" was null or undefined when calling createNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/newsletter/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewsletterRequestToJSON(requestParameters['createNewsletterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterResponseFromJSON(jsonValue));
    }

    /**
     */
    async createNewsletter(requestParameters: CreateNewsletterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterResponse> {
        const response = await this.createNewsletterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeToNewsletterRaw(requestParameters: SubscribeToNewsletterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['newsletterName'] == null) {
            throw new runtime.RequiredError(
                'newsletterName',
                'Required parameter "newsletterName" was null or undefined when calling subscribeToNewsletter().'
            );
        }

        if (requestParameters['subscribeToNewsletterRequest'] == null) {
            throw new runtime.RequiredError(
                'subscribeToNewsletterRequest',
                'Required parameter "subscribeToNewsletterRequest" was null or undefined when calling subscribeToNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/newsletter/{newsletterName}`.replace(`{${"newsletterName"}}`, encodeURIComponent(String(requestParameters['newsletterName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeToNewsletterRequestToJSON(requestParameters['subscribeToNewsletterRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async subscribeToNewsletter(requestParameters: SubscribeToNewsletterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscribeToNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async unsubscribeFromNewsletterRaw(requestParameters: UnsubscribeFromNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['newsletterName'] == null) {
            throw new runtime.RequiredError(
                'newsletterName',
                'Required parameter "newsletterName" was null or undefined when calling unsubscribeFromNewsletter().'
            );
        }

        if (requestParameters['subscribeToNewsletterRequest'] == null) {
            throw new runtime.RequiredError(
                'subscribeToNewsletterRequest',
                'Required parameter "subscribeToNewsletterRequest" was null or undefined when calling unsubscribeFromNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/newsletter/{newsletterName}`.replace(`{${"newsletterName"}}`, encodeURIComponent(String(requestParameters['newsletterName']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeToNewsletterRequestToJSON(requestParameters['subscribeToNewsletterRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unsubscribeFromNewsletter(requestParameters: UnsubscribeFromNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeFromNewsletterRaw(requestParameters, initOverrides);
    }

}
