/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderItemResponse } from './OrderItemResponse';
import {
    OrderItemResponseFromJSON,
    OrderItemResponseFromJSONTyped,
    OrderItemResponseToJSON,
} from './OrderItemResponse';

/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    shippingMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    paymentMethodDetails?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    shippingAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    paymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    shippedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    deliveredDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    lastUpdatedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    estimatedDeliveryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    orderState?: OrderResponseOrderStateEnum;
    /**
     * 
     * @type {Array<OrderItemResponse>}
     * @memberof OrderResponse
     */
    orderItems?: Array<OrderItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    currency?: string;
}


/**
 * @export
 */
export const OrderResponseOrderStateEnum = {
    PaymentPending: 'PAYMENT_PENDING',
    PaymentProcessed: 'PAYMENT_PROCESSED',
    PaymentFailed: 'PAYMENT_FAILED',
    ProviderNotified: 'PROVIDER_NOTIFIED',
    OrderInProgress: 'ORDER_IN_PROGRESS',
    OrderReadyForPickup: 'ORDER_READY_FOR_PICKUP',
    PackageInTransit: 'PACKAGE_IN_TRANSIT',
    DeliveryConfirmed: 'DELIVERY_CONFIRMED',
    OrderCanceledSystem: 'ORDER_CANCELED_SYSTEM',
    OrderCanceledClient: 'ORDER_CANCELED_CLIENT',
    RefundInitiated: 'REFUND_INITIATED',
    RefundCompleted: 'REFUND_COMPLETED'
} as const;
export type OrderResponseOrderStateEnum = typeof OrderResponseOrderStateEnum[keyof typeof OrderResponseOrderStateEnum];


/**
 * Check if a given object implements the OrderResponse interface.
 */
export function instanceOfOrderResponse(value: object): value is OrderResponse {
    return true;
}

export function OrderResponseFromJSON(json: any): OrderResponse {
    return OrderResponseFromJSONTyped(json, false);
}

export function OrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'shippingMethod': json['shippingMethod'] == null ? undefined : json['shippingMethod'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'address': json['address'] == null ? undefined : json['address'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'country': json['country'] == null ? undefined : json['country'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'paymentMethodDetails': json['paymentMethodDetails'] == null ? undefined : json['paymentMethodDetails'],
        'shippingAmount': json['shippingAmount'] == null ? undefined : json['shippingAmount'],
        'paymentDate': json['paymentDate'] == null ? undefined : (new Date(json['paymentDate'])),
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'shippedDate': json['shippedDate'] == null ? undefined : (new Date(json['shippedDate'])),
        'deliveredDate': json['deliveredDate'] == null ? undefined : (new Date(json['deliveredDate'])),
        'lastUpdatedDate': json['lastUpdatedDate'] == null ? undefined : (new Date(json['lastUpdatedDate'])),
        'estimatedDeliveryDate': json['estimatedDeliveryDate'] == null ? undefined : (new Date(json['estimatedDeliveryDate'])),
        'totalAmount': json['totalAmount'] == null ? undefined : json['totalAmount'],
        'orderState': json['orderState'] == null ? undefined : json['orderState'],
        'orderItems': json['orderItems'] == null ? undefined : ((json['orderItems'] as Array<any>).map(OrderItemResponseFromJSON)),
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'discountAmount': json['discountAmount'] == null ? undefined : json['discountAmount'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function OrderResponseToJSON(value?: OrderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderId': value['orderId'],
        'shippingMethod': value['shippingMethod'],
        'trackingNumber': value['trackingNumber'],
        'address': value['address'],
        'city': value['city'],
        'state': value['state'],
        'postalCode': value['postalCode'],
        'country': value['country'],
        'paymentMethod': value['paymentMethod'],
        'paymentMethodDetails': value['paymentMethodDetails'],
        'shippingAmount': value['shippingAmount'],
        'paymentDate': value['paymentDate'] == null ? undefined : ((value['paymentDate']).toISOString()),
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'shippedDate': value['shippedDate'] == null ? undefined : ((value['shippedDate']).toISOString()),
        'deliveredDate': value['deliveredDate'] == null ? undefined : ((value['deliveredDate']).toISOString()),
        'lastUpdatedDate': value['lastUpdatedDate'] == null ? undefined : ((value['lastUpdatedDate']).toISOString()),
        'estimatedDeliveryDate': value['estimatedDeliveryDate'] == null ? undefined : ((value['estimatedDeliveryDate']).toISOString()),
        'totalAmount': value['totalAmount'],
        'orderState': value['orderState'],
        'orderItems': value['orderItems'] == null ? undefined : ((value['orderItems'] as Array<any>).map(OrderItemResponseToJSON)),
        'couponCode': value['couponCode'],
        'discountAmount': value['discountAmount'],
        'currency': value['currency'],
    };
}

