/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiscountDTO } from './DiscountDTO';
import {
    DiscountDTOFromJSON,
    DiscountDTOFromJSONTyped,
    DiscountDTOToJSON,
} from './DiscountDTO';

/**
 * 
 * @export
 * @interface CouponResponse
 */
export interface CouponResponse {
    /**
     * 
     * @type {string}
     * @memberof CouponResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponse
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof CouponResponse
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CouponResponse
     */
    eligibilityRule?: string;
    /**
     * 
     * @type {Array<DiscountDTO>}
     * @memberof CouponResponse
     */
    discounts?: Array<DiscountDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponResponse
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CouponResponse
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CouponResponse
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CouponResponse
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponResponse
     */
    modifiedBy?: string;
}

/**
 * Check if a given object implements the CouponResponse interface.
 */
export function instanceOfCouponResponse(value: object): value is CouponResponse {
    return true;
}

export function CouponResponseFromJSON(json: any): CouponResponse {
    return CouponResponseFromJSONTyped(json, false);
}

export function CouponResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouponResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'description': json['description'] == null ? undefined : json['description'],
        'expirationDate': json['expirationDate'] == null ? undefined : (new Date(json['expirationDate'])),
        'eligibilityRule': json['eligibilityRule'] == null ? undefined : json['eligibilityRule'],
        'discounts': json['discounts'] == null ? undefined : ((json['discounts'] as Array<any>).map(DiscountDTOFromJSON)),
        'active': json['active'] == null ? undefined : json['active'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function CouponResponseToJSON(value?: CouponResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'description': value['description'],
        'expirationDate': value['expirationDate'] == null ? undefined : ((value['expirationDate']).toISOString()),
        'eligibilityRule': value['eligibilityRule'],
        'discounts': value['discounts'] == null ? undefined : ((value['discounts'] as Array<any>).map(DiscountDTOToJSON)),
        'active': value['active'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

