/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPosterToCollectionRequest
 */
export interface AddPosterToCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPosterToCollectionRequest
     */
    collectionId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddPosterToCollectionRequest
     */
    posterIds: Array<string>;
}

/**
 * Check if a given object implements the AddPosterToCollectionRequest interface.
 */
export function instanceOfAddPosterToCollectionRequest(value: object): value is AddPosterToCollectionRequest {
    if (!('collectionId' in value) || value['collectionId'] === undefined) return false;
    if (!('posterIds' in value) || value['posterIds'] === undefined) return false;
    return true;
}

export function AddPosterToCollectionRequestFromJSON(json: any): AddPosterToCollectionRequest {
    return AddPosterToCollectionRequestFromJSONTyped(json, false);
}

export function AddPosterToCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPosterToCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'collectionId': json['collectionId'],
        'posterIds': json['posterIds'],
    };
}

export function AddPosterToCollectionRequestToJSON(value?: AddPosterToCollectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectionId': value['collectionId'],
        'posterIds': value['posterIds'],
    };
}

