/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemovePosterToWishlistRequest
 */
export interface RemovePosterToWishlistRequest {
    /**
     * 
     * @type {string}
     * @memberof RemovePosterToWishlistRequest
     */
    posterId?: string;
    /**
     * 
     * @type {number}
     * @memberof RemovePosterToWishlistRequest
     */
    sessionWishlistSize?: number;
}

/**
 * Check if a given object implements the RemovePosterToWishlistRequest interface.
 */
export function instanceOfRemovePosterToWishlistRequest(value: object): value is RemovePosterToWishlistRequest {
    return true;
}

export function RemovePosterToWishlistRequestFromJSON(json: any): RemovePosterToWishlistRequest {
    return RemovePosterToWishlistRequestFromJSONTyped(json, false);
}

export function RemovePosterToWishlistRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemovePosterToWishlistRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'sessionWishlistSize': json['sessionWishlistSize'] == null ? undefined : json['sessionWishlistSize'],
    };
}

export function RemovePosterToWishlistRequestToJSON(value?: RemovePosterToWishlistRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'sessionWishlistSize': value['sessionWishlistSize'],
    };
}

