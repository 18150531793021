import { Resource } from 'i18next';
import { SimpleUserResponse } from '../generated';
import { USER } from '../resources/constants';

export default class SSRContext {
    private static instance: SSRContext | null = null;

    private static SSRState: any;
    private state: any;
    private queryParams: any;
    private requestUrl?: string;
    private translationResources?: Resource;

    public static setState(state: any) {
        this.getInstance().state = state;
    }

    public static setSSRState(window: any) {
        try {
            this.SSRState = window?.globalState;
            this.getInstance().translationResources = JSON.parse(window?.translationResources ?? {});
            this.getInstance().requestUrl = window?.requestUrl ?? window?.location?.pathname;
            this.getInstance().queryParams = JSON.parse(window?.queryParams || undefined);
            this.getInstance().state = JSON.parse(JSON.parse(this.SSRState?.data));
            Object.keys(this.SSRState)
                .filter((key) => key !== 'data')
                .forEach((key) => (this.getInstance().state[key] = JSON.parse(this.SSRState[key])));
        } catch (e) {
            console.error(e);
        }
    }

    public static getUser(): SimpleUserResponse | undefined {
        return this.get(USER);
    }

    public static get(key: string): any {
        try {
            return SSRContext.getInstance()?.state ? SSRContext.getInstance()?.state[key] : undefined;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public static has(key: string): boolean {
        return SSRContext.getInstance()?.state?.hasOwnProperty(key);
    }

    public static setStatusCode(statusCode: number) {
        if (this.isInServer()) {
            this.SSRState.statusCode = statusCode;
        }
    }

    public static getInstance(): SSRContext {
        if (!this.instance) this.instance = new SSRContext();
        return this.instance;
    }

    public static getRequestUrl(): string | undefined {
        return SSRContext.getInstance().requestUrl ?? window?.location?.pathname;
    }

    public static print() {
        console.log(JSON.stringify(this.getInstance().state));
        console.log('++++');
        console.log(JSON.stringify(this.SSRState));
        console.log('++++');
        console.log(this.getInstance().state);
    }

    public static isInServer(): boolean {
        return !!this.SSRState;
    }

    public static isInClient(): boolean {
        return !this.SSRState;
    }

    public static getQueryParameter(key: string): string | undefined {
        try {
            if (this.isInServer()) {
                if (SSRContext.getInstance()?.queryParams && Object.keys(SSRContext.getInstance()?.queryParams).includes(key)) {
                    return SSRContext.getInstance()?.queryParams[key] ?? undefined;
                }
            } else {
                return new URLSearchParams(window.location.search).get(key) ?? undefined;
            }
        } catch (e) {
            console.error(e);
        }
        return undefined;
    }

    public static getQueryParameters(): URLSearchParams {
        try {
            if (this.isInServer() && SSRContext.getInstance()?.queryParams) {
                const params = SSRContext.getInstance()?.queryParams;
                const searchParams = new URLSearchParams();

                // Iterate through the queryParams object and set each key-value pair in URLSearchParams
                Object.entries(params).forEach(([key, value]) => {
                    searchParams.set(key, value as string);
                });

                return searchParams;
            } else {
                return new URLSearchParams(window.location.search);
            }
        } catch (e) {
            console.error(e);
            return new URLSearchParams();
        }
    }

    public static getSSRTranslationResources(): Resource | undefined {
        return SSRContext.getInstance().translationResources;
    }
}
