/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderItemResponse
 */
export interface OrderItemResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    orderItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    posterId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    posterName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    posterPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    posterImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    sizeId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    sizeName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    sizePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    frameId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    frameName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    framePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    frameImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    materialName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    materialPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    materialImage?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    subTotalAmount?: number;
}

/**
 * Check if a given object implements the OrderItemResponse interface.
 */
export function instanceOfOrderItemResponse(value: object): value is OrderItemResponse {
    return true;
}

export function OrderItemResponseFromJSON(json: any): OrderItemResponse {
    return OrderItemResponseFromJSONTyped(json, false);
}

export function OrderItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'orderItemId': json['orderItemId'] == null ? undefined : json['orderItemId'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'posterName': json['posterName'] == null ? undefined : json['posterName'],
        'posterPrice': json['posterPrice'] == null ? undefined : json['posterPrice'],
        'posterImage': json['posterImage'] == null ? undefined : json['posterImage'],
        'sizeId': json['sizeId'] == null ? undefined : json['sizeId'],
        'sizeName': json['sizeName'] == null ? undefined : json['sizeName'],
        'sizePrice': json['sizePrice'] == null ? undefined : json['sizePrice'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'frameId': json['frameId'] == null ? undefined : json['frameId'],
        'frameName': json['frameName'] == null ? undefined : json['frameName'],
        'framePrice': json['framePrice'] == null ? undefined : json['framePrice'],
        'frameImage': json['frameImage'] == null ? undefined : json['frameImage'],
        'materialId': json['materialId'] == null ? undefined : json['materialId'],
        'materialName': json['materialName'] == null ? undefined : json['materialName'],
        'materialPrice': json['materialPrice'] == null ? undefined : json['materialPrice'],
        'materialImage': json['materialImage'] == null ? undefined : json['materialImage'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'totalAmount': json['totalAmount'] == null ? undefined : json['totalAmount'],
        'subTotalAmount': json['subTotalAmount'] == null ? undefined : json['subTotalAmount'],
    };
}

export function OrderItemResponseToJSON(value?: OrderItemResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderItemId': value['orderItemId'],
        'orderId': value['orderId'],
        'posterId': value['posterId'],
        'posterName': value['posterName'],
        'posterPrice': value['posterPrice'],
        'posterImage': value['posterImage'],
        'sizeId': value['sizeId'],
        'sizeName': value['sizeName'],
        'sizePrice': value['sizePrice'],
        'width': value['width'],
        'height': value['height'],
        'frameId': value['frameId'],
        'frameName': value['frameName'],
        'framePrice': value['framePrice'],
        'frameImage': value['frameImage'],
        'materialId': value['materialId'],
        'materialName': value['materialName'],
        'materialPrice': value['materialPrice'],
        'materialImage': value['materialImage'],
        'quantity': value['quantity'],
        'currency': value['currency'],
        'totalAmount': value['totalAmount'],
        'subTotalAmount': value['subTotalAmount'],
    };
}

