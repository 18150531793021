/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimpleOrderResponse } from './SimpleOrderResponse';
import {
    SimpleOrderResponseFromJSON,
    SimpleOrderResponseFromJSONTyped,
    SimpleOrderResponseToJSON,
} from './SimpleOrderResponse';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface AdminUserInformation
 */
export interface AdminUserInformation {
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    preferredCurrency?: string;
    /**
     * 
     * @type {Address}
     * @memberof AdminUserInformation
     */
    address?: Address;
    /**
     * 
     * @type {Date}
     * @memberof AdminUserInformation
     */
    birthdate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminUserInformation
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminUserInformation
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof AdminUserInformation
     */
    roles?: Set<AdminUserInformationRolesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserInformation
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUserInformation
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserInformation
     */
    anonymous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserInformation
     */
    cat?: boolean;
    /**
     * 
     * @type {Array<SimpleOrderResponse>}
     * @memberof AdminUserInformation
     */
    orders?: Array<SimpleOrderResponse>;
}


/**
 * @export
 */
export const AdminUserInformationRolesEnum = {
    User: 'USER',
    Provider: 'PROVIDER',
    Admin: 'ADMIN'
} as const;
export type AdminUserInformationRolesEnum = typeof AdminUserInformationRolesEnum[keyof typeof AdminUserInformationRolesEnum];


/**
 * Check if a given object implements the AdminUserInformation interface.
 */
export function instanceOfAdminUserInformation(value: object): value is AdminUserInformation {
    return true;
}

export function AdminUserInformationFromJSON(json: any): AdminUserInformation {
    return AdminUserInformationFromJSONTyped(json, false);
}

export function AdminUserInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'preferredLanguage': json['preferredLanguage'] == null ? undefined : json['preferredLanguage'],
        'preferredCurrency': json['preferredCurrency'] == null ? undefined : json['preferredCurrency'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'birthdate': json['birthdate'] == null ? undefined : (new Date(json['birthdate'])),
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'roles': json['roles'] == null ? undefined : json['roles'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'image': json['image'] == null ? undefined : json['image'],
        'anonymous': json['anonymous'] == null ? undefined : json['anonymous'],
        'cat': json['cat'] == null ? undefined : json['cat'],
        'orders': json['orders'] == null ? undefined : ((json['orders'] as Array<any>).map(SimpleOrderResponseFromJSON)),
    };
}

export function AdminUserInformationToJSON(value?: AdminUserInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'preferredLanguage': value['preferredLanguage'],
        'preferredCurrency': value['preferredCurrency'],
        'address': AddressToJSON(value['address']),
        'birthdate': value['birthdate'] == null ? undefined : ((value['birthdate']).toISOString()),
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'roles': value['roles'] == null ? undefined : Array.from(value['roles'] as Set<any>),
        'enabled': value['enabled'],
        'image': value['image'],
        'anonymous': value['anonymous'],
        'cat': value['cat'],
        'orders': value['orders'] == null ? undefined : ((value['orders'] as Array<any>).map(SimpleOrderResponseToJSON)),
    };
}

