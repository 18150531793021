/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortObject } from './SortObject';
import {
    SortObjectFromJSON,
    SortObjectFromJSONTyped,
    SortObjectToJSON,
} from './SortObject';

/**
 * 
 * @export
 * @interface SearchCollectionFilter
 */
export interface SearchCollectionFilter {
    /**
     * 
     * @type {string}
     * @memberof SearchCollectionFilter
     */
    searchTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCollectionFilter
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCollectionFilter
     */
    order?: SearchCollectionFilterOrderEnum;
    /**
     * 
     * @type {SortObject}
     * @memberof SearchCollectionFilter
     */
    sort?: SortObject;
}


/**
 * @export
 */
export const SearchCollectionFilterOrderEnum = {
    New: 'NEW',
    Relevant: 'RELEVANT',
    Hot: 'HOT'
} as const;
export type SearchCollectionFilterOrderEnum = typeof SearchCollectionFilterOrderEnum[keyof typeof SearchCollectionFilterOrderEnum];


/**
 * Check if a given object implements the SearchCollectionFilter interface.
 */
export function instanceOfSearchCollectionFilter(value: object): value is SearchCollectionFilter {
    return true;
}

export function SearchCollectionFilterFromJSON(json: any): SearchCollectionFilter {
    return SearchCollectionFilterFromJSONTyped(json, false);
}

export function SearchCollectionFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchCollectionFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerm': json['searchTerm'] == null ? undefined : json['searchTerm'],
        'category': json['category'] == null ? undefined : json['category'],
        'order': json['order'] == null ? undefined : json['order'],
        'sort': json['sort'] == null ? undefined : SortObjectFromJSON(json['sort']),
    };
}

export function SearchCollectionFilterToJSON(value?: SearchCollectionFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerm': value['searchTerm'],
        'category': value['category'],
        'order': value['order'],
        'sort': SortObjectToJSON(value['sort']),
    };
}

