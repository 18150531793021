import React from 'react';
import { Field, ErrorMessage, FormikProps } from 'formik';
import { Form as BSForm, Button, Col, Row, Spinner } from 'react-bootstrap';
import { TbDeviceFloppy } from 'react-icons/tb';
import { UserResponse } from '../../generated';
import { useTranslation } from 'react-i18next';

type Props = {
    readonly editable?: boolean;
    readonly formikProps: FormikProps<UserResponse>;
};

export default function UserAddressForm({ editable, formikProps }: Props) {
    const { t } = useTranslation(); // Initialize translation
    const { isSubmitting, errors, touched, values } = formikProps;

    return (
        <>
            <BSForm.Group className="mb-3">
                <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.address')}</BSForm.Label>
                {editable ? (
                    <>
                        <Field
                            type="text"
                            name="address.addressLine"
                            as={BSForm.Control}
                            placeholder={t('USER_ADDRESS_FORM.streetAddress')}
                            // @ts-ignore
                            className={`form-control ${errors.address?.addressLine && touched.address?.addressLine ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage name="address.addressLine" component="div" className="invalid-feedback fs-7" />
                    </>
                ) : (
                    <>
                        {values.address?.addressLine ? (
                            <p className="user-form-value">{values.address?.addressLine}</p>
                        ) : (
                            <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noAddressFound')}</p>
                        )}
                    </>
                )}
            </BSForm.Group>

            <Row>
                <Col md={4}>
                    <BSForm.Group controlId="postalCode" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.postalCode')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="address.postalCode"
                                    as={BSForm.Control}
                                    placeholder={t('USER_ADDRESS_FORM.postalCodePlaceholder')}
                                    // @ts-ignore
                                    className={`form-control ${errors.address?.postalCode && touched.address?.postalCode ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="address.postalCode" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.address?.postalCode ? (
                                    <p className="user-form-value">{values.address?.postalCode}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noPostalCodeFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>

                <Col md={8}>
                    <BSForm.Group controlId="city" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.city')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="address.city"
                                    as={BSForm.Control}
                                    placeholder={t('USER_ADDRESS_FORM.cityPlaceholder')}
                                    // @ts-ignore
                                    className={`form-control ${errors.address?.city && touched.address?.city ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="address.city" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.address?.city ? (
                                    <p className="user-form-value">{values.address?.city}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noCityFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <BSForm.Group controlId="country" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.country')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="address.country"
                                    as={BSForm.Control}
                                    placeholder={t('USER_ADDRESS_FORM.countryPlaceholder')}
                                    // @ts-ignore
                                    className={`form-control ${errors.address?.country && touched.address?.country ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="address.country" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.address?.country ? (
                                    <p className="user-form-value">{values.address?.country}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noCountryFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>

                <Col md={8}>
                    <BSForm.Group controlId="state" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.state')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="address.state"
                                    as={BSForm.Control}
                                    placeholder={t('USER_ADDRESS_FORM.statePlaceholder')}
                                    // @ts-ignore
                                    className={`form-control ${errors.address?.state && touched.address?.state ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="address.state" component="div" className="invalid-feedback fs-7" />{' '}
                            </>
                        ) : (
                            <>
                                {values.address?.state ? (
                                    <p className="user-form-value">{values.address?.state}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noStateFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <BSForm.Group controlId="phoneNumber" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_ADDRESS_FORM.phoneNumber')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="phoneNumber"
                                    as={BSForm.Control}
                                    placeholder={t('USER_ADDRESS_FORM.phoneNumberPlaceholder')}
                                    className={`form-control ${errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.phoneNumber ? (
                                    <p className="user-form-value">{values.phoneNumber}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_ADDRESS_FORM.noPhoneNumberFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>
            </Row>

            {editable && (
                <Button type="submit" disabled={isSubmitting} className="mb-3 mt-2">
                    {isSubmitting ? (
                        <Spinner animation="border" />
                    ) : (
                        <>
                            <TbDeviceFloppy size={25} className="pb-1" /> {t('BUTTON.save')}
                        </>
                    )}
                </Button>
            )}
        </>
    );
}
