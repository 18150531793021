/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface Frame
 */
export interface Frame {
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    frameId?: string;
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    translatableName?: string;
    /**
     * 
     * @type {number}
     * @memberof Frame
     */
    price?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Frame
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    image?: string;
    /**
     * 
     * @type {Date}
     * @memberof Frame
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Frame
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    modifiedBy?: string;
}

/**
 * Check if a given object implements the Frame interface.
 */
export function instanceOfFrame(value: object): value is Frame {
    return true;
}

export function FrameFromJSON(json: any): Frame {
    return FrameFromJSONTyped(json, false);
}

export function FrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Frame {
    if (json == null) {
        return json;
    }
    return {
        
        'frameId': json['frameId'] == null ? undefined : json['frameId'],
        'translatableName': json['translatableName'] == null ? undefined : json['translatableName'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : CurrencyFromJSON(json['currency']),
        'image': json['image'] == null ? undefined : json['image'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function FrameToJSON(value?: Frame | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frameId': value['frameId'],
        'translatableName': value['translatableName'],
        'price': value['price'],
        'currency': CurrencyToJSON(value['currency']),
        'image': value['image'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

