import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navbar, Container, Nav, Stack, NavDropdown, Image, Button, Form, InputGroup, Offcanvas, Spinner } from 'react-bootstrap';
import { TbHeart, TbSearch, TbShoppingCart } from 'react-icons/tb';
import cat from '../../resources/image/menu/cat.png';
import './TopMenu.scss';
import HoverDropdown, { Content1, Content2, Content3 } from './TopMenuHoverDropDown';
import { USER_CART_ITEMS, USER_WISHLIST_ITEMS, API_CONFIG, USER } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { SimpleUserResponse, UserControllerApi } from '../../generated';
import { WishlistContext } from '../../provider/WishlistProvider';
import { CartContext } from '../../provider/CartProvider';
import { MenuContext } from '../../provider/MenuProvider';
import RandomGenerator from '../../utils/Random';
import { Link, useNavigate } from 'react-router-dom';
import { SearchContext } from '../../provider/SearchProvider';
import CurrencySelector from './CurrencySelector';
import { LanguageContext } from '../../provider/LanguageProvider';
import { useTranslation } from 'react-i18next';

const userClient = new UserControllerApi(API_CONFIG);
const R = new RandomGenerator();

const fetchSimpleUser = async () => {
    return (await userClient.getSimpleUserDetails().catch((e: any) => console.error(e))) ?? undefined;
};

export default function TopMenu() {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Use translation hook at the top of the component

    // Context hooks should always be called in the same order
    const { searchTerm, setSearchTerm } = useContext(SearchContext);
    const { addCallback } = useContext(MenuContext);
    const { wishlist, clearWishlistStorage } = useContext(WishlistContext);
    const { cart, clearCartStorage } = useContext(CartContext);
    const { currentLanguage, availableLanguages, changeLanguage, getAvailableLanguages, isAvailableLanguagesLoading } = useContext(LanguageContext);

    // State hooks should also be called unconditionally
    const [user, setUser] = useStateSSRInit<SimpleUserResponse | undefined>(fetchSimpleUser, USER);
    const [searchTermOnBar, setSearchTermOnBar] = useState<string | undefined>(searchTerm);

    // Always ensure arrays or objects used in hooks are stable between renders
    const placeholders = useMemo(() => t('TOP_MENU.searchPlaceholder', { returnObjects: true }) as string[], [t]);
    const [placeholder, setPlaceholder] = useState<string>(placeholders[Math.floor(Math.random() * placeholders.length)]);

    // Define content array outside of hook calls to avoid dynamic changes in hook calls
    const content = useMemo(
        () => [
            { label: t('TOP_MENU.posterCategories'), link: '/listing', content: <Content1 />, width: 800 },
            { label: t('TOP_MENU.exploreCollections'), link: '/collections', content: <Content2 />, width: 800 },
            { label: t('TOP_MENU.aboutUs'), link: '/about', content: <Content3 />, width: 800 },
        ],
        [t]
    );

    const getCartItemsSize = useCallback((): number => {
        return cart?.cartItems?.reduce((acc: number, cartItem: any) => acc + (cartItem?.quantity ?? 0), 0) ?? 0;
    }, [cart]);

    const getWishlistItemsSize = useCallback((): number => {
        return wishlist?.items?.length ?? 0;
    }, [wishlist]);

    // Refactor state hooks to avoid dynamic order issues
    const [cartSize, setCartSize] = useStateSSRInit<number>(() => Promise.resolve(getCartItemsSize()), USER_CART_ITEMS, getCartItemsSize());
    const [wishlistSize, setWishlistSize] = useStateSSRInit<number>(() => Promise.resolve(getWishlistItemsSize()), USER_WISHLIST_ITEMS, getWishlistItemsSize());

    useEffect(() => {
        setCartSize(getCartItemsSize());
    }, [cart, getCartItemsSize, setCartSize]);

    useEffect(() => {
        setWishlistSize(getWishlistItemsSize());
    }, [wishlist, getWishlistItemsSize, setWishlistSize]);

    // Ensure all useEffect calls have stable dependencies
    useEffect(() => {
        addCallback(() => {
            fetchSimpleUser()
                .then((response) => setUser(response))
                .catch((e: any) => console.error(e));
        });
        const interval = setInterval(() => {
            setPlaceholder(placeholders[Math.floor(R.random() * placeholders.length)]);
        }, 30000);
        return () => clearInterval(interval);
    }, [addCallback, setUser, placeholders]);

    const handleKeyPress = (event: any) => {
        if (event?.key === 'Enter') {
            setSearchTerm(searchTermOnBar);
            navigate(searchTermOnBar ? `/search?q=${searchTermOnBar}` : '/listing');
        }
    };

    const brandComponent = (
        <Navbar.Brand to="/" as={Link} style={{ marginRight: '0px' }}>
            <Stack direction="horizontal">
                <Image src="/android-chrome-192x192.png" width={40} height={40} alt="Lumiglyph logo" />
                <div className="visible-300 ms-2">
                    <Stack gap={0}>
                        <span className="logo-title">{t('TOP_MENU.brandName')}</span>
                        <span className="fw-light slogan">
                            {t('TOP_MENU.slogan-left')} <br className="slogan-break-line" /> {t('TOP_MENU.slogan-right')}
                        </span>
                    </Stack>
                </div>
            </Stack>
        </Navbar.Brand>
    );

    const cartComponent = (
        <Nav.Link as={Link} to="/cart" className="active p-0 pb-1" style={{ height: '40px' }}>
            <TbShoppingCart size={28} className="mt-2" />
            {cartSize > 0 && <div className="count">{cartSize > 99 ? '9+' : cartSize}</div>}
        </Nav.Link>
    );

    const wishlistComp = (
        <Nav.Link as={Link} to="/wishlist" className="active p-0 pb-1" style={{ height: '40px' }}>
            <TbHeart size={28} className="mt-2" />
            {wishlistSize > 0 && <div className="count">{wishlistSize > 99 ? '9+' : wishlistSize}</div>}
        </Nav.Link>
    );

    const searchBar = (
        <InputGroup className="rounded-pill cursor-pointer">
            <InputGroup.Text className="bg-white">
                <TbSearch size={18} />
            </InputGroup.Text>
            <Form.Control
                className="border"
                type="text"
                placeholder={placeholder}
                value={searchTermOnBar}
                onKeyDown={handleKeyPress}
                onChange={(e) => setSearchTermOnBar(e.target.value)}
            />
        </InputGroup>
    );

    const logout = async () => {
        await userClient
            .logout()
            .catch((e: any) => console.error(e))
            .then(() => {
                clearWishlistStorage();
                clearCartStorage();
                setUser(undefined);
                window.sessionStorage.clear();
                navigate('/');
            });
    };

    return (
        <div>
            <Navbar bg="light" expand="lg" className="py-2">
                <Container className="d-flex justify-content-between">
                    <Navbar.Toggle />

                    {brandComponent}

                    <div className="d-lg-none">
                        <Stack direction="horizontal" gap={2} className="align-items-center">
                            {user ? (
                                <NavDropdown
                                    title={<Image src={user?.cat ? cat : user?.image ?? cat} roundedCircle width={40} height={40} alt="User profile Image" />}
                                    className="no-arrow"
                                >
                                    <NavDropdown.Item as={Link} to="/secured/user">
                                        {t('TOP_MENU.profile')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => logout()}>{t('TOP_MENU.signOut')}</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <div className="align-self-center ">
                                    <Link to="/sign-in">
                                        <Button size="sm" variant="outline-primary" className="no-wrap">
                                            {t('TOP_MENU.signIn')}
                                        </Button>
                                    </Link>
                                </div>
                            )}
                            {wishlistComp}
                            {cartComponent}
                        </Stack>
                    </div>

                    <Navbar.Offcanvas placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>{brandComponent}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="bg-light">
                            <div className="d-lg-none">
                                {content.map((item) => (
                                    <Nav key={item.label} className="text-center lead">
                                        <Nav.Link as={Link} to={item.link}>
                                            {item.label}
                                        </Nav.Link>
                                    </Nav>
                                ))}
                            </div>

                            {user ? (
                                <div className="d-lg-none">
                                    <Nav className="text-center lead">
                                        <Nav.Link onClick={() => logout()}>{t('TOP_MENU.signOut')}</Nav.Link>
                                    </Nav>
                                </div>
                            ) : (
                                <div className="d-lg-none align-self-center text-center">
                                    <Link to="/sign-in">
                                        <Button size="sm" variant="outline-primary" className="no-wrap">
                                            {t('TOP_MENU.signIn')}
                                        </Button>
                                    </Link>
                                </div>
                            )}

                            <div className="visible-lg w-100 ms-3 mt-1">
                                <Nav className=" rounded-pill">{searchBar}</Nav>
                            </div>

                            <div className="visible-lg">
                                <Nav>
                                    <Stack direction="horizontal" gap={2} className="ms-4 align-items-center">
                                        {user ? (
                                            <NavDropdown
                                                title={
                                                    <Image
                                                        src={user?.cat ? cat : user?.image ?? cat}
                                                        roundedCircle
                                                        width={40}
                                                        height={40}
                                                        alt="User profile Image"
                                                    />
                                                }
                                                className="no-arrow"
                                            >
                                                <NavDropdown.Item as={Link} to="/secured/user">
                                                    {t('TOP_MENU.profile')}
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item onClick={() => logout()}>{t('TOP_MENU.signOut')}</NavDropdown.Item>
                                            </NavDropdown>
                                        ) : (
                                            <div className="align-self-center ">
                                                <Link to="/sign-in">
                                                    <Button
                                                        variant="outline-primary"
                                                        className="w-100 no-wrap"
                                                        style={{
                                                            minWidth: '80px',
                                                        }}
                                                    >
                                                        {t('TOP_MENU.signIn')}
                                                    </Button>
                                                </Link>
                                            </div>
                                        )}
                                        <div className="vr ms-2" />
                                        {currentLanguage && (
                                            <NavDropdown
                                                title={
                                                    <Image
                                                        src={currentLanguage.flagUrl}
                                                        height={21}
                                                        width={34}
                                                        className="mt-1 p-0 lang-flag opacity-75"
                                                        style={{
                                                            borderRadius: '5px',
                                                        }}
                                                    />
                                                }
                                                onClick={getAvailableLanguages}
                                                id="language-nav-dropdown"
                                                className="no-arrow"
                                            >
                                                {isAvailableLanguagesLoading ? (
                                                    <NavDropdown.Item disabled>
                                                        <div className="text-center">
                                                            <Spinner size="sm" />
                                                        </div>
                                                    </NavDropdown.Item>
                                                ) : (
                                                    (Array.isArray(availableLanguages) ? availableLanguages : []).map((language) => (
                                                        <NavDropdown.Item key={language.code} onClick={() => changeLanguage(language?.code ?? '')}>
                                                            <Image src={language.flagUrl} width={26} height={17} className="mt-0 p-0 lang-flag " />
                                                            <span className="ms-2">{language.name}</span>
                                                        </NavDropdown.Item>
                                                    ))
                                                )}
                                                <hr className="my-1" />
                                                <NavDropdown.Item className="m-0 p-0">
                                                    <CurrencySelector />
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        )}
                                        {wishlistComp}
                                        {cartComponent}
                                    </Stack>
                                </Nav>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <div
                className="d-lg-none bg-light"
                style={{
                    borderTop: '1px solid #e9ecef',
                }}
            >
                <Container className="py-2">{searchBar}</Container>
            </div>

            <div className="visible-lg">
                <Navbar
                    bg="light"
                    expand="md"
                    style={{
                        borderTop: '1px solid #e9ecef',
                    }}
                    className="py-0"
                >
                    <Container>
                        <Nav className="mx-auto">
                            <HoverDropdown content={content} />
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
}
