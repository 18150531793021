/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePosterResponse } from './SimplePosterResponse';
import {
    SimplePosterResponseFromJSON,
    SimplePosterResponseFromJSONTyped,
    SimplePosterResponseToJSON,
} from './SimplePosterResponse';
import type { SimpleTagResponse } from './SimpleTagResponse';
import {
    SimpleTagResponseFromJSON,
    SimpleTagResponseFromJSONTyped,
    SimpleTagResponseToJSON,
} from './SimpleTagResponse';

/**
 * 
 * @export
 * @interface CollectionResponse
 */
export interface CollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    collectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    translatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    description?: string;
    /**
     * 
     * @type {Set<SimpleTagResponse>}
     * @memberof CollectionResponse
     */
    tags?: Set<SimpleTagResponse>;
    /**
     * 
     * @type {Array<SimplePosterResponse>}
     * @memberof CollectionResponse
     */
    posters?: Array<SimplePosterResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionResponse
     */
    hot?: boolean;
}

/**
 * Check if a given object implements the CollectionResponse interface.
 */
export function instanceOfCollectionResponse(value: object): value is CollectionResponse {
    return true;
}

export function CollectionResponseFromJSON(json: any): CollectionResponse {
    return CollectionResponseFromJSONTyped(json, false);
}

export function CollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'collectionId': json['collectionId'] == null ? undefined : json['collectionId'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'translatedName': json['translatedName'] == null ? undefined : json['translatedName'],
        'description': json['description'] == null ? undefined : json['description'],
        'tags': json['tags'] == null ? undefined : (new Set((json['tags'] as Array<any>).map(SimpleTagResponseFromJSON))),
        'posters': json['posters'] == null ? undefined : ((json['posters'] as Array<any>).map(SimplePosterResponseFromJSON)),
        'hot': json['hot'] == null ? undefined : json['hot'],
    };
}

export function CollectionResponseToJSON(value?: CollectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectionId': value['collectionId'],
        'defaultName': value['defaultName'],
        'translatedName': value['translatedName'],
        'description': value['description'],
        'tags': value['tags'] == null ? undefined : (Array.from(value['tags'] as Set<any>).map(SimpleTagResponseToJSON)),
        'posters': value['posters'] == null ? undefined : ((value['posters'] as Array<any>).map(SimplePosterResponseToJSON)),
        'hot': value['hot'],
    };
}

