/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderItemResponse } from './OrderItemResponse';
import {
    OrderItemResponseFromJSON,
    OrderItemResponseFromJSONTyped,
    OrderItemResponseToJSON,
} from './OrderItemResponse';
import type { CurrencyConversionField } from './CurrencyConversionField';
import {
    CurrencyConversionFieldFromJSON,
    CurrencyConversionFieldFromJSONTyped,
    CurrencyConversionFieldToJSON,
} from './CurrencyConversionField';

/**
 * 
 * @export
 * @interface OrderResponseAdmin
 */
export interface OrderResponseAdmin {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    shippingMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    shippingAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    estimatedDeliveryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    shippedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    deliveredDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    paymentStatus?: OrderResponseAdminPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    paymentIntentId?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    paymentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    checkoutId?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    taxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    subTotalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    paymentMethodDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    orderState?: OrderResponseAdminOrderStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseAdmin
     */
    lastUpdatedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    discountId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    couponCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    applicableTo?: OrderResponseAdminApplicableToEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    discountType?: OrderResponseAdminDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAdmin
     */
    discountValue?: number;
    /**
     * 
     * @type {Array<OrderItemResponse>}
     * @memberof OrderResponseAdmin
     */
    orderItems?: Array<OrderItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAdmin
     */
    currency?: string;
    /**
     * 
     * @type {Set<CurrencyConversionField>}
     * @memberof OrderResponseAdmin
     */
    rates?: Set<CurrencyConversionField>;
}


/**
 * @export
 */
export const OrderResponseAdminPaymentStatusEnum = {
    Paid: 'PAID',
    Unpaid: 'UNPAID',
    WaitingPayment: 'WAITING_PAYMENT',
    Fail: 'FAIL'
} as const;
export type OrderResponseAdminPaymentStatusEnum = typeof OrderResponseAdminPaymentStatusEnum[keyof typeof OrderResponseAdminPaymentStatusEnum];

/**
 * @export
 */
export const OrderResponseAdminOrderStateEnum = {
    PaymentPending: 'PAYMENT_PENDING',
    PaymentProcessed: 'PAYMENT_PROCESSED',
    PaymentFailed: 'PAYMENT_FAILED',
    ProviderNotified: 'PROVIDER_NOTIFIED',
    OrderInProgress: 'ORDER_IN_PROGRESS',
    OrderReadyForPickup: 'ORDER_READY_FOR_PICKUP',
    PackageInTransit: 'PACKAGE_IN_TRANSIT',
    DeliveryConfirmed: 'DELIVERY_CONFIRMED',
    OrderCanceledSystem: 'ORDER_CANCELED_SYSTEM',
    OrderCanceledClient: 'ORDER_CANCELED_CLIENT',
    RefundInitiated: 'REFUND_INITIATED',
    RefundCompleted: 'REFUND_COMPLETED'
} as const;
export type OrderResponseAdminOrderStateEnum = typeof OrderResponseAdminOrderStateEnum[keyof typeof OrderResponseAdminOrderStateEnum];

/**
 * @export
 */
export const OrderResponseAdminApplicableToEnum = {
    PosterPrice: 'POSTER_PRICE',
    FramePrice: 'FRAME_PRICE',
    MaterialPrice: 'MATERIAL_PRICE',
    SizePrice: 'SIZE_PRICE',
    SubTotalPosterPrice: 'SUB_TOTAL_POSTER_PRICE',
    SubTotalCartPrice: 'SUB_TOTAL_CART_PRICE'
} as const;
export type OrderResponseAdminApplicableToEnum = typeof OrderResponseAdminApplicableToEnum[keyof typeof OrderResponseAdminApplicableToEnum];

/**
 * @export
 */
export const OrderResponseAdminDiscountTypeEnum = {
    Percentage: 'PERCENTAGE',
    FixedAmount: 'FIXED_AMOUNT',
    FreeShipping: 'FREE_SHIPPING',
    FreeCheapestProduct: 'FREE_CHEAPEST_PRODUCT'
} as const;
export type OrderResponseAdminDiscountTypeEnum = typeof OrderResponseAdminDiscountTypeEnum[keyof typeof OrderResponseAdminDiscountTypeEnum];


/**
 * Check if a given object implements the OrderResponseAdmin interface.
 */
export function instanceOfOrderResponseAdmin(value: object): value is OrderResponseAdmin {
    return true;
}

export function OrderResponseAdminFromJSON(json: any): OrderResponseAdmin {
    return OrderResponseAdminFromJSONTyped(json, false);
}

export function OrderResponseAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponseAdmin {
    if (json == null) {
        return json;
    }
    return {
        
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'email': json['email'] == null ? undefined : json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'shippingMethod': json['shippingMethod'] == null ? undefined : json['shippingMethod'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'address': json['address'] == null ? undefined : json['address'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'country': json['country'] == null ? undefined : json['country'],
        'shippingAmount': json['shippingAmount'] == null ? undefined : json['shippingAmount'],
        'estimatedDeliveryDate': json['estimatedDeliveryDate'] == null ? undefined : (new Date(json['estimatedDeliveryDate'])),
        'shippedDate': json['shippedDate'] == null ? undefined : (new Date(json['shippedDate'])),
        'deliveredDate': json['deliveredDate'] == null ? undefined : (new Date(json['deliveredDate'])),
        'paymentStatus': json['paymentStatus'] == null ? undefined : json['paymentStatus'],
        'paymentIntentId': json['paymentIntentId'] == null ? undefined : json['paymentIntentId'],
        'paymentDate': json['paymentDate'] == null ? undefined : (new Date(json['paymentDate'])),
        'checkoutId': json['checkoutId'] == null ? undefined : json['checkoutId'],
        'taxAmount': json['taxAmount'] == null ? undefined : json['taxAmount'],
        'subTotalAmount': json['subTotalAmount'] == null ? undefined : json['subTotalAmount'],
        'totalAmount': json['totalAmount'] == null ? undefined : json['totalAmount'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'paymentMethodDetails': json['paymentMethodDetails'] == null ? undefined : json['paymentMethodDetails'],
        'orderState': json['orderState'] == null ? undefined : json['orderState'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'lastUpdatedDate': json['lastUpdatedDate'] == null ? undefined : (new Date(json['lastUpdatedDate'])),
        'discountAmount': json['discountAmount'] == null ? undefined : json['discountAmount'],
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'applicableTo': json['applicableTo'] == null ? undefined : json['applicableTo'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'discountValue': json['discountValue'] == null ? undefined : json['discountValue'],
        'orderItems': json['orderItems'] == null ? undefined : ((json['orderItems'] as Array<any>).map(OrderItemResponseFromJSON)),
        'currency': json['currency'] == null ? undefined : json['currency'],
        'rates': json['rates'] == null ? undefined : (new Set((json['rates'] as Array<any>).map(CurrencyConversionFieldFromJSON))),
    };
}

export function OrderResponseAdminToJSON(value?: OrderResponseAdmin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderId': value['orderId'],
        'email': value['email'],
        'name': value['name'],
        'phoneNumber': value['phoneNumber'],
        'shippingMethod': value['shippingMethod'],
        'trackingNumber': value['trackingNumber'],
        'address': value['address'],
        'city': value['city'],
        'state': value['state'],
        'postalCode': value['postalCode'],
        'country': value['country'],
        'shippingAmount': value['shippingAmount'],
        'estimatedDeliveryDate': value['estimatedDeliveryDate'] == null ? undefined : ((value['estimatedDeliveryDate']).toISOString()),
        'shippedDate': value['shippedDate'] == null ? undefined : ((value['shippedDate']).toISOString()),
        'deliveredDate': value['deliveredDate'] == null ? undefined : ((value['deliveredDate']).toISOString()),
        'paymentStatus': value['paymentStatus'],
        'paymentIntentId': value['paymentIntentId'],
        'paymentDate': value['paymentDate'] == null ? undefined : ((value['paymentDate']).toISOString()),
        'checkoutId': value['checkoutId'],
        'taxAmount': value['taxAmount'],
        'subTotalAmount': value['subTotalAmount'],
        'totalAmount': value['totalAmount'],
        'paymentMethod': value['paymentMethod'],
        'paymentMethodDetails': value['paymentMethodDetails'],
        'orderState': value['orderState'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'lastUpdatedDate': value['lastUpdatedDate'] == null ? undefined : ((value['lastUpdatedDate']).toISOString()),
        'discountAmount': value['discountAmount'],
        'discountId': value['discountId'],
        'couponCode': value['couponCode'],
        'applicableTo': value['applicableTo'],
        'discountType': value['discountType'],
        'discountValue': value['discountValue'],
        'orderItems': value['orderItems'] == null ? undefined : ((value['orderItems'] as Array<any>).map(OrderItemResponseToJSON)),
        'currency': value['currency'],
        'rates': value['rates'] == null ? undefined : (Array.from(value['rates'] as Set<any>).map(CurrencyConversionFieldToJSON)),
    };
}

