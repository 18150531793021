import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image, Placeholder, Ratio } from 'react-bootstrap';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import './CollectionsPreview.scss';
import { CollectionResponse, SimplePosterResponse } from '../../generated';
import { Link } from 'react-router-dom';
import SSRContext from '../../service/SSRContext';
import { CollectionContext } from '../../provider/CollectionProvider';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

type CollectionPreviewProps = {
    readonly collection?: CollectionResponse;
    readonly firstIsLoading?: boolean;
};

type SlideProps = {
    readonly poster: SimplePosterResponse;
    readonly collection: string;
};

export default function CollectionsPreview({ collection, firstIsLoading }: CollectionPreviewProps) {
    const ref = useRef<StackedCarousel | undefined>();
    const maxVisibleSlide = 5;
    const minimumSlides = 3;

    // Generate placeholder data if there are not enough posters
    const postersData = (collection?.posters ?? []).map((poster) => ({
        poster,
        collection: collection?.defaultName ?? '',
        firstIsLoading,
    }));

    // Fill placeholders to meet the minimum number of slides required
    const slidesData =
        postersData.length >= 3 ? postersData : [...postersData, ...Array(maxVisibleSlide - postersData.length).fill({ poster: {}, collection: '' })];

    return (
        <div className="pt-3 pb-3 mb-3 mt-2 collection-preview">
            {/* Show Placeholder for Collection Name */}
            {firstIsLoading ? (
                <Placeholder animation="glow" className="w-100">
                    <Placeholder className="collection-name-placeholder mb-3" />
                </Placeholder>
            ) : (
                <h1 className="display-4 fs-4 ps-3 pb-3">{collection?.translatedName}</h1>
            )}
            <div className="position-relative">
                {SSRContext.isInClient() ? (
                    <ResponsiveContainer
                        carouselRef={ref}
                        render={(parentWidth, carouselRef) => {
                            let currentVisibleSlide = maxVisibleSlide;
                            if (parentWidth <= 324) {
                                currentVisibleSlide = 1;
                            } else if (parentWidth <= 334) {
                                currentVisibleSlide = 3;
                            } else if (parentWidth <= 354) {
                                currentVisibleSlide = 3;
                            }
                            return (
                                <StackedCarousel
                                    height={310}
                                    ref={carouselRef}
                                    slideComponent={Slide}
                                    slideWidth={parentWidth * 0.3}
                                    carouselWidth={parentWidth}
                                    data={slidesData}
                                    currentVisibleSlide={currentVisibleSlide}
                                    maxVisibleSlide={maxVisibleSlide}
                                />
                            );
                        }}
                    />
                ) : (
                    <StackedCarousel
                        height={310}
                        slideComponent={Slide}
                        slideWidth={310 * 0.3} // TODO: Use width sent by client
                        carouselWidth={310} // TODO: Use width sent by client
                        data={slidesData}
                        currentVisibleSlide={0}
                        maxVisibleSlide={maxVisibleSlide}
                    />
                )}
                <div
                    className="slide-left"
                    onClick={() => ref.current?.goBack()}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            ref.current?.goBack();
                        }
                    }}
                >
                    <TbChevronLeft />
                </div>
                <div
                    className="slide-right"
                    onClick={() => ref.current?.goNext()}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            ref.current?.goNext();
                        }
                    }}
                >
                    <TbChevronRight />
                </div>
            </div>
        </div>
    );
}

// Memoized Slide component for better performance
const Slide = React.memo(function ({ data, dataIndex }: { data: SlideProps[]; dataIndex: number }) {
    const { poster, collection } = data[dataIndex];
    const { firstIsLoading } = useContext(CollectionContext);

    return (
        <div className="slide-container position-relative p-2">
            {firstIsLoading ? (
                <Placeholder animation="glow">
                    <Placeholder
                        style={{
                            width: '200px',
                            height: '300px',
                        }}
                    />
                </Placeholder>
            ) : (
                <Link to={`/collection/${collection}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <Image
                        src={createPosterImageUrl(ImageSize.Normal, poster.image)}
                        alt={poster.altText}
                        className="custom-rounded fit"
                        draggable={false}
                        unselectable="on"
                        style={{
                            display: 'block',
                        }}
                    />
                </Link>
            )}
        </div>
    );
});

Slide.displayName = 'Slide';
