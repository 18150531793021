import React, { useCallback, useEffect, useMemo } from 'react';
import { SimplePosterResponse, WishlistControllerApi, WishlistResponse } from '../generated';
import { API_CONFIG, WISHLIST_WISHLIST } from '../resources/constants';
import { useStateSSRInit } from '../utils/DataWrapper';
import { isJson } from '../service/Utils';
import SSRContext from '../service/SSRContext';

/**
 * Provides a context for managing a wishlist in a React application.
 * This context includes functions for adding and removing posters,
 * checking if a poster is a favorite, and clearing the wishlist from storage.
 */
type WishlistContextType = {
    addPoster: (posterId: string) => void;
    removePoster: (posterId: string) => void;
    isFav: (posterId: string) => boolean;
    clearWishlistStorage: () => void;
    wishlist: WishlistResponse | undefined;
};

export const WishlistContext = React.createContext<WishlistContextType>({
    addPoster: () => {},
    removePoster: () => {},
    isFav: () => false,
    clearWishlistStorage: () => {},
    wishlist: undefined,
});

type Props = {
    children: React.ReactNode;
};

export const WISH_LIST_KEY = 'WISHLIST';

const client: WishlistControllerApi = new WishlistControllerApi(API_CONFIG);

const fetchWishlistFromSessionStorage = (): WishlistResponse | undefined => {
    if (SSRContext.isInServer()) return undefined;
    const value = window.sessionStorage.getItem(WISH_LIST_KEY);
    return isJson(value) ? JSON.parse(value ?? '{}') : {};
};

/**
 * Fetches the wishlist from session storage or the server.
 * @returns The wishlist object.
 */
const fetchWishlist = async () => {
    try {
        const sessionWishlist = fetchWishlistFromSessionStorage();

        if (!sessionWishlist?.items) {
            return await client.getWishlist({
                wishlistRequest: { ...sessionWishlist },
            });
        } else {
            return sessionWishlist;
        }
    } catch (e) {
        console.error(e);
    }
};

/**
 * WishlistProvider component that wraps the children components
 * and provides them with wishlist context.
 * @param {Props} props - The props containing children components.
 */
export default function WishlistProvider({ children }: Props) {
    const [wishlist, setWishlist] = useStateSSRInit<WishlistResponse | undefined>(fetchWishlist, WISHLIST_WISHLIST, fetchWishlistFromSessionStorage());
    useEffect(() => {
        window.sessionStorage.setItem(WISH_LIST_KEY, JSON.stringify(wishlist));
    }, [wishlist]);

    /**
     * Adds a poster to the wishlist.
     * @param {string} posterId - The ID of the poster to be added.
     */
    const addPoster = useCallback(
        async (posterId: string) => {
            client
                .addItem({ addPosterToWishlistRequest: { posterId, sessionWishlistSize: wishlist?.items?.length ?? 0 } })
                .then((poster: SimplePosterResponse) => {
                    setWishlist((prev: WishlistResponse | undefined) => {
                        if (prev?.items) {
                            return {
                                ...prev,
                                items: [...prev.items, poster],
                            };
                        } else {
                            return {
                                ...prev,
                                items: [poster],
                            };
                        }
                    });
                })
                .catch((e: any) => console.error(e));
        },
        [setWishlist, wishlist?.items?.length]
    );

    /**
     * Removes a poster from the wishlist.
     * @param {string} posterId - The ID of the poster to be removed.
     */
    const removePoster = useCallback(
        async (posterId: string) => {
            client
                .removeItem({ removePosterToWishlistRequest: { posterId, sessionWishlistSize: wishlist?.items?.length ?? 0 } })
                .then(() => {
                    setWishlist((prev) => {
                        if (prev?.items) {
                            return {
                                ...prev,
                                items: prev.items.filter((poster) => poster.posterId !== posterId),
                            };
                        } else {
                            return prev;
                        }
                    });
                })
                .catch((e: any) => console.error(e));
        },
        [setWishlist, wishlist?.items?.length]
    );

    /**
     * Checks if a poster is in the wishlist (is a favorite).
     * @param {string} posterId - The ID of the poster to check.
     * @returns {boolean} True if the poster is in the wishlist, false otherwise.
     */
    const isFav = useCallback(
        (posterId: string) => {
            return wishlist?.items?.some((poster) => poster.posterId === posterId) ?? false;
        },
        [wishlist]
    );

    /**
     * Clears the wishlist from session storage.
     */
    const clearWishlistStorage = useCallback(() => {
        window.sessionStorage.removeItem(WISH_LIST_KEY);
        setWishlist(undefined);
    }, [setWishlist]);

    const wishlistSize = wishlist?.items?.length ?? 0;

    const value = useMemo(
        () => ({
            addPoster,
            removePoster,
            isFav,
            clearWishlistStorage,
            wishlist,
        }),
        [addPoster, removePoster, isFav, clearWishlistStorage, wishlist]
    );

    return <WishlistContext.Provider value={value}>{children}</WishlistContext.Provider>;
}
