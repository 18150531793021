import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { isRightArrowVisible, isLeftArrowVisible, scrollToPrevious, scrollToNext } from '../../utils/ScrollUtils';
import './Shelf.scss';

type Props = {
    children: ReactNode[];
    title?: string;
    size?: 'sm' | 'md' | 'lg';
    hr?: boolean;
    userGrid?: boolean;
    gridColumns?: number;
    margin?: number;
    center?: boolean;
};

const getColumnCssClass = (gridColumns: number) => {
    switch (gridColumns) {
        case 2:
            return 'shelf-col-2';
        case 3:
            return 'shelf-col-3';
        case 4:
            return 'shelf-col-4';
        case 5:
            return 'shelf-col-5';
        case 6:
            return 'shelf-col-6';
        default:
            return 'shelf-col-5';
    }
};

function throttle(func: () => void, delay: number) {
    let lastCall = 0;
    return function () {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func();
    };
}

export default function Shelf({
    children,
    title,
    size = 'md',
    className,
    hr = false,
    userGrid = true,
    gridColumns = 4,
    margin = 5,
    center = true,
    ...htmlProps
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    const [productsAsString, setProductsAsString] = useState<string[]>(children.map((child, index) => index.toString()));
    const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);

    const ref = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const stackRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setProductsAsString(children.map((child, index) => index.toString()));
    }, [children]);

    useEffect(() => {
        if (children.length > 0) {
            setShowRightArrow(isRightArrowVisible(ref, productsAsString, stackRef.current));
        }
    }, [stackRef, children.length, productsAsString]);

    useEffect(() => {
        if (children.length > 0) {
            setShowRightArrow(isRightArrowVisible(ref, productsAsString, stackRef.current));
        }
    }, [stackRef, children.length, productsAsString]);

    return (
        <div {...htmlProps} className={`mt-${margin} image-shelf pb-2 ${className ?? ''}`}>
            {title && <h1 className="display-4 fs-2 mb-3">{title}</h1>}
            {hr && <hr />}
            <div
                ref={stackRef}
                className="overflow-auto row-shelf"
                onScroll={(e) => {
                    const isRightVisible = isRightArrowVisible(ref, productsAsString, e.currentTarget);
                    if (isRightVisible && !showRightArrow) {
                        setShowRightArrow(true);
                    } else if (!isRightVisible && showRightArrow) {
                        setShowRightArrow(false);
                    }

                    const isLeftVisible = isLeftArrowVisible(ref, productsAsString, e.currentTarget);
                    if (isLeftVisible && !showLeftArrow) {
                        setShowLeftArrow(true);
                    } else if (!isLeftVisible && showLeftArrow) {
                        setShowLeftArrow(false);
                    }
                }}
            >
                {children.map((child, index) => (
                    <div
                        key={index}
                        ref={(element) => (ref.current[index.toString()] = element)}
                        className={`${userGrid ? getColumnCssClass(gridColumns) : ''} ${center ? 'text-center align-self-center' : ''}`}
                    >
                        {child}
                    </div>
                ))}
            </div>
            {showLeftArrow && <TbChevronLeft className="left-chevron" onClick={() => scrollToPrevious(stackRef, ref, productsAsString)} size={35} />}
            {showRightArrow && <TbChevronRight className="right-chevron " onClick={() => scrollToNext(stackRef, ref, productsAsString)} size={35} />}
        </div>
    );
}
