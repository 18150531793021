/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateDiscountRequest } from './CreateDiscountRequest';
import {
    CreateDiscountRequestFromJSON,
    CreateDiscountRequestFromJSONTyped,
    CreateDiscountRequestToJSON,
} from './CreateDiscountRequest';

/**
 * 
 * @export
 * @interface CreateCouponRequest
 */
export interface CreateCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCouponRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponRequest
     */
    eligibilityRule?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateCouponRequest
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Array<CreateDiscountRequest>}
     * @memberof CreateCouponRequest
     */
    discounts: Array<CreateDiscountRequest>;
}

/**
 * Check if a given object implements the CreateCouponRequest interface.
 */
export function instanceOfCreateCouponRequest(value: object): value is CreateCouponRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('discounts' in value) || value['discounts'] === undefined) return false;
    return true;
}

export function CreateCouponRequestFromJSON(json: any): CreateCouponRequest {
    return CreateCouponRequestFromJSONTyped(json, false);
}

export function CreateCouponRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCouponRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'description': json['description'],
        'eligibilityRule': json['eligibilityRule'] == null ? undefined : json['eligibilityRule'],
        'expirationDate': json['expirationDate'] == null ? undefined : (new Date(json['expirationDate'])),
        'discounts': ((json['discounts'] as Array<any>).map(CreateDiscountRequestFromJSON)),
    };
}

export function CreateCouponRequestToJSON(value?: CreateCouponRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'description': value['description'],
        'eligibilityRule': value['eligibilityRule'],
        'expirationDate': value['expirationDate'] == null ? undefined : ((value['expirationDate']).toISOString()),
        'discounts': ((value['discounts'] as Array<any>).map(CreateDiscountRequestToJSON)),
    };
}

