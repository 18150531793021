import React, { useContext, useState } from 'react';
import { Col, Row, Image, Ratio, Stack, Container } from 'react-bootstrap';
import backgroundImage from '../../resources/image/product/product_bg.jpg';
import interiorGray from '../../resources/image/product/interior.webp';
import tvRoom from '../../resources/image/product/tv_room.webp';
import diningRoom from '../../resources/image/product/dining_room.webp';
import gamingRoom from '../../resources/image/product/gaming_room.webp';
import homeOffice from '../../resources/image/product/home_office.webp';
import kitchen from '../../resources/image/product/kitchen.webp';
import livingRoom from '../../resources/image/product/living_room.webp';
import livingRoom2 from '../../resources/image/product/living_room2.jpg';
import './ProductPresentation.scss';
import { TbHeart, TbHeartFilled } from 'react-icons/tb';
import { FrameResponse, MaterialResponse, PosterResponse, SimplePosterResponseAspectRatioEnum, SizeResponse } from '../../generated';
import moneyBackGarantee from '../../resources/image/icons/money-back-guarantee.png';
import deliveryInsurance from '../../resources/image/icons/delivery-insurance.png';
import highQuality from '../../resources/image/icons/high-quality.png';
import { ProductContext, ProductProvider } from '../../provider/ProductProvider';
import { ActionButton } from '../Button/ActionButton';
import { WishlistContext } from '../../provider/WishlistProvider';
import { CartContext } from '../../provider/CartProvider';
import { useTranslation } from 'react-i18next';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';
import { ImageWithPlaceHolder } from '../Image/ImageWithPlaceHolder';
import { getAspectRatioValue } from '../../service/Utils';

const backgrounds: BackgroundImageContainer[] = [
    {
        id: 0,
        background: backgroundImage,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        size: '50%',
    },
    {
        id: 1,
        background: interiorGray,
        top: '15%',
        left: '33%',
        size: '21%',
    },
    {
        id: 2,
        background: tvRoom,
        top: '13%',
        left: '15%',
        size: '20%',
    },
    {
        id: 3,
        background: diningRoom,
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        size: '20%',
    },
    {
        id: 4,
        background: gamingRoom,
        top: '12%',
        left: '28%',
        size: '18%',
    },
    {
        id: 5,
        background: homeOffice,
        top: '10%',
        left: '40%',
        size: '21%',
    },
    {
        id: 6,
        background: kitchen,
        top: '10%',
        left: '28%',
        size: '18%',
    },
    {
        id: 7,
        background: livingRoom,
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        size: '18%',
    },
    {
        id: 8,
        background: livingRoom2,
        top: '13%',
        left: '52%',
        size: '18%',
    },
];

type Props = {
    readonly product: PosterResponse;
};

type CircleImageButtonProps = {
    image: string;
    selected: boolean;
    name: string;
    useBorder?: boolean;
};
function CircleImageButton({ image, selected, useBorder, name, ...htmlProps }: CircleImageButtonProps & React.HTMLAttributes<HTMLDivElement>) {
    const btn = (
        <div
            className={`rounded-circle mx-auto ${useBorder ? 'border border-1' : ''} color-btn ${selected ? 'color-btn-selected' : ''}`}
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'contain',
                color: useBorder ? 'black' : 'white',
                width: '35px',
                height: '35px',
            }}
        ></div>
    );
    return (
        <div {...htmlProps}>
            {selected ? (
                <div className="p-1 rounded-circle mx-auto border border-1 border-dark" style={{ width: 'fit-content' }}>
                    {btn}
                </div>
            ) : (
                <div className="p-1 mx-auto" style={{ width: 'fit-content', border: '1px solid transparent' }}>
                    {btn}
                </div>
            )}
            <p className="fs-6-8 my-0 text-center">{name}</p>
        </div>
    );
}

type BackgroundImageContainer = {
    id: number;
    top: string;
    left: string;
    size: string;
    background: string;
    transform?: string;
};

type ImageContainerProps = {
    poster: PosterResponse;
    backgroundContainer: BackgroundImageContainer;
    imageSize: ImageSize;
    scaleOnHover?: boolean;
    mainImage?: boolean;
};

function ImageContainer({
    children,
    poster,
    backgroundContainer,
    scaleOnHover,
    imageSize,
    mainImage,
    ...otherProps
}: ImageContainerProps & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <Ratio aspectRatio="1x1" {...otherProps}>
            <div
                id={mainImage ? '' : `product-presentation-backgroung-${backgroundContainer.id}`}
                style={{
                    backgroundImage: `url(${backgroundContainer.background})`,
                    backgroundSize: 'cover',
                }}
            >
                <ImageWithPlaceHolder
                    className={`shadow-img ${scaleOnHover ? 'scale-on-hover' : ''}`}
                    src={createPosterImageUrl(imageSize, poster.image)}
                    parentSelector={mainImage ? '.main-img' : `#product-presentation-backgroung-${backgroundContainer.id}`}
                    alt={poster.altText}
                    aspectRatio={getAspectRatioValue(poster.aspectRatio as SimplePosterResponseAspectRatioEnum)}
                    fluid
                    placeholderStyle={{
                        position: 'absolute',
                        top: backgroundContainer.top,
                        left: backgroundContainer.left,
                        width: backgroundContainer.size,
                        height: backgroundContainer.size,
                        transform: backgroundContainer.transform,
                        transition: 'transform 0s ease, all .4s ease',
                    }}
                    style={{
                        position: 'absolute',
                        top: backgroundContainer.top,
                        left: backgroundContainer.left,
                        width: backgroundContainer.size,
                        transform: backgroundContainer.transform,
                        transition: 'transform 0s ease, all .4s ease',
                    }}
                />
                {children}
            </div>
        </Ratio>
    );
}

type ToggleSizeButtonProps = {
    sizes: SizeResponse[];
};

export function ToggleSizeButtons({ sizes, ...htmlProps }: ToggleSizeButtonProps & React.HTMLAttributes<HTMLDivElement>) {
    const { getSize, setSize } = useContext(ProductContext);
    const { t } = useTranslation();
    return (
        <div {...htmlProps}>
            <p className="font-weight-light mb-1">{t('PRODUCT_PRESENTATION.size')}</p>
            <ul className="size-list">
                {sizes.map((size) => (
                    <li
                        className={`m-1 toggle-btn ${getSize()?.name === size.name ? 'toggle-active' : ''}`}
                        key={size.sizeId}
                        onClick={() => setSize(size)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSize(size);
                            }
                        }}
                    >
                        <span className="fw-bold">{size.name}</span>
                        <span className="ms-2 fw-light">{`${parseFloat(size.width?.toString() ?? '0')} cm / ${parseFloat(
                            size.height?.toString() ?? '0'
                        )} cm`}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

type ToggleFrameButtonProps = {
    frames: FrameResponse[];
};

export function ToggleFrameButtons({ frames, ...htmlProps }: ToggleFrameButtonProps & React.HTMLAttributes<HTMLDivElement>) {
    const { getFrame, setFrame } = useContext(ProductContext);
    const { t } = useTranslation();
    return (
        <div {...htmlProps}>
            <p className="font-weight-light mb-1">{t('PRODUCT_PRESENTATION.selectFrame')}</p>
            <ul className="size-list">
                {frames.map((frame) => (
                    <li
                        className="m-1"
                        key={frame.frameId}
                        onClick={() => setFrame(frame)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setFrame(frame);
                            }
                        }}
                    >
                        <CircleImageButton image={frame.image ?? ''} selected={getFrame()?.name === frame?.name} useBorder name={frame?.name ?? ''} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

type ToggleMaterialButtonProps = {
    materials: MaterialResponse[];
};

export function ToggleMaterialButtons({ materials, ...htmlProps }: ToggleMaterialButtonProps & React.HTMLAttributes<HTMLDivElement>) {
    const { getMaterial, setMaterial } = useContext(ProductContext);
    const { t } = useTranslation();
    return (
        <div {...htmlProps}>
            <p className="font-weight-light mb-1">{t('PRODUCT_PRESENTATION.selectMaterial')}</p>
            <ul className="size-list">
                {materials.map((material) => (
                    <li
                        className="m-1"
                        key={material.materialId}
                        onClick={() => setMaterial(material)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setMaterial(material);
                            }
                        }}
                    >
                        <CircleImageButton
                            image={material.image ?? ''}
                            selected={getMaterial()?.name === material?.name}
                            useBorder
                            name={material?.name ?? ''}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

function SelectorContainer() {
    const { product, sizes, frames, materials, getTotalPrice, getSize, getFrame, getMaterial } = useContext(ProductContext);
    const { addItem } = useContext(CartContext);
    const { t } = useTranslation();

    const addToCart = (actionComplete: (error?: string | undefined) => void) => {
        addItem({
            posterId: product.posterId ?? '',
            sizeId: getSize()?.sizeId ?? '',
            frameId: getFrame()?.frameId ?? '',
            materialId: getMaterial()?.materialId ?? '',
            quantity: 1,
        })
            .then((r) => actionComplete())
            .catch((e: any) => {
                console.error(e);
                actionComplete(e.message);
            });
    };

    return (
        <div className="product-display-selector">
            <h1 className="display-4 fs-1 mb-3">{product.translatedName}</h1>
            {sizes.length > 0 && <ToggleSizeButtons sizes={sizes} />}
            {materials.length > 0 && <ToggleMaterialButtons materials={materials} />}
            {frames.length > 0 && <ToggleFrameButtons frames={frames} />}
            <p className="fs-5-5 lead">{product.description}</p>
            <Row>
                <Col className="mt-2">
                    <ActionButton
                        className="no-break mt-1"
                        onActionClick={addToCart}
                        successMessage={t('BUTTON.addedToCart')}
                        loadingMessage={t('BUTTON.addingToCart')}
                        size="lg"
                    >
                        {product.currencySymbol} {getTotalPrice()} - {t('BUTTON.addToCart')}
                    </ActionButton>
                </Col>
                <Col className="mt-2">
                    <Stack direction="horizontal" gap={4}>
                        <Image src={moneyBackGarantee} style={{ maxHeight: '60px' }} alt={t('PRODUCT_PRESENTATION.moneyBackGuarantee')} />
                        <Image src={deliveryInsurance} style={{ maxHeight: '50px' }} alt={t('PRODUCT_PRESENTATION.deliveryInsurance')} />
                        <Image src={highQuality} style={{ maxHeight: '50px' }} alt={t('PRODUCT_PRESENTATION.highQuality')} />
                    </Stack>
                </Col>
            </Row>
        </div>
    );
}
export default function ProductPresentation({ product }: Props) {
    const [background, setBackground] = useState<BackgroundImageContainer>(backgrounds[0]);
    const [touchStartX, setTouchStartX] = useState(0); // to detect swipes
    const [touchEndX, setTouchEndX] = useState(0);

    const { isFav, addPoster, removePoster } = useContext(WishlistContext);

    const handleTouchStart = (event: React.TouchEvent) => {
        setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        setTouchEndX(event.changedTouches[0].clientX);
        const minPixeisToMove = 100;

        const currentIndex = backgrounds.findIndex((b) => b.background === background.background);

        if (touchEndX - touchStartX < -minPixeisToMove && currentIndex < backgrounds.length - 1) {
            // Swiped left
            setBackground(backgrounds[Math.min(currentIndex + 1, backgrounds.length - 1)]);
        }
        if (touchEndX - touchStartX > minPixeisToMove && currentIndex > 0) {
            // Swiped right
            setBackground(backgrounds[Math.max(0, currentIndex - 1)]);
        }
    };

    return (
        <ProductProvider product={product}>
            <Row>
                <Col md={7}>
                    <Row>
                        <Col
                            xs={{
                                span: 12,
                                order: 'last',
                            }}
                            md={{
                                span: 2,
                                order: 'first',
                            }}
                            className="p-0"
                        >
                            <div className="product-display-small-wrapper">
                                <Stack direction="vertical" gap={2} className="product-display-img product-display-small">
                                    {backgrounds.map((backgroundContainer) => (
                                        <ImageContainer
                                            mainImage={false}
                                            className="rounded"
                                            style={{ cursor: 'pointer' }}
                                            key={backgroundContainer.background}
                                            poster={product}
                                            imageSize={ImageSize.Small}
                                            backgroundContainer={backgroundContainer}
                                            onClick={() => setBackground(backgroundContainer)}
                                        />
                                    ))}
                                </Stack>
                            </div>
                        </Col>
                        <Col md={10} className="p-0">
                            <ImageContainer
                                mainImage
                                poster={product}
                                imageSize={ImageSize.Original}
                                backgroundContainer={background}
                                className="product-display-img overflow-hidden main-img"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                scaleOnHover={background.background === backgrounds[0].background}
                            >
                                <>
                                    {background.background === backgrounds[0].background &&
                                        (isFav(product?.posterId ?? '') ? (
                                            <TbHeartFilled
                                                className="favorite-icon"
                                                size={25}
                                                onClick={() => product.posterId && removePoster(product.posterId)}
                                            />
                                        ) : (
                                            <TbHeart className="favorite-icon" onClick={() => product.posterId && addPoster(product.posterId)} size={25} />
                                        ))}
                                </>
                            </ImageContainer>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    <Container>
                        <SelectorContainer />
                    </Container>
                </Col>
            </Row>
        </ProductProvider>
    );
}
