import React from 'react';
import HomeBanner from '../components/Home/HomeBanner';
import { Container } from 'react-bootstrap';
import { GetCollectionShelfShelfEnum } from '../generated';
import ListingShelf from '../components/Shelf/ListingShelf';
import CategoryShelf from '../components/Shelf/CategoryShelf';
import TwoStoreCollectionShelf from '../components/Shelf/TwoStoreCollectionShelf';
import CategoriesShelf from '../components/Shelf/CategoriesShelf';
import CommunityShelf from '../components/Shelf/CommunityShelf';
import NewsletterShelf from '../components/Shelf/NewsletterShelf';
import TrustShelf from '../components/Shelf/TrustShelf';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
    const { t } = useTranslation(); // Assuming 'home' is the namespace for HomePage translations

    return (
        <>
            <HomeBanner />
            <Container>
                <ListingShelf
                    title={t('HOME_PAGE.listingShelfTrending')}
                    subTitle={t('HOME_PAGE.listingShelfSubTitle')}
                    suggestCategories={false}
                    discoverMore
                />
            </Container>
            <section className="gray-section py-5 mt-5">
                <Container fluid>
                    <TwoStoreCollectionShelf
                        title={t('HOME_PAGE.twoStoreCollectionTitle')}
                        subtitle={t('HOME_PAGE.twoStoreCollectionSubTitle')}
                        shelf={GetCollectionShelfShelfEnum.YouAlsoMightLike}
                        discoverMore
                    />
                </Container>
            </section>
            <Container>
                <div className="text-center my-5 pb-3">
                    <h1 className="display-4  mt-3">{t('HOME_PAGE.categoriesTitle')}</h1>
                    <p className="fs-4">{t('HOME_PAGE.categoriesSubTitle')}</p>
                </div>
                <CategoryShelf category="Animal" title={t('HOME_PAGE.animalCategory')} />
                <CategoryShelf category="Cities" title={t('HOME_PAGE.citiesCategory')} />
                <CategoryShelf category="Space" title={t('HOME_PAGE.spaceCategory')} />
            </Container>
            <section className="gray-section py-5 mt-5">
                <Container fluid className="mb-5">
                    <div className="text-center mb-5 pb-3">
                        <h1 className="display-4  mt-3">{t('HOME_PAGE.stillSearchingTitle')}</h1>
                        <p className="fs-4">{t('HOME_PAGE.stillSearchingSubTitle')}</p>
                    </div>
                    <CategoriesShelf />
                </Container>
            </section>
            <Container className="mb-5">
                <CommunityShelf />
            </Container>
            <NewsletterShelf />
            <Container>
                <TrustShelf />
            </Container>
        </>
    );
}
