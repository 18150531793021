import React, { useContext } from 'react';
import { Col, Form, Placeholder, Row, Stack } from 'react-bootstrap';
import { TbShoppingCart } from 'react-icons/tb';
import './Cart.scss';
import { CartContext } from '../../provider/CartProvider';
import { Checkout } from './Checkout';
import { CartProduct } from './CartProduct';
import { useTranslation } from 'react-i18next';

export default function Cart({ ...htmlProps }: React.HTMLAttributes<HTMLDivElement>) {
    const { getItems, isLoading } = useContext(CartContext);
    const { t } = useTranslation();

    const items = getItems();

    return (
        <div {...htmlProps}>
            <Row>
                <Col lg={8}>
                    <h1 className="display-4">
                        <TbShoppingCart className="me-1 pb-2" size={60} />
                        {t('CART.yourCart')}
                    </h1>
                    <Stack>
                        {isLoading ? (
                            <>
                                <LoadingCartProduct />
                                <hr />
                                <LoadingCartProduct />
                            </>
                        ) : (
                            <>
                                {items && items.length > 0 && (
                                    <>
                                        {items
                                            .sort((a, b) => (a.posterTranslatedName ?? '').localeCompare(b.posterTranslatedName ?? ''))
                                            .map((item, index) => (
                                                <div key={item.itemId}>
                                                    {index == 0 && <hr />}
                                                    <CartProduct item={item} />
                                                    <hr />
                                                </div>
                                            ))}
                                    </>
                                )}
                            </>
                        )}
                    </Stack>
                </Col>
                <Col lg={4}>
                    <Checkout />
                </Col>
            </Row>
        </div>
    );
}

function LoadingCartProduct() {
    return (
        <Row className="p-4 mb-5">
            <Col md={4} style={{ maxHeight: '220px' }}>
                <div
                    className="mx-auto"
                    style={{
                        width: 'fit-content',
                    }}
                >
                    <Placeholder animation="glow">
                        <Placeholder className="custom-rounded" style={{ height: '260px', width: '220px' }} />
                    </Placeholder>
                </div>
            </Col>
            <Col md={8}>
                <Placeholder animation="glow">
                    <Placeholder className="w-100 mb-3 rounded" as={'h1'} />
                </Placeholder>
                <Stack direction="horizontal" gap={4} className="mb-3 lead">
                    <Placeholder animation="glow">
                        <Placeholder as={Form.Control} />
                    </Placeholder>
                    <Placeholder animation="glow" className="w-100">
                        <Placeholder as={Form.Select} />
                    </Placeholder>
                </Stack>
                <Form.Group>
                    <Placeholder animation="glow">
                        <Placeholder className="w-100 mb-3" as={Form.Select} />
                    </Placeholder>
                </Form.Group>
                <Form.Group>
                    <Placeholder animation="glow">
                        <Placeholder as={Form.Select} style={{ width: '200px' }} />
                    </Placeholder>
                </Form.Group>
            </Col>
        </Row>
    );
}
