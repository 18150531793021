import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import SSRContext from '../service/SSRContext';

export function useStateSSRInit<T>(fallBack: () => Promise<T>, dataProperty: string, defaultData?: T): [T, Dispatch<SetStateAction<T>>] {
    const initialData = SSRContext.get(dataProperty);

    const [data, setData] = useState<T>(initialData || defaultData);

    useEffect(() => {
        if (!initialData) {
            fallBack()
                .then((data) => setData(data))
                .catch((e: any) => console.error(e));
        }
    }, []);

    return [data, setData];
}

export function useRefSSRInit<T>(fallBack: () => Promise<T>, dataProperty: string, defaultData?: T): MutableRefObject<T> {
    const initialData = SSRContext.get(dataProperty);

    const ref = useRef<T>(initialData || defaultData);

    useEffect(() => {
        if (!initialData) {
            fallBack()
                .then((data) => (ref.current = data))
                .catch((e: any) => console.error(e));
        }
    }, []);

    return ref;
}
