/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    tagId?: string;
    /**
     * 
     * @type {number}
     * @memberof TagResponse
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    translatedName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagResponse
     */
    parents?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagResponse
     */
    children?: Array<string>;
}

/**
 * Check if a given object implements the TagResponse interface.
 */
export function instanceOfTagResponse(value: object): value is TagResponse {
    return true;
}

export function TagResponseFromJSON(json: any): TagResponse {
    return TagResponseFromJSONTyped(json, false);
}

export function TagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'tagId': json['tagId'] == null ? undefined : json['tagId'],
        'score': json['score'] == null ? undefined : json['score'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'translatedName': json['translatedName'] == null ? undefined : json['translatedName'],
        'parents': json['parents'] == null ? undefined : json['parents'],
        'children': json['children'] == null ? undefined : json['children'],
    };
}

export function TagResponseToJSON(value?: TagResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tagId': value['tagId'],
        'score': value['score'],
        'defaultName': value['defaultName'],
        'translatedName': value['translatedName'],
        'parents': value['parents'],
        'children': value['children'],
    };
}

