/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    translatableName?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    symbol?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    minimumAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    zeroDecimalCurrency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    threeDecimalCurrency?: boolean;
}

/**
 * Check if a given object implements the Currency interface.
 */
export function instanceOfCurrency(value: object): value is Currency {
    return true;
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'translatableName': json['translatableName'] == null ? undefined : json['translatableName'],
        'symbol': json['symbol'] == null ? undefined : json['symbol'],
        'minimumAmount': json['minimumAmount'] == null ? undefined : json['minimumAmount'],
        'zeroDecimalCurrency': json['zeroDecimalCurrency'] == null ? undefined : json['zeroDecimalCurrency'],
        'threeDecimalCurrency': json['threeDecimalCurrency'] == null ? undefined : json['threeDecimalCurrency'],
    };
}

export function CurrencyToJSON(value?: Currency | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'translatableName': value['translatableName'],
        'symbol': value['symbol'],
        'minimumAmount': value['minimumAmount'],
        'zeroDecimalCurrency': value['zeroDecimalCurrency'],
        'threeDecimalCurrency': value['threeDecimalCurrency'],
    };
}

