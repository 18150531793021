import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as BSForm, Col, Row, Stack, Image, Placeholder } from 'react-bootstrap';
import { OrderResponse } from '../../generated';
import { capitalize } from '../../service/Utils';
import './Order.scss';
import { Link } from 'react-router-dom';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

type Props = {
    readonly order?: OrderResponse;
    readonly isLoading?: boolean;
};

export default function Order({ order, isLoading }: Props) {
    const { t, i18n } = useTranslation();

    // Use i18n.language for the locale
    const formatter = new Intl.DateTimeFormat(i18n.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return <>{isLoading ? <LoadingOrder /> : <>{order && <OrderDetails order={order} t={t} formatter={formatter} />}</>}</>;
}

type OrderDetailsProps = {
    readonly order: OrderResponse;
    readonly t: any;
    readonly formatter: Intl.DateTimeFormat;
};

function OrderDetails({ order, t, formatter }: OrderDetailsProps) {
    return (
        <>
            {order.orderItems && (
                <div key={order.orderId} className="mt-3">
                    <Stack direction="horizontal">
                        <Stack direction="vertical" gap={1}>
                            <h1 className="display-4 fs-5 mb-0 pb-0">{formatter.format(order.createdDate)}</h1>
                            <p className="fs-7 fw-light p-0">
                                {t('ORDER.orderId')}: {order.orderId}
                            </p>
                        </Stack>
                        <div className={`rounded-pill ms-auto px-2 ${order.orderState?.toLowerCase()}`}>
                            <p className="m-0 fs-6 fw-light">{capitalize(order.orderState ?? '')}</p>
                        </div>
                    </Stack>
                    <Row>
                        <Col sm={12} md={6} lg={5} xl={4} className="mb-3">
                            <Stack>
                                {order.orderItems?.map((item) => (
                                    <div key={item.orderItemId} className="user-orders-image-container mb-3">
                                        <p className="fs-5 mb-3 w-100 mt-0 pt-0 fw-500">{item.posterName}</p>
                                        <Stack direction="horizontal" gap={2}>
                                            <div className="order-wrapper">
                                                <Link
                                                    to={`/poster/${item.posterName}`}
                                                    className="fit d-block"
                                                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                                >
                                                    <Image
                                                        src={createPosterImageUrl(ImageSize.Small, item.posterImage)}
                                                        className="order-image"
                                                        alt={item.posterName}
                                                    />
                                                </Link>
                                            </div>
                                            <table
                                                className="mb-2"
                                                style={{
                                                    width: '150px',
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td className="attribute-column">{t('ORDER.quantity')}:</td>
                                                        <td className="order-form-value">{item.quantity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="attribute-column">{t('ORDER.price')}:</td>
                                                        <td className="order-form-value">
                                                            {item.subTotalAmount} {order.currency}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="attribute-column">{t('ORDER.size')}:</td>
                                                        <td className="order-form-value">{item.sizeName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="attribute-column">{t('ORDER.material')}:</td>
                                                        <td className="order-form-value">{item.materialName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="attribute-column">{t('ORDER.frame')}:</td>
                                                        <td className="order-form-value">{item.frameName}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Stack>
                                    </div>
                                ))}
                                <div>
                                    {order.couponCode && (
                                        <p className="user-order-qt mb-0 ps-2">
                                            {t('ORDER.coupon')}: <span className="fw-light">{order.couponCode}</span>
                                        </p>
                                    )}
                                    <Stack direction="horizontal" gap={3} className="ps-2">
                                        <p className="user-order-qt mb-0">
                                            {t('ORDER.discount')}:{' '}
                                            <span className="fw-light">
                                                {order.discountAmount} {order.currency}
                                            </span>
                                        </p>
                                        <p className="user-order-qt mb-0">
                                            {t('ORDER.total')}:{' '}
                                            <span className="fw-light">
                                                {(order.totalAmount ?? 0) / 100} {order.currency}
                                            </span>
                                        </p>
                                    </Stack>
                                </div>
                            </Stack>
                        </Col>
                        <Col sm={12} md={3} lg={2} xl={4} className="mb-3">
                            <Row>
                                <Col>
                                    <BSForm.Group className="mb-1">
                                        <BSForm.Label className="user-form-label">{t('ORDER.transporter')}</BSForm.Label>
                                        <p className="order-form-value">{order.shippingMethod}</p>
                                    </BSForm.Group>
                                </Col>
                                <Col>
                                    <BSForm.Group className="mb-1">
                                        <BSForm.Label className="user-form-label">{t('ORDER.tracking')}</BSForm.Label>
                                        <p className="order-form-value">{order.trackingNumber ?? t('ORDER.pending')}</p>
                                    </BSForm.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <BSForm.Group className="mb-1">
                                        <BSForm.Label className="user-form-label">{t('ORDER.paymentMethod')}</BSForm.Label>
                                        <p className="order-form-value">{capitalize(order.paymentMethod ?? '')}</p>
                                    </BSForm.Group>
                                </Col>
                                {order.paymentMethodDetails && (
                                    <Col>
                                        <BSForm.Group className="mb-1">
                                            <BSForm.Label className="user-form-label">{t('ORDER.paymentDetails')}</BSForm.Label>
                                            <p className="order-form-value">{order.paymentMethodDetails}</p>
                                        </BSForm.Group>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col sm={12} md={3} lg={3} xl={4} className="mb-3">
                            <Row>
                                <Col>
                                    <BSForm.Group className="mb-1">
                                        <BSForm.Label className="user-form-label">{t('ORDER.address')}</BSForm.Label>
                                        <p className="order-form-value">{order.address}</p>
                                    </BSForm.Group>
                                    <Row>
                                        <Col>
                                            <BSForm.Group className="mb-1">
                                                <BSForm.Label className="user-form-label">{t('ORDER.postalCode')}</BSForm.Label>
                                                <p className="order-form-value">{order.postalCode}</p>
                                            </BSForm.Group>
                                        </Col>
                                        <Col>
                                            <BSForm.Group className="mb-1">
                                                <BSForm.Label className="user-form-label">{t('ORDER.city')}</BSForm.Label>
                                                <p className="order-form-value">{order.city}</p>
                                            </BSForm.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <BSForm.Group className="mb-1">
                                                <BSForm.Label className="user-form-label">{t('ORDER.country')}</BSForm.Label>
                                                <p className="order-form-value">{order.country}</p>
                                            </BSForm.Group>
                                        </Col>
                                        {order.state && (
                                            <Col>
                                                <BSForm.Group className="mb-1">
                                                    <BSForm.Label className="user-form-label">{t('ORDER.state')}</BSForm.Label>
                                                    <p className="order-form-value">{order.state}</p>
                                                </BSForm.Group>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}

function LoadingOrder() {
    return (
        <div className="mt-3">
            <Placeholder as={Row} animation="glow" className="mb-3">
                <Col md={3}>
                    <Placeholder className="w-100 rounded placeholder-lg" />
                </Col>
                <Col md={6}></Col>
                <Col md={3}>
                    <Placeholder className="w-100 rounded placeholder-lg" bg="primary" />
                </Col>
            </Placeholder>
            <hr />
            <Row>
                <Col sm={12} md={6} lg={5} xl={4} className="mb-3">
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col xs={4}>
                            <Placeholder className="w-100 rounded ms-3 mb-2" style={{ height: '200px' }} bg="primary" />
                        </Col>
                        <Col xs={8}>
                            <Placeholder className="w-75 rounded placeholder-md mb-2 ms-3" />
                            <Placeholder className="w-75 rounded placeholder-md mb-2 ms-3" />
                            <Placeholder className="w-75 rounded placeholder-md mb-2 ms-3" />
                            <Placeholder className="w-75 rounded placeholder-md mb-2 ms-3" />
                        </Col>
                    </Placeholder>
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col>
                            <Placeholder className=" rounded placeholder-md mb-2 w-75" />
                        </Col>
                    </Placeholder>
                </Col>
                <Col sm={12} md={3} lg={2} xl={4} className="mb-3">
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                        </Col>
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                        </Col>
                    </Placeholder>
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" bg="primary" />
                        </Col>
                    </Placeholder>
                </Col>
                <Col sm={12} md={3} lg={2} xl={4} className="mb-3">
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                        </Col>
                    </Placeholder>
                    <Placeholder as={Row} animation="glow" className="mb-2">
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                        </Col>
                        <Col>
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                            <Placeholder className="w-100 rounded placeholder-md mb-2" />
                        </Col>
                    </Placeholder>
                </Col>
            </Row>
        </div>
    );
}
