/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCartItemRequest
 */
export interface UpdateCartItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    cartItemId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    frameId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCartItemRequest
     */
    sizeId?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCartItemRequest
     */
    quantity?: number;
}

/**
 * Check if a given object implements the UpdateCartItemRequest interface.
 */
export function instanceOfUpdateCartItemRequest(value: object): value is UpdateCartItemRequest {
    if (!('cartItemId' in value) || value['cartItemId'] === undefined) return false;
    return true;
}

export function UpdateCartItemRequestFromJSON(json: any): UpdateCartItemRequest {
    return UpdateCartItemRequestFromJSONTyped(json, false);
}

export function UpdateCartItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCartItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cartItemId': json['cartItemId'],
        'frameId': json['frameId'] == null ? undefined : json['frameId'],
        'materialId': json['materialId'] == null ? undefined : json['materialId'],
        'sizeId': json['sizeId'] == null ? undefined : json['sizeId'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function UpdateCartItemRequestToJSON(value?: UpdateCartItemRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartItemId': value['cartItemId'],
        'frameId': value['frameId'],
        'materialId': value['materialId'],
        'sizeId': value['sizeId'],
        'quantity': value['quantity'],
    };
}

