import React, { useMemo, useState } from 'react';
import SSRContext from '../service/SSRContext';

type SearchContextType = {
    searchTerm: string | undefined;
    setSearchTerm: (searchTerm: string | undefined) => void;
};

export const SearchContext = React.createContext<SearchContextType>({
    searchTerm: '',
    setSearchTerm: () => {},
});

type Props = {
    readonly children: React.ReactNode;
};

export default function SearchProvider({ children }: Props) {
    const [searchTerm, setSearchTerm] = useState<string | undefined>(SSRContext.getQueryParameter('q'));

    const contextValue = useMemo(() => ({ searchTerm, setSearchTerm }), [searchTerm, setSearchTerm]);

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}
