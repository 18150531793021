import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Image, Placeholder, Ratio } from 'react-bootstrap';
import { TbFlame, TbHeart, TbHeartFilled } from 'react-icons/tb';
import { useSpring, animated } from 'react-spring';
import './Listing.scss';
import { Link } from 'react-router-dom';
import RandomGenerator from '../../utils/Random';
import Masonry from 'react-masonry-css';
import { GetShelfShelfEnum, SimplePosterResponse, SimplePosterResponseAspectRatioEnum } from '../../generated';
import { WishlistContext } from '../../provider/WishlistProvider';
import SSRContext from '../../service/SSRContext';
import { getAspectRatioValue } from '../../service/Utils';
import NotFound from '../Product/NotFound';
import { getSizeBasedOnDevice, ListingContext } from '../../provider/ListingProvider';
import { useTranslation } from 'react-i18next';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

type ProductProps = {
    readonly product: SimplePosterResponse;
};

export function ProductDisplay({ product }: ProductProps) {
    const [widthProps, setWidth] = useSpring(() => ({ width: '0px' }));
    const [bgColorProps, setBgColor] = useSpring(() => ({ backgroundColor: 'transparent' }));
    const [favoriteBgColor, setFavoriteBgColor] = useSpring(() => ({ backgroundColor: 'transparent', color: 'white' }));
    const [imageLoading, setImageLoading] = useState(SSRContext.isInClient());
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const { isFav, removePoster, addPoster } = useContext(WishlistContext);

    const isFavorite = isFav(product.posterId ?? '');

    const handleMouseEnter = () => {
        setWidth({ width: '65px', config: { duration: 300 } });
        setBgColor({ backgroundColor: 'red', config: { duration: 0 } });
        setFavoriteBgColor({ backgroundColor: 'white', color: 'black', config: { duration: 300 } });
    };

    const handleMouseLeave = () => {
        setWidth({ width: '22px', config: { duration: 300 } });
        setBgColor({ backgroundColor: 'transparent', config: { duration: 300 } });
        setFavoriteBgColor({ backgroundColor: 'transparent', color: 'white', config: { duration: 300 } });
    };

    const handleImageLoaded = () => {
        setImageLoading(false);
    };

    const [containerWidth, setContainerWidth] = useState<number>(0);

    useEffect(() => {
        setContainerWidth(imageContainerRef?.current?.offsetWidth ?? 0);
    }, []);

    useLayoutEffect(() => {
        const updateWidth = () => {
            if (imageContainerRef.current) {
                setContainerWidth(imageContainerRef.current.offsetWidth);
            }
        };

        setTimeout(updateWidth, 0); // doing this becuase some reason some tiles are not rendered properly and the width is smaller then it should be
        updateWidth();

        if (SSRContext.isInClient()) window.addEventListener('resize', updateWidth);
        return () => {
            if (SSRContext.isInClient()) window.removeEventListener('resize', updateWidth);
        };
    }, []);

    const aspectRatioValue = getAspectRatioValue(product.aspectRatio ?? SimplePosterResponseAspectRatioEnum._23);
    const placeholderHeight = containerWidth / aspectRatioValue;

    return (
        <div className="unselectable">
            <div className="product">
                <div
                    className="product-image shadow-sm"
                    style={{ position: 'relative' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={imageContainerRef}
                >
                    <Link to={`/poster/${product.defaultName}`} className="fit d-block" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        {imageLoading && SSRContext.isInClient() && (
                            // Show placeholder while the image is loading
                            <Placeholder animation="glow" className="product-background-image custom-rounded">
                                <Placeholder style={{ width: `${containerWidth}px`, height: `${placeholderHeight}px` }} bg="secondary" />
                            </Placeholder>
                        )}
                        <Image
                            width={SSRContext.isInClient() ? containerWidth : undefined}
                            height={SSRContext.isInClient() ? placeholderHeight : undefined}
                            src={createPosterImageUrl(ImageSize.Normal, product.image)}
                            alt={product.altText}
                            fluid
                            className={`product-background-image custom-rounded ${imageLoading && SSRContext.isInClient() ? 'd-none' : ''}`} // Hide image while it's loading
                            onLoad={handleImageLoaded} // Handle image load event
                        />
                    </Link>
                    {/* containerWidth {containerWidth} */}
                    {product.isHot && (
                        <animated.div className="product-hot-tag product-overlay rounded-pill p-1 ps-2" style={{ ...widthProps, ...bgColorProps }}>
                            <TbFlame className="product-hot-icon mb-1" size={18} />
                            <span className="fs-6">Hot</span>
                        </animated.div>
                    )}
                    <div className="product-overlay " style={{ flexDirection: 'row-reverse' }}>
                        <animated.div
                            className="rounded-circle p-1 product-favorite-icon "
                            style={favoriteBgColor}
                            onClick={() => {
                                if (product.posterId) {
                                    if (isFavorite) {
                                        removePoster(product.posterId);
                                    } else {
                                        addPoster(product.posterId);
                                    }
                                }
                            }}
                        >
                            {isFavorite ? <TbHeartFilled className="pb-1" size={22} /> : <TbHeart className="pb-1" size={22} />}
                        </animated.div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const aspectsRatios = [(2 / 3) * 100, (4 / 3) * 100];

const breakpointColumnsObj = {
    default: 5,
    1200: 4,
    1000: 3,
    900: 2,
    370: 1,
};
type Props = {
    readonly products: SimplePosterResponse[] | undefined;
    readonly isLoading?: boolean;
};

export default function Listing({ products, isLoading }: Props) {
    const R = new RandomGenerator();

    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.LISTING_POSTER_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.LISTING_POSTER_placeholders', { returnObjects: true }) as string[], [t]);

    const { clearFilters } = useContext(ListingContext);

    return (
        <>
            <Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-grid_column">
                {products &&
                    products.length > 0 &&
                    products.map((product) => (
                        <div key={product.posterId}>
                            <ProductDisplay product={product} />
                        </div>
                    ))}
                {isLoading &&
                    Array.from(Array(getSizeBasedOnDevice()).keys()).map((key) => (
                        <div key={key}>
                            <Ratio aspectRatio={aspectsRatios[(R.random() * aspectsRatios.length) | 0]}>
                                <Placeholder animation="glow">
                                    <Placeholder className="product-image-placeholder" />
                                </Placeholder>
                            </Ratio>
                        </div>
                    ))}
            </Masonry>
            {(!products || products?.length == 0) && !isLoading && (
                <NotFound
                    title={t('NOT_FOUND.LISTING_POSTER_title')}
                    description={descriptions}
                    placeholder={placeholders}
                    shelfEnum={GetShelfShelfEnum.FrequentlyBoughtTogether}
                    onClickCallback={clearFilters}
                />
            )}
        </>
    );
}
