import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TbTruckDelivery, TbShieldDollar, TbClock } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

export default function TrustShelf() {
    const { t } = useTranslation();

    return (
        <Row className="mt-5 pt-5">
            <Col md={4} className="mb-3">
                <div className="gray-section p-3 h-100 rounded">
                    <h4>
                        <TbTruckDelivery size={40} className="pb-2" />
                        {t('TRUST.SwiftDelivery_Title')}
                    </h4>
                    <p>{t('TRUST.SwiftDelivery_Description')}</p>
                </div>
            </Col>
            <Col md={4} className="mb-3">
                <div className="gray-section p-3 h-100 rounded">
                    <h4>
                        <TbShieldDollar size={40} className="pb-2" />
                        {t('TRUST.SecureTransactions_Title')}
                    </h4>
                    <p>{t('TRUST.SecureTransactions_Description')}</p>
                </div>
            </Col>
            <Col md={4} className="mb-3">
                <div className="gray-section p-3 h-100 rounded">
                    <h4>
                        <TbClock size={40} className="pb-2" />
                        {t('TRUST.ReturnPolicy_Title')}
                    </h4>
                    <p>{t('TRUST.ReturnPolicy_Description')}</p>
                </div>
            </Col>
        </Row>
    );
}
