/**
 * Utility function to save a query parameter in the current URL.
 * It updates the URL without causing a browser navigation.
 *
 * @param {string} param - The query parameter key.
 * @param {string} value - The value to set for the query parameter.
 */
export const saveQueryParam = (param: string, value: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(param, value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
};

/**
 * Utility function to remove a query parameter from the current URL.
 *
 * @param {string} param - The query parameter key to remove.
 */
export const removeQueryParam = (param: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete(param);
    const params = queryParams.toString();
    const newUrl = `${window.location.pathname}${params && params.length > 0 ? '?' : ''}${queryParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
};
