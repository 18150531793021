import React from 'react';
import { ShelfControllerApi, ShelfDetails } from '../../generated';
import { API_CONFIG, SHELF_PREFIX } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { ProductPlace } from './ProductShelf';
import Shelf from './Shelf';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const shelfClient = new ShelfControllerApi(API_CONFIG);

type Props = {
    category: string;
    size?: 'sm' | 'md' | 'lg';
    addCart?: 'none' | 'hover' | 'bottom';
    title?: string;
};

export default function CategoryShelf({ category, size = 'md', className, addCart, title, ...htmlProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { t } = useTranslation();

    const fetchShelf = async () => {
        if (!category) return undefined;
        return shelfClient.getShelfByCategory({ category }).catch((error) => {
            console.error(error);
            return undefined;
        });
    };

    const [shelf] = useStateSSRInit<ShelfDetails | undefined>(fetchShelf, SHELF_PREFIX + category.toUpperCase());

    return (
        <>
            {shelf?.posters && shelf.posters.length >= 1 ? (
                <div className="mt-5">
                    <Stack direction="horizontal" gap={1} className="mb-3">
                        <h1 className="fs-2 display-4">{title ?? `Posters about ${category.toLowerCase()}`}</h1>{' '}
                        <Link
                            to={`/listing?category=${category.toUpperCase()}`}
                            className="text-decoration-none"
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            <span
                                className="rounded-pill bg-primary color-white px-2 fs-6 ms-2 mb-2"
                                style={{
                                    paddingTop: '0.2rem',
                                    paddingBottom: '0.2rem',
                                }}
                            >
                                {t('BUTTON.discoverMore')}
                            </span>
                        </Link>
                    </Stack>
                    <Shelf className={className} {...htmlProps} margin={1}>
                        {Array.from(shelf.posters).map((product) => (
                            <ProductPlace key={product.posterId} size={size} product={product} addCart={addCart} />
                        ))}
                    </Shelf>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
