import React from 'react';
import PhoenixAccordion, { SectionProps } from '../Accordion/PhoenixAccordion';

export default function CheckoutWhatNext() {
    const sections: SectionProps[] = [
        {
            title: 'When will my poster be shipped?',
            children: (
                <p className="my-3">
                    Your poster will be shipped within 3-5 business days after your order is confirmed. You&apos;ll receive a tracking number via email as soon
                    as it&apos;s on its way!
                </p>
            ),
        },
        {
            title: 'Can I change my shipping address after placing an order?',
            children: (
                <p className="my-3">
                    If you need to change your shipping address, please contact our support team immediately. We&apos;ll do our best to accommodate changes, but
                    once the order is processed for shipping, we can&apos;t guarantee modifications.
                </p>
            ),
        },
        {
            title: 'What is your return and exchange policy?',
            children: (
                <p className="my-3">
                    We stand by the quality of our products. If you&apos;re not satisfied with your purchase, you can return or exchange it within 30 days.
                    Please check our detailed return policy for more information.
                </p>
            ),
        },
        {
            title: 'How can I track my order?',
            children: (
                <p className="my-3">
                    Once your order is shipped, you&apos;ll receive an email with a tracking number. You can also track your order status on our website using
                    your order number and email.
                </p>
            ),
        },
    ];

    return (
        <div>
            <h4 className="display-4 fs-2 mt-5 mb-4">What&apos;s next?</h4>
            <PhoenixAccordion sections={sections} />
        </div>
    );
}
