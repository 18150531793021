import React, { useTransition } from 'react';
import Masonry from 'react-masonry-css';
import { ProductDisplay } from '../Listing/Listing';
import { GetShelfShelfEnum, ShelfControllerApi, ShelfDetails, SimpleTagResponse, TagControllerApi } from '../../generated';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { API_CONFIG, LISTING_CATEGORIES } from '../../resources/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../service/Utils';

const breakpointColumnsObj = {
    default: 6,
    1400: 5,
    1200: 4,
    1000: 3,
    900: 2,
    370: 1,
};

type Props = {
    readonly title?: string;
    readonly subTitle?: string;
    readonly suggestCategories?: boolean;
    readonly discoverMore?: boolean;
};

const SHELF: GetShelfShelfEnum = GetShelfShelfEnum.ListingShelf;

const shelfClient = new ShelfControllerApi(API_CONFIG);
const categoryClient = new TagControllerApi(API_CONFIG);

const fetchCategory = async (): Promise<SimpleTagResponse[] | undefined> => {
    try {
        return categoryClient.getCategories();
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

export default function ListingShelf({ title, subTitle, suggestCategories = true, discoverMore }: Props) {
    const { t } = useTranslation();

    const [categories] = useStateSSRInit<SimpleTagResponse[] | undefined>(
        () => fetchCategory().then((r) => r?.slice(0, Math.min(8, r.length))),
        LISTING_CATEGORIES,
        []
    );

    const fetchShelf = async () => {
        try {
            const response = await shelfClient.getShelf({ shelf: SHELF });
            setShelf(response);
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const [shelf, setShelf] = useStateSSRInit<ShelfDetails | undefined>(fetchShelf, SHELF);

    return (
        <div>
            <div
                className="text-center"
                style={{
                    margin: '5rem 0',
                }}
            >
                <h1 className="display-4">{title}</h1>
                {subTitle && <p className="fs-4">{subTitle}</p>}
                {suggestCategories &&
                    categories?.map((tag) => (
                        <div
                            key={tag.defaultName}
                            className="rounded-pill py-1 px-3 d-inline-block m-1 cursor-pointer"
                            style={{
                                width: 'fit-content',
                                backgroundColor: '#f8f9fa',
                            }}
                        >
                            <Link
                                to={`/listing?category=${tag?.defaultName}`}
                                className="fit d-block text-decoration-none"
                                style={{
                                    color: '#212529',
                                }}
                            >
                                {capitalize(tag?.translatedName)}
                            </Link>
                        </div>
                    ))}
            </div>
            {shelf?.posters && shelf.posters.length >= 1 && (
                <Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-grid_column">
                    {Array.from(shelf.posters).map((product) => (
                        <div key={product.posterId}>
                            <ProductDisplay product={product} />
                        </div>
                    ))}
                </Masonry>
            )}
            <div className="text-center h-100 mt-3 pt-3">
                {discoverMore && (
                    <Link
                        to="/listing"
                        className="primary-button text-decoration-none shadow-sm"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        {t('BUTTON.discoverMore')}
                    </Link>
                )}
            </div>
        </div>
    );
}
