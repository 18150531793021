/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageResponse
 */
export interface LanguageResponse {
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    flagUrl?: string;
}

/**
 * Check if a given object implements the LanguageResponse interface.
 */
export function instanceOfLanguageResponse(value: object): value is LanguageResponse {
    return true;
}

export function LanguageResponseFromJSON(json: any): LanguageResponse {
    return LanguageResponseFromJSONTyped(json, false);
}

export function LanguageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'name': json['name'] == null ? undefined : json['name'],
        'flagUrl': json['flagUrl'] == null ? undefined : json['flagUrl'],
    };
}

export function LanguageResponseToJSON(value?: LanguageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'name': value['name'],
        'flagUrl': value['flagUrl'],
    };
}

