/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPosterToWishlistRequest,
  RemovePosterToWishlistRequest,
  SimplePosterResponse,
  WishlistRequest,
  WishlistResponse,
} from '../models/index';
import {
    AddPosterToWishlistRequestFromJSON,
    AddPosterToWishlistRequestToJSON,
    RemovePosterToWishlistRequestFromJSON,
    RemovePosterToWishlistRequestToJSON,
    SimplePosterResponseFromJSON,
    SimplePosterResponseToJSON,
    WishlistRequestFromJSON,
    WishlistRequestToJSON,
    WishlistResponseFromJSON,
    WishlistResponseToJSON,
} from '../models/index';

export interface AddItemRequest {
    addPosterToWishlistRequest: AddPosterToWishlistRequest;
}

export interface GetWishlistRequest {
    wishlistRequest: WishlistRequest;
}

export interface RemoveItemRequest {
    removePosterToWishlistRequest: RemovePosterToWishlistRequest;
}

/**
 * 
 */
export class WishlistControllerApi extends runtime.BaseAPI {

    /**
     */
    async addItemRaw(requestParameters: AddItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimplePosterResponse>> {
        if (requestParameters['addPosterToWishlistRequest'] == null) {
            throw new runtime.RequiredError(
                'addPosterToWishlistRequest',
                'Required parameter "addPosterToWishlistRequest" was null or undefined when calling addItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/wishlist/item`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddPosterToWishlistRequestToJSON(requestParameters['addPosterToWishlistRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimplePosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async addItem(requestParameters: AddItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimplePosterResponse> {
        const response = await this.addItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWishlistRaw(requestParameters: GetWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WishlistResponse>> {
        if (requestParameters['wishlistRequest'] == null) {
            throw new runtime.RequiredError(
                'wishlistRequest',
                'Required parameter "wishlistRequest" was null or undefined when calling getWishlist().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/wishlist/wishlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WishlistRequestToJSON(requestParameters['wishlistRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WishlistResponseFromJSON(jsonValue));
    }

    /**
     */
    async getWishlist(requestParameters: GetWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WishlistResponse> {
        const response = await this.getWishlistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeItemRaw(requestParameters: RemoveItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['removePosterToWishlistRequest'] == null) {
            throw new runtime.RequiredError(
                'removePosterToWishlistRequest',
                'Required parameter "removePosterToWishlistRequest" was null or undefined when calling removeItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/wishlist/item`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemovePosterToWishlistRequestToJSON(requestParameters['removePosterToWishlistRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeItem(requestParameters: RemoveItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeItemRaw(requestParameters, initOverrides);
    }

}
