import React, { useCallback, useMemo, useState } from 'react';
import { ReactNode, createContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import SSRContext from '../service/SSRContext';
import { USER_ID, USER_TYPE, USER_AGE_GROUP, USER_LANGUAGE, USER_PREFERRED_CATEGORY, USER_CART_ITEMS, USER_WISHLIST_ITEMS } from '../resources/constants';

export type GaOptions = {
    cookieUpdate?: boolean;
    /**
     * Default two years
     */
    cookieExpires?: number;
    cookieDomain?: string;
    cookieFlags?: string;
    userId?: string;
    clientId?: string;
    anonymizeIp?: boolean;
    contentGroup1?: string;
    contentGroup2?: string;
    contentGroup3?: string;
    contentGroup4?: string;
    contentGroup5?: string;
    allowAdFeatures?: boolean;
    allowAdPersonalizationSignals?: boolean;
    nonInteraction?: boolean;
    page?: string;
};

type Props = {
    children: ReactNode;
    measurementId: string;
    options?: {
        nonce?: string;
        testMode?: boolean;
        gtagUrl?: string;
        gaOptions?: GaOptions | any;
        gtagOptions?: any;
    };
};

type GAContextType = {
    trackPage: () => void;
};

const GAContext = createContext<GAContextType>({
    trackPage: () => {},
});

export function getGeneralInfo() {
    const info: any = {};
    // Set user id if available
    const userId = SSRContext.get(USER_ID) ?? 'react_test_id';
    if (userId) {
        info['user_id'] = userId;
    }

    // Set user type if available
    const userType = SSRContext.get(USER_TYPE);
    if (userType) {
        info['user_type'] = userType;
    }

    // Set user age group if available
    const userAgeGroup = SSRContext.get(USER_AGE_GROUP);
    if (userAgeGroup) {
        info['user_age_group'] = userAgeGroup;
    }

    // Set user language if available
    const userLanguage = SSRContext.get(USER_LANGUAGE);
    if (userLanguage) {
        info['user_language'] = userLanguage;
    }

    // Set user preferred category if available
    const userPreferredCategory = SSRContext.get(USER_PREFERRED_CATEGORY);
    if (userPreferredCategory) {
        info['user_preferred_category'] = userPreferredCategory;
    }

    // Set user current total cart items if available (default 0)
    info['total_cart_items'] = SSRContext.get(USER_CART_ITEMS) || 0;

    // Set user current total wishlist items if available (default 0)
    info['total_wishlist_items'] = SSRContext.get(USER_WISHLIST_ITEMS) || 0;

    return info;
}

//https://developers.google.com/tag-platform/gtagjs/reference
export default function GAProvider({ children, measurementId, options }: Props) {
    const location = useLocation();
    const [generalInfo] = useState<any>(getGeneralInfo());
    const [initialized, setInitialized] = useState(false);

    const trackPage = useCallback(() => {
        if (SSRContext.isInServer()) return;

        const path = location.pathname;
        const queryParams = location.search;
        const queryParamsObject: any = {};

        if (queryParams) {
            new URLSearchParams(queryParams).forEach((value, key) => {
                queryParamsObject[key] = value;
            });
        }
        ReactGA.send({ hitType: 'pageview', page: path, ...queryParamsObject, ...generalInfo });
    }, [location.pathname, location.search, generalInfo]);

    useEffect(() => {
        if (SSRContext.isInServer()) return;
        if (!initialized) {
            ReactGA.initialize(measurementId);
            setInitialized(true);
        }
        ReactGA.set(generalInfo);
        ReactGA.gtag('set', 'user_properties', generalInfo);
        trackPage();
    }, [measurementId, generalInfo, initialized, trackPage]);

    return <GAContext.Provider value={useMemo(() => ({ trackPage }), [trackPage])}>{children}</GAContext.Provider>;
}
