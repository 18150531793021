import React from 'react';
import UserProfile from '../components/UserProfile/UserProfile';
import { Container } from 'react-bootstrap';

export function UserPage() {
    return (
        <Container
            className="px-4 py-2 rounded"
            style={{
                boxShadow: '0 0 35px rgba(0,0,0,.2)',
                marginTop: '6rem',
            }}
        >
            <UserProfile />
        </Container>
    );
}
