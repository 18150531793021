import React, { useContext } from 'react';
import { TbHeart, TbHeartFilled } from 'react-icons/tb';
import '../Wishlist/Wishlist.scss';
import { CollectionResponse } from '../../generated';
import { Link } from 'react-router-dom';
import { WishlistContext } from '../../provider/WishlistProvider';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';
import { ImageWithPlaceHolder } from '../Image/ImageWithPlaceHolder';

type Props = {
    collection: CollectionResponse;
};

export default function CollectionListing({ collection, ...otherProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { isFav, addPoster, removePoster } = useContext(WishlistContext);
    return (
        <div {...otherProps}>
            <div className="custom-row text-center custom-rounded pb-5">
                {collection?.posters?.map((item, index) => (
                    <div key={item.posterId} className="custom-column">
                        <div className="fit position-relative wishlist-product ">
                            <Link
                                to={`/poster/${item.defaultName}`}
                                className="fit d-block w-100"
                                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                            >
                                <ImageWithPlaceHolder
                                    src={createPosterImageUrl(ImageSize.Normal, item.image)}
                                    alt={item.defaultName}
                                    className="custom-rounded img-wishlist"
                                    parentSelector={`.custom-column:nth-child(${index + 1})`}
                                />
                            </Link>
                            {isFav(item.posterId ?? '') ? (
                                <TbHeartFilled size={20} className="fav-icon-wishlist" onClick={() => removePoster(item.posterId ?? '')} />
                            ) : (
                                <TbHeart size={20} className="fav-icon-wishlist" onClick={() => addPoster(item.posterId ?? '')} />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
