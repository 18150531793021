/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPosterToCollectionRequest,
  CollectionResponse,
  CreateCollectionRequest,
  DeleteCollectionRequest,
  PageSimplerCollectionResponse,
  RemovePosterToCollectionRequest,
  SearchCollectionFilter,
} from '../models/index';
import {
    AddPosterToCollectionRequestFromJSON,
    AddPosterToCollectionRequestToJSON,
    CollectionResponseFromJSON,
    CollectionResponseToJSON,
    CreateCollectionRequestFromJSON,
    CreateCollectionRequestToJSON,
    DeleteCollectionRequestFromJSON,
    DeleteCollectionRequestToJSON,
    PageSimplerCollectionResponseFromJSON,
    PageSimplerCollectionResponseToJSON,
    RemovePosterToCollectionRequestFromJSON,
    RemovePosterToCollectionRequestToJSON,
    SearchCollectionFilterFromJSON,
    SearchCollectionFilterToJSON,
} from '../models/index';

export interface AddPostersToCollectionRequest {
    addPosterToCollectionRequest: AddPosterToCollectionRequest;
}

export interface CreateCollectionOperationRequest {
    createCollectionRequest: CreateCollectionRequest;
}

export interface DeleteCollectionOperationRequest {
    deleteCollectionRequest: DeleteCollectionRequest;
}

export interface GetCollectionRequest {
    collectionId: string;
}

export interface GetCollectionByNameRequest {
    collectionName: string;
}

export interface GetCollectionsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    searchCollectionFilter?: SearchCollectionFilter;
}

export interface RemovePostersFromCollectionRequest {
    removePosterToCollectionRequest: RemovePosterToCollectionRequest;
}

/**
 * 
 */
export class CollectionControllerApi extends runtime.BaseAPI {

    /**
     */
    async addPostersToCollectionRaw(requestParameters: AddPostersToCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['addPosterToCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'addPosterToCollectionRequest',
                'Required parameter "addPosterToCollectionRequest" was null or undefined when calling addPostersToCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection/add-posters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddPosterToCollectionRequestToJSON(requestParameters['addPosterToCollectionRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async addPostersToCollection(requestParameters: AddPostersToCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addPostersToCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCollectionRaw(requestParameters: CreateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        if (requestParameters['createCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'createCollectionRequest',
                'Required parameter "createCollectionRequest" was null or undefined when calling createCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCollectionRequestToJSON(requestParameters['createCollectionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async createCollection(requestParameters: CreateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.createCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCollectionRaw(requestParameters: DeleteCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['deleteCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'deleteCollectionRequest',
                'Required parameter "deleteCollectionRequest" was null or undefined when calling deleteCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCollectionRequestToJSON(requestParameters['deleteCollectionRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteCollection(requestParameters: DeleteCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCollectionRaw(requestParameters: GetCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        if (requestParameters['collectionId'] == null) {
            throw new runtime.RequiredError(
                'collectionId',
                'Required parameter "collectionId" was null or undefined when calling getCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/id/{collectionId}`.replace(`{${"collectionId"}}`, encodeURIComponent(String(requestParameters['collectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCollection(requestParameters: GetCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.getCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCollectionByNameRaw(requestParameters: GetCollectionByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        if (requestParameters['collectionName'] == null) {
            throw new runtime.RequiredError(
                'collectionName',
                'Required parameter "collectionName" was null or undefined when calling getCollectionByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collection/{collectionName}`.replace(`{${"collectionName"}}`, encodeURIComponent(String(requestParameters['collectionName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCollectionByName(requestParameters: GetCollectionByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.getCollectionByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCollectionsRaw(requestParameters: GetCollectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSimplerCollectionResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection/filtered`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchCollectionFilterToJSON(requestParameters['searchCollectionFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSimplerCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCollections(requestParameters: GetCollectionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSimplerCollectionResponse> {
        const response = await this.getCollectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePostersFromCollectionRaw(requestParameters: RemovePostersFromCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['removePosterToCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'removePosterToCollectionRequest',
                'Required parameter "removePosterToCollectionRequest" was null or undefined when calling removePostersFromCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collection/remove-posters`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemovePosterToCollectionRequestToJSON(requestParameters['removePosterToCollectionRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async removePostersFromCollection(requestParameters: RemovePostersFromCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removePostersFromCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
