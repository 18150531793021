/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminUserInformation,
  EditUserRequest,
  PageSimpleRowUserResponse,
  SearchUserFilter,
  SimpleUserResponse,
  UserProviderResponse,
  UserResponse,
  WishlistRequest,
} from '../models/index';
import {
    AdminUserInformationFromJSON,
    AdminUserInformationToJSON,
    EditUserRequestFromJSON,
    EditUserRequestToJSON,
    PageSimpleRowUserResponseFromJSON,
    PageSimpleRowUserResponseToJSON,
    SearchUserFilterFromJSON,
    SearchUserFilterToJSON,
    SimpleUserResponseFromJSON,
    SimpleUserResponseToJSON,
    UserProviderResponseFromJSON,
    UserProviderResponseToJSON,
    UserResponseFromJSON,
    UserResponseToJSON,
    WishlistRequestFromJSON,
    WishlistRequestToJSON,
} from '../models/index';

export interface EditUserOperationRequest {
    editUserRequest: EditUserRequest;
}

export interface GetUserByIdRequest {
    userId: string;
}

export interface GetUsersRequest {
    searchUserFilter: SearchUserFilter;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MergeAnonSessionCartRequest {
    anonCartId: string;
}

export interface MergeAnonSessionWishlistRequest {
    wishlistRequest: WishlistRequest;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     */
    async editUserRaw(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters['editUserRequest'] == null) {
            throw new runtime.RequiredError(
                'editUserRequest',
                'Required parameter "editUserRequest" was null or undefined when calling editUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditUserRequestToJSON(requestParameters['editUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     */
    async editUser(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.editUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<string>> {
        const response = await this.getRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSimpleUserDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/simple`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSimpleUserDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleUserResponse> {
        const response = await this.getSimpleUserDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserByIdRaw(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserInformation>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/admin/id/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserInformationFromJSON(jsonValue));
    }

    /**
     */
    async getUserById(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserInformation> {
        const response = await this.getUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUserDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.getUserDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserProviderDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProviderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/provider`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUserProviderDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProviderResponse> {
        const response = await this.getUserProviderDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSimpleRowUserResponse>> {
        if (requestParameters['searchUserFilter'] == null) {
            throw new runtime.RequiredError(
                'searchUserFilter',
                'Required parameter "searchUserFilter" was null or undefined when calling getUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/admin/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchUserFilterToJSON(requestParameters['searchUserFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSimpleRowUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async getUsers(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSimpleRowUserResponse> {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     */
    async mergeAnonSessionCartRaw(requestParameters: MergeAnonSessionCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['anonCartId'] == null) {
            throw new runtime.RequiredError(
                'anonCartId',
                'Required parameter "anonCartId" was null or undefined when calling mergeAnonSessionCart().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['anonCartId'] != null) {
            queryParameters['anonCartId'] = requestParameters['anonCartId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/merge-cart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mergeAnonSessionCart(requestParameters: MergeAnonSessionCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mergeAnonSessionCartRaw(requestParameters, initOverrides);
    }

    /**
     */
    async mergeAnonSessionWishlistRaw(requestParameters: MergeAnonSessionWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['wishlistRequest'] == null) {
            throw new runtime.RequiredError(
                'wishlistRequest',
                'Required parameter "wishlistRequest" was null or undefined when calling mergeAnonSessionWishlist().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/merge-wishlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WishlistRequestToJSON(requestParameters['wishlistRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mergeAnonSessionWishlist(requestParameters: MergeAnonSessionWishlistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mergeAnonSessionWishlistRaw(requestParameters, initOverrides);
    }

}
