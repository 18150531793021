import React from 'react';
import { TbBrandFacebook, TbBrandInstagram, TbBrandPinterest, TbBrandTwitter } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

export default function CommunityShelf() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="text-center my-5 pb-3">
                <h1 className="display-4  mt-3">{t('COMMUNITY.TITLE')}</h1>
                <p className="fs-4">{t('COMMUNITY.SUBTITLE')}</p>
            </div>
            <div className="text-center">
                <ul className="list-inline">
                    <li className="mx-2 fs-4 list-inline-item">
                        <p className="p-0 fw-bold">{t('COMMUNITY.JOIN_US_ON')}</p>
                    </li>
                    <li className="mx-2 fs-4 list-inline-item">
                        <TbBrandInstagram className="fs-3" /> {t('COMMUNITY.INSTAGRAM')}
                    </li>
                    <li className="mx-2 fs-4 list-inline-item">
                        <TbBrandFacebook className="fs-3" /> {t('COMMUNITY.FACEBOOK')}
                    </li>
                    <li className="mx-2 fs-4 list-inline-item">
                        <TbBrandTwitter className="fs-3" /> {t('COMMUNITY.TWITTER')}
                    </li>
                    <li className="mx-2 fs-4 list-inline-item">
                        <TbBrandPinterest className="fs-3" /> {t('COMMUNITY.PINTEREST')}
                    </li>
                </ul>
            </div>
        </div>
    );
}
