/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTagRequest,
  EditTagRequest,
  PageTagResponse,
  SimpleTagResponse,
  TagResponse,
} from '../models/index';
import {
    CreateTagRequestFromJSON,
    CreateTagRequestToJSON,
    EditTagRequestFromJSON,
    EditTagRequestToJSON,
    PageTagResponseFromJSON,
    PageTagResponseToJSON,
    SimpleTagResponseFromJSON,
    SimpleTagResponseToJSON,
    TagResponseFromJSON,
    TagResponseToJSON,
} from '../models/index';

export interface CreateTagOperationRequest {
    createTagRequest: CreateTagRequest;
}

export interface CreateTagsRequest {
    createTagRequest: Array<CreateTagRequest>;
}

export interface DeleteTagRequest {
    name: string;
}

export interface EditTagNameRequest {
    editTagRequest: EditTagRequest;
}

export interface FindByIdRequest {
    id: string;
}

export interface FindByNameRequest {
    name: string;
}

export interface GetAllRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetDynamicRequest {
    tags?: Array<string>;
    aspectRatios?: Array<GetDynamicAspectRatiosEnum>;
    colors?: Array<GetDynamicColorsEnum>;
    category?: string;
}

/**
 * 
 */
export class TagControllerApi extends runtime.BaseAPI {

    /**
     */
    async createTagRaw(requestParameters: CreateTagOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createTagRequest'] == null) {
            throw new runtime.RequiredError(
                'createTagRequest',
                'Required parameter "createTagRequest" was null or undefined when calling createTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTagRequestToJSON(requestParameters['createTagRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createTag(requestParameters: CreateTagOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTagsRaw(requestParameters: CreateTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createTagRequest'] == null) {
            throw new runtime.RequiredError(
                'createTagRequest',
                'Required parameter "createTagRequest" was null or undefined when calling createTags().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tag/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createTagRequest']!.map(CreateTagRequestToJSON),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createTags(requestParameters: CreateTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling deleteTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async editTagNameRaw(requestParameters: EditTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['editTagRequest'] == null) {
            throw new runtime.RequiredError(
                'editTagRequest',
                'Required parameter "editTagRequest" was null or undefined when calling editTagName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tag`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTagRequestToJSON(requestParameters['editTagRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async editTagName(requestParameters: EditTagNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.editTagNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllDistinctTagNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/distinct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findAllDistinctTagNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.findAllDistinctTagNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findByIdRaw(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagResponseFromJSON(jsonValue));
    }

    /**
     */
    async findById(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagResponse> {
        const response = await this.findByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findByNameRaw(requestParameters: FindByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagResponse>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling findByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/name/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagResponseFromJSON(jsonValue));
    }

    /**
     */
    async findByName(requestParameters: FindByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagResponse> {
        const response = await this.findByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllRaw(requestParameters: GetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageTagResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageTagResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAll(requestParameters: GetAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageTagResponse> {
        const response = await this.getAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimpleTagResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimpleTagResponseFromJSON));
    }

    /**
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimpleTagResponse>> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDynamicRaw(requestParameters: GetDynamicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimpleTagResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags'];
        }

        if (requestParameters['aspectRatios'] != null) {
            queryParameters['aspectRatios'] = requestParameters['aspectRatios'];
        }

        if (requestParameters['colors'] != null) {
            queryParameters['colors'] = requestParameters['colors'];
        }

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tag/dynamic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimpleTagResponseFromJSON));
    }

    /**
     */
    async getDynamic(requestParameters: GetDynamicRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimpleTagResponse>> {
        const response = await this.getDynamicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetDynamicAspectRatiosEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type GetDynamicAspectRatiosEnum = typeof GetDynamicAspectRatiosEnum[keyof typeof GetDynamicAspectRatiosEnum];
/**
 * @export
 */
export const GetDynamicColorsEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type GetDynamicColorsEnum = typeof GetDynamicColorsEnum[keyof typeof GetDynamicColorsEnum];
