import React, { ReactNode, useState } from 'react';
import { Stack } from 'react-bootstrap';
import './PhoenixTabs.scss';

export type Tabs = {
    title: string;
    content: ReactNode;
}[];

type Props = {
    readonly tabs: Tabs;
    readonly callback?: (index: number, title: string) => void;
    readonly defaultTab?: number;
    readonly useLazyRendering?: boolean;
};

//Lazy rendering
export default function PhoenixTabs({ tabs, callback, defaultTab = 0, useLazyRendering = false }: Props) {
    const [selectedTab, setSelectedTab] = useState<number>(defaultTab);
    const [renderedTabs, setRenderedTabs] = useState<{ [key: number]: boolean }>({ [defaultTab]: true });

    const handleTabClick = (index: number, title: string) => {
        setSelectedTab(index);
        if (useLazyRendering) {
            setRenderedTabs((prevRenderedTabs) => ({
                ...prevRenderedTabs,
                [index]: true, // Mark the clicked tab as rendered
            }));
        }
        if (callback) callback(index, title);
    };

    return (
        <>
            <Stack direction="horizontal" className="tabs mt-2 mb-3" gap={2}>
                {tabs.map((tab, index) => (
                    <div
                        key={tab.title}
                        className={`tab ${selectedTab === index ? 'tab-active' : ''}`}
                        onClick={() => handleTabClick(index, tab.title)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleTabClick(index, tab.title);
                            }
                        }}
                    >
                        {tab.title}
                    </div>
                ))}
            </Stack>
            <div className="tab-content">
                {tabs.map((tab, index) => (
                    <div key={tab.title} className={`tab-content-item ${selectedTab === index ? 'tab-content-item-active' : ''}`}>
                        {useLazyRendering ? (renderedTabs[index] ? tab.content : null) : tab.content}
                    </div>
                ))}
            </div>
        </>
    );
}
