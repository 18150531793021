/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleTagResponse
 */
export interface SimpleTagResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleTagResponse
     */
    translatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleTagResponse
     */
    defaultName?: string;
}

/**
 * Check if a given object implements the SimpleTagResponse interface.
 */
export function instanceOfSimpleTagResponse(value: object): value is SimpleTagResponse {
    return true;
}

export function SimpleTagResponseFromJSON(json: any): SimpleTagResponse {
    return SimpleTagResponseFromJSONTyped(json, false);
}

export function SimpleTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleTagResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'translatedName': json['translatedName'] == null ? undefined : json['translatedName'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
    };
}

export function SimpleTagResponseToJSON(value?: SimpleTagResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'translatedName': value['translatedName'],
        'defaultName': value['defaultName'],
    };
}

