import { MutableRefObject } from 'react';

/**
 * Checks if an HTML element is in view within a specific parent element.
 *
 * @param {HTMLElement | null} el - The element to check.
 * @param {HTMLElement | null} [parent] - The parent element to check within.
 * @param {number} [offset=1] - Offset to apply to the calculation.
 * @returns {boolean} Whether the element is in view within the parent.
 */
export const isInView = (el: HTMLElement | null, parent?: HTMLElement | null, offset = 1) => {
    if (!el) return false;
    if (!parent) parent = el.parentElement;
    if (parent) {
        const rect = el.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        return (
            rect.top >= parentRect.top - offset &&
            rect.left >= parentRect.left - offset &&
            rect.bottom <= parentRect.bottom + offset &&
            rect.right <= parentRect.right + offset
        );
    } else {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) + offset
        );
    }
};

export const scrollToNext = (
    parentRef: MutableRefObject<HTMLDivElement | null>,
    elementsRef: MutableRefObject<{ [key: string]: HTMLDivElement | null }>,
    elements: string[]
) => {
    let hasSeenVisibleFilter = false;
    for (const elem of elements) {
        if (hasSeenVisibleFilter) {
            elementsRef.current[elem]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            return;
        }
        const isInViewVar = isInView(elementsRef.current[elem], parentRef.current);
        if (isInViewVar) {
            hasSeenVisibleFilter = true;
        }
    }
};

export const scrollToPrevious = (
    parentRef: MutableRefObject<HTMLDivElement | null>,
    elementsRef: MutableRefObject<{ [key: string]: HTMLDivElement | null }>,
    elements: string[]
) => {
    if (!elementsRef.current && elements?.length <= 0 && !parentRef.current) return;
    let prevElem = elements[0];
    for (const elem of elements) {
        const isInViewVar = isInView(elementsRef.current[elem], parentRef.current);
        if (isInViewVar) {
            elementsRef.current[prevElem]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            return;
        }
        prevElem = elem;
    }
};

export const isRightArrowVisible = (
    elementsRef: MutableRefObject<{ [key: string]: HTMLDivElement | null }>,
    elements: string[],
    targetElement: HTMLElement | null
): boolean => {
    if (!targetElement) return false;
    const isLastElemInView = isInView(elementsRef.current[elements[elements.length - 1]], targetElement);
    return !isLastElemInView;
};

export const isLeftArrowVisible = (
    elementsRef: MutableRefObject<{ [key: string]: HTMLDivElement | null }>,
    elements: string[],
    targetElement: HTMLElement | null
): boolean => {
    if (!targetElement) return false;
    const isFirstElemInView = isInView(elementsRef.current[elements[0]], targetElement);
    return !isFirstElemInView;
};
