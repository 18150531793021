import React, { useMemo, useState } from 'react';
import { AddCartItemRequest, CartControllerApi, CartDTO, CartItemDTO, CartDTOFromJSON, CouponControllerApi, UpdateCartItemRequest } from '../generated';
import { API_CONFIG, CART_CART } from '../resources/constants';
import { useStateSSRInit } from '../utils/DataWrapper';
import SSRContext from '../service/SSRContext';

type CartContextType = {
    removeItem: (itemId: string) => Promise<CartDTO | undefined>;
    editItem: (item: UpdateCartItemRequest) => Promise<CartDTO | undefined>;
    addItem: (posterId: AddCartItemRequest) => Promise<CartDTO | undefined>;
    getItems: () => CartItemDTO[];
    clearCartStorage: () => void;
    applyCoupon: (couponCode: string) => Promise<CartDTO | undefined>;
    fetchCart: () => void;
    isLoading: boolean;
    isExecutingOperation: boolean;
    cart?: CartDTO;
};

export const CartContext = React.createContext<CartContextType>({
    removeItem: () => Promise.resolve({} as CartDTO),
    editItem: () => Promise.resolve({} as CartDTO),
    getItems: () => [],
    addItem: () => Promise.resolve({} as CartDTO),
    clearCartStorage: () => {},
    applyCoupon: () => Promise.resolve({} as CartDTO),
    fetchCart: () => {},
    isLoading: false,
    isExecutingOperation: false,
    cart: undefined,
});

type Props = {
    readonly children: React.ReactNode;
};

const client = new CartControllerApi(API_CONFIG);
const couponClient = new CouponControllerApi(API_CONFIG);

export default function CartProvider({ children }: Props) {
    const [cart, setCart] = useStateSSRInit<CartDTO | undefined>(() => Promise.resolve(undefined), CART_CART, undefined);
    const [isLoading, setIsLoading] = useState<boolean>(!SSRContext.has(CART_CART));
    const [isExecutingOperation, setIsExecutingOperation] = useState<boolean>(false);

    const items = useMemo(() => cart?.cartItems ?? [], [cart]);

    const getItems = () => (cart ? [...items].sort((a, b) => ((a.createdDate ?? 0) as number) - ((b.createdDate ?? 0) as number)) : []);

    const clearCartStorage = () => {
        setCart(undefined);
    };

    const fetchCart = (): void => {
        if (cart) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        client
            .getCart()
            .then((cart) => setCart(cart))
            .catch((error) => {
                console.error(error);
                return undefined;
            })
            .finally(() => setIsLoading(false));
    };

    const removeItem = (itemId: string): Promise<CartDTO | undefined> => {
        setIsExecutingOperation(true);
        return client
            .removeCartItem({
                removeCartRequest: {
                    itemId: itemId,
                },
            })
            .then((newCart) => {
                setCart(newCart);
                return newCart;
            })
            .catch((error) => {
                console.error(error);
                return cart;
            })
            .finally(() => setIsExecutingOperation(false));
    };

    const addItem = (item: AddCartItemRequest): Promise<CartDTO | undefined> => {
        setIsExecutingOperation(true);
        return client
            .addCartItem({
                addCartItemRequest: { ...item },
            })
            .then((newCart) => {
                setCart(newCart);
                return newCart;
            })
            .catch((error) => {
                console.error(error);
                return cart;
            })
            .finally(() => setIsExecutingOperation(false));
    };

    const editItem = (editedItem: UpdateCartItemRequest): Promise<CartDTO | undefined> => {
        setIsExecutingOperation(true);
        return client
            .editCartItem({
                updateCartItemRequest: {
                    ...editedItem,
                },
            })
            .then((newCart) => {
                setCart(newCart);
                return newCart;
            })
            .catch((error) => {
                console.error(error);
                return cart;
            })
            .finally(() => setIsExecutingOperation(false));
    };

    const applyCoupon = (couponCode: string): Promise<CartDTO | undefined> => {
        if (!couponCode) return Promise.resolve(cart);

        setIsExecutingOperation(true);
        return couponClient
            .applyCoupon({
                couponCode: couponCode,
            })
            .then((newCart) => {
                setCart(newCart);
                return newCart;
            })
            .catch((error) => {
                console.error(error);
                return cart;
            })
            .finally(() => setIsExecutingOperation(false));
    };

    const contextValue = useMemo(
        () => ({
            removeItem,
            editItem,
            addItem,
            getItems,
            clearCartStorage,
            applyCoupon,
            fetchCart,
            isExecutingOperation,
            isLoading,
            cart,
        }),
        [cart, isLoading, isExecutingOperation]
    );

    return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
}
