/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditDiscountRequest
 */
export interface EditDiscountRequest {
    /**
     * 
     * @type {string}
     * @memberof EditDiscountRequest
     */
    discountId: string;
    /**
     * 
     * @type {number}
     * @memberof EditDiscountRequest
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof EditDiscountRequest
     */
    discountType?: EditDiscountRequestDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EditDiscountRequest
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EditDiscountRequest
     */
    discountRule?: string;
    /**
     * 
     * @type {string}
     * @memberof EditDiscountRequest
     */
    applicableTo?: EditDiscountRequestApplicableToEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EditDiscountRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditDiscountRequest
     */
    currency?: string;
}


/**
 * @export
 */
export const EditDiscountRequestDiscountTypeEnum = {
    Percentage: 'PERCENTAGE',
    FixedAmount: 'FIXED_AMOUNT',
    FreeShipping: 'FREE_SHIPPING',
    FreeCheapestProduct: 'FREE_CHEAPEST_PRODUCT'
} as const;
export type EditDiscountRequestDiscountTypeEnum = typeof EditDiscountRequestDiscountTypeEnum[keyof typeof EditDiscountRequestDiscountTypeEnum];

/**
 * @export
 */
export const EditDiscountRequestApplicableToEnum = {
    PosterPrice: 'POSTER_PRICE',
    FramePrice: 'FRAME_PRICE',
    MaterialPrice: 'MATERIAL_PRICE',
    SizePrice: 'SIZE_PRICE',
    SubTotalPosterPrice: 'SUB_TOTAL_POSTER_PRICE',
    SubTotalCartPrice: 'SUB_TOTAL_CART_PRICE'
} as const;
export type EditDiscountRequestApplicableToEnum = typeof EditDiscountRequestApplicableToEnum[keyof typeof EditDiscountRequestApplicableToEnum];


/**
 * Check if a given object implements the EditDiscountRequest interface.
 */
export function instanceOfEditDiscountRequest(value: object): value is EditDiscountRequest {
    if (!('discountId' in value) || value['discountId'] === undefined) return false;
    return true;
}

export function EditDiscountRequestFromJSON(json: any): EditDiscountRequest {
    return EditDiscountRequestFromJSONTyped(json, false);
}

export function EditDiscountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditDiscountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'discountId': json['discountId'],
        'order': json['order'] == null ? undefined : json['order'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'discountAmount': json['discountAmount'] == null ? undefined : json['discountAmount'],
        'discountRule': json['discountRule'] == null ? undefined : json['discountRule'],
        'applicableTo': json['applicableTo'] == null ? undefined : json['applicableTo'],
        'active': json['active'] == null ? undefined : json['active'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function EditDiscountRequestToJSON(value?: EditDiscountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountId': value['discountId'],
        'order': value['order'],
        'discountType': value['discountType'],
        'discountAmount': value['discountAmount'],
        'discountRule': value['discountRule'],
        'applicableTo': value['applicableTo'],
        'active': value['active'],
        'currency': value['currency'],
    };
}

