/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface Size
 */
export interface Size {
    /**
     * 
     * @type {string}
     * @memberof Size
     */
    sizeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Size
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Size
     */
    aspectRatio?: SizeAspectRatioEnum;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    price?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Size
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    height?: number;
    /**
     * 
     * @type {Date}
     * @memberof Size
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Size
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Size
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Size
     */
    modifiedBy?: string;
}


/**
 * @export
 */
export const SizeAspectRatioEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type SizeAspectRatioEnum = typeof SizeAspectRatioEnum[keyof typeof SizeAspectRatioEnum];


/**
 * Check if a given object implements the Size interface.
 */
export function instanceOfSize(value: object): value is Size {
    return true;
}

export function SizeFromJSON(json: any): Size {
    return SizeFromJSONTyped(json, false);
}

export function SizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Size {
    if (json == null) {
        return json;
    }
    return {
        
        'sizeId': json['sizeId'] == null ? undefined : json['sizeId'],
        'name': json['name'] == null ? undefined : json['name'],
        'aspectRatio': json['aspectRatio'] == null ? undefined : json['aspectRatio'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : CurrencyFromJSON(json['currency']),
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function SizeToJSON(value?: Size | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sizeId': value['sizeId'],
        'name': value['name'],
        'aspectRatio': value['aspectRatio'],
        'price': value['price'],
        'currency': CurrencyToJSON(value['currency']),
        'width': value['width'],
        'height': value['height'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

