import React, { useContext, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DynamicFilters from '../components/Listing/DynamicFilters';
import Filter from '../components/Listing/Filter';
import Listing from '../components/Listing/Listing';
import { useStateSSRInit } from '../utils/DataWrapper';
import { SimpleTagResponse, TagControllerApi } from '../generated';
import { API_CONFIG, LISTING_CATEGORIES } from '../resources/constants';
import SSRContext from '../service/SSRContext';
import { debounce } from '../service/Utils';
import { ListingContext } from '../provider/ListingProvider';

type Props = {
    readonly searchTerm?: string;
};

const tagClient = new TagControllerApi(API_CONFIG);

const fetchCategoriesFallback = async (): Promise<SimpleTagResponse[] | undefined> => {
    return tagClient
        .getCategories()
        .then((response) => Array.from(response))
        .catch((error) => {
            console.error(error, error.message);
            return undefined;
        });
};

export default function ListingPage({ searchTerm }: Props) {
    const listingRef = useRef<HTMLDivElement>(null);

    const { loadMoreProducts, posters, firstIsLoading } = useContext(ListingContext);

    const [categories] = useStateSSRInit<SimpleTagResponse[] | undefined>(fetchCategoriesFallback, LISTING_CATEGORIES, []);

    useEffect(() => {
        const handleScroll = debounce(() => {
            if (!SSRContext.isInClient()) return;

            const listing = listingRef.current;
            if (!listing) return;

            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            if (scrollTop + clientHeight > scrollHeight - 800) {
                loadMoreProducts();
            }
        }, 100);

        if (SSRContext.isInClient()) window.addEventListener('scroll', handleScroll);

        return () => {
            if (SSRContext.isInClient()) window.removeEventListener('scroll', handleScroll);
        };
    }, [loadMoreProducts]);

    return (
        <Container className="mt-5 custom-container">
            <Row>
                <Col sm={12} md={3} xxl={2}>
                    <Filter categories={categories?.sort()} />
                </Col>
                <Col sm={12} md={9} xxl={10} ref={listingRef}>
                    <DynamicFilters />
                    <Listing products={posters} isLoading={firstIsLoading} />
                </Col>
            </Row>
        </Container>
    );
}
