import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ProductPage } from './ProductPage';
import SignInPage from './SignInPage';
import { ModalProvider } from '../provider/ModalProvider';
import PageNotFound from './PageNotFound/PageNotFound';
import ListingPage from './ListingPage';
import CartPage from './CartPage';
import WishlistPage from './WishlistPage';
import CollectionsPage from './CollectionsPage';
import CollectionPage from './Collection/CollectionPage';
import { UserPage } from './UserPage';
import HomePage from './HomePage';
import Footer from '../components/Footer/Footer';
import GAProvider from '../provider/GAProvider';
import { devOptions, measurementIdDev } from '../resources/google_analytics';
import PaymentCompletePage from './PaymentCompletePage';
import TopMenu from '../components/Menu/TopMenu';
import MenuProvider from '../provider/MenuProvider';
import WishlistProvider from '../provider/WishlistProvider';
import SuccessSignInPage from './SuccessSignInPage';
import CartProvider from '../provider/CartProvider';
import SearchPage from './SearchPage';
import SearchProvider from '../provider/SearchProvider';
import { ErrorPage } from './ErrorPage';
import ListingProvider from '../provider/ListingProvider';
import CollectionProvider from '../provider/CollectionProvider';
import LanguageProvider from '../provider/LanguageProvider';

function ElementWithTopMenu() {
    return (
        <CartProvider>
            <WishlistProvider>
                <SearchProvider>
                    <MenuProvider>
                        <TopMenu />
                        <Outlet />
                        <Footer />
                    </MenuProvider>
                </SearchProvider>
            </WishlistProvider>
        </CartProvider>
    );
}

export function PhenixRoutes() {
    return (
        <LanguageProvider>
            <GAProvider measurementId={measurementIdDev} options={devOptions}>
                <ModalProvider>
                    <Routes>
                        <Route element={<ElementWithTopMenu />}>
                            <Route path={'/'} element={<HomePage />} />
                            <Route path={'/cart'} element={<CartPage />} />
                            <Route path={'/sign-in'} element={<SignInPage />} />
                            <Route path={'/poster/:posterName'} element={<ProductPage />} />
                            <Route
                                path={'/listing'}
                                element={
                                    <ListingProvider>
                                        <ListingPage />
                                    </ListingProvider>
                                }
                            />
                            <Route path={'/wishlist'} element={<WishlistPage />} />
                            <Route path={'/collection/:collectionName'} element={<CollectionPage />} />
                            <Route
                                path={'/collections'}
                                element={
                                    <CollectionProvider>
                                        <CollectionsPage />
                                    </CollectionProvider>
                                }
                            />
                            <Route path={'/secured/user'} element={<UserPage />} />
                            <Route path={'/payment-complete'} element={<PaymentCompletePage />} />
                            <Route
                                path={'search'}
                                element={
                                    <ListingProvider>
                                        <SearchPage />
                                    </ListingProvider>
                                }
                            />
                            <Route path={'/error'} element={<ErrorPage />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                        <Route path="/success-sign-in" element={<SuccessSignInPage />} />
                    </Routes>
                </ModalProvider>
            </GAProvider>
        </LanguageProvider>
    );
}
