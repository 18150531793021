import React, { useEffect, useState } from 'react';
import minimMovieFan from '../../resources/image/collection/miniature/movie_fan.webp';
import moviewFan from '../../resources/image/collection/movie_fan.webp';
import miniTravel from '../../resources/image/collection/miniature/travel.jpg';
import travel from '../../resources/image/collection/travel.webp';
import minidiningRoom from '../../resources/image/collection/miniature/dining_room.webp';
import diningRoom from '../../resources/image/collection/dining_room.webp';
import { Image, Container, Stack } from 'react-bootstrap';
import './CollectionHeader.scss';
import { CollectionResponse } from '../../generated';
import { Link } from 'react-router-dom';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';
import { ImageWithPlaceHolder } from '../Image/ImageWithPlaceHolder';

type CollectionTableProps = {
    readonly mini?: boolean;
    readonly collection: CollectionResponse;
};

function CollectionTable({ mini = false, collection }: CollectionTableProps) {
    const [productsPerRow, setProductsPerRow] = useState<number>(4);
    const collectionName = mini ? 'mini-collection' : 'collection';

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 370) {
                setProductsPerRow(2);
            } else if (window.innerWidth > 1400) {
                setProductsPerRow(6);
            } else if (window.innerWidth > 992) {
                setProductsPerRow(5);
            } else {
                setProductsPerRow(4);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className={`${collectionName}-showcase-table`}>
            <Stack direction="horizontal" style={{ gap: `var(--${collectionName}-showcase-gap)` }} className={`${collectionName}-showcase-row`}>
                {collection?.posters?.slice(0, productsPerRow).map((product) => (
                    <div className={`${collectionName}-showcase-cell`} key={product.posterId}>
                        {mini ? (
                            <ImageWithPlaceHolder
                                parentSelector=".mini-collection-showcase-row"
                                src={createPosterImageUrl(ImageSize.Small, product.image)}
                                className={`${collectionName}-showcase-image`}
                            />
                        ) : (
                            <Link to={`/poster/${product.defaultName}`} className="fit d-block" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                <ImageWithPlaceHolder
                                    parentSelector=".collection-showcase-row"
                                    placeholderStyle={{ height: '100%' }}
                                    placeholderClassName="placeholder-collection-showcase"
                                    src={createPosterImageUrl(ImageSize.Small, product.image)}
                                    className={`${collectionName}-showcase-image`}
                                />
                            </Link>
                        )}
                    </div>
                ))}
            </Stack>
            <Stack direction="horizontal" style={{ gap: `var(--${collectionName}-showcase-gap)` }} className={`${collectionName}-showcase-row`}>
                {collection?.posters?.slice(productsPerRow, productsPerRow * 2).map((product) => (
                    <div className={`${collectionName}-showcase-cell`} key={product.posterId}>
                        {mini ? (
                            <ImageWithPlaceHolder
                                parentSelector=".mini-collection-showcase-row"
                                src={createPosterImageUrl(ImageSize.Small, product.image)}
                                className={`${collectionName}-showcase-image`}
                            />
                        ) : (
                            <Link to={`/poster/${product.defaultName}`} className="fit d-block" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                <ImageWithPlaceHolder
                                    parentSelector=".collection-showcase-row"
                                    placeholderStyle={{ height: '100%' }}
                                    placeholderClassName="placeholder-collection-showcase"
                                    src={createPosterImageUrl(ImageSize.Small, product.image)}
                                    className={`${collectionName}-showcase-image`}
                                />
                            </Link>
                        )}
                    </div>
                ))}
            </Stack>
        </div>
    );
}

type CollectionHeaderProps = {
    readonly collection: CollectionResponse;
};

export default function CollectionHeader({ collection }: CollectionHeaderProps) {
    const [backgroundImage, setBackgroundImage] = useState<string>(travel);

    // Preload the images when the component mounts
    useEffect(() => {
        const imagesToPreload = [travel, moviewFan, diningRoom];
        imagesToPreload.forEach((src) => {
            const img = new window.Image(); // Correctly initialize an image element
            img.src = src; // This loads the image in the background
        });
    }, []);

    return (
        <div className="collection-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Container className="h-100 custom-container">
                <div className="mini-collection-container">
                    <Stack direction="horizontal" className="mini-collection-wrapper" style={{ gap: 'var(--collection-showcase-mini-gap)' }}>
                        <div
                            className={`${backgroundImage == travel ? 'mini-collection-selected' : ''} mini-collection-item`}
                            onClick={() => setBackgroundImage(travel)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setBackgroundImage(travel);
                                }
                            }}
                        >
                            <Image src={miniTravel} width="100%" height="100%" />
                            <CollectionTable mini collection={collection} />
                        </div>
                        <div
                            className={`${backgroundImage == moviewFan ? 'mini-collection-selected' : ''} mini-collection-item`}
                            onClick={() => setBackgroundImage(moviewFan)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setBackgroundImage(moviewFan);
                                }
                            }}
                        >
                            <Image src={minimMovieFan} width="100%" height="100%" />
                            <CollectionTable mini collection={collection} />
                        </div>
                        <div
                            className={`${backgroundImage == diningRoom ? 'mini-collection-selected' : ''} mini-collection-item`}
                            onClick={() => setBackgroundImage(diningRoom)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setBackgroundImage(diningRoom);
                                }
                            }}
                        >
                            <Image src={minidiningRoom} width="100%" height="100%" />
                            <CollectionTable mini collection={collection} />
                        </div>
                    </Stack>
                </div>
                <CollectionTable collection={collection} />
            </Container>
        </div>
    );
}
