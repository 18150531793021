/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCartItemRequest
 */
export interface AddCartItemRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequest
     */
    posterId: string;
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequest
     */
    sizeId: string;
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequest
     */
    frameId: string;
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequest
     */
    materialId: string;
    /**
     * 
     * @type {number}
     * @memberof AddCartItemRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequest
     */
    cartId?: string;
}

/**
 * Check if a given object implements the AddCartItemRequest interface.
 */
export function instanceOfAddCartItemRequest(value: object): value is AddCartItemRequest {
    if (!('posterId' in value) || value['posterId'] === undefined) return false;
    if (!('sizeId' in value) || value['sizeId'] === undefined) return false;
    if (!('frameId' in value) || value['frameId'] === undefined) return false;
    if (!('materialId' in value) || value['materialId'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function AddCartItemRequestFromJSON(json: any): AddCartItemRequest {
    return AddCartItemRequestFromJSONTyped(json, false);
}

export function AddCartItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCartItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'],
        'sizeId': json['sizeId'],
        'frameId': json['frameId'],
        'materialId': json['materialId'],
        'quantity': json['quantity'],
        'cartId': json['cartId'] == null ? undefined : json['cartId'],
    };
}

export function AddCartItemRequestToJSON(value?: AddCartItemRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'sizeId': value['sizeId'],
        'frameId': value['frameId'],
        'materialId': value['materialId'],
        'quantity': value['quantity'],
        'cartId': value['cartId'],
    };
}

