import React, { useEffect, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { LanguageControllerApi, LanguageResponse } from '../generated';
import { API_CONFIG, AVAILABLE_LANGUAGES, LANGUAGE } from '../resources/constants';
import { useStateSSRInit } from '../utils/DataWrapper';
import i18n from 'i18next';

type LanguageContextType = {
    currentLanguage?: LanguageResponse;
    changeLanguage: (languageCode: string) => void;
    getAvailableLanguages: () => void;
    isAvailableLanguagesLoading: boolean;
    availableLanguages: LanguageResponse[];
};

export const LanguageContext = React.createContext<LanguageContextType>({
    currentLanguage: {} as LanguageResponse,
    changeLanguage: () => {},
    getAvailableLanguages: () => {},
    isAvailableLanguagesLoading: false,
    availableLanguages: [],
});

type Props = {
    readonly children: React.ReactNode;
};

const client = new LanguageControllerApi(API_CONFIG);

const fetchAvailableLanguages = async () => {
    return (await client.getAvailableLanguages().catch((e: any) => console.error(e))) ?? [];
};

const fetchCurrentLanguage = async () => {
    return (await client.getCurrentLanguage().catch((e: any) => console.error(e))) ?? {};
};

export default function LanguageProvider({ children }: Props) {
    // Initialize state for current language and available languages
    const [currentLanguage, setCurrentLanguage] = useStateSSRInit<LanguageResponse | undefined>(fetchCurrentLanguage, LANGUAGE);
    const [availableLanguages, setAvailableLanguages] = useState<LanguageResponse[]>([]);
    const [isAvailableLanguagesLoading, setIsAvailableLanguagesLoading] = useState(false);

    const getAvailableLanguages = async () => {
        if ((availableLanguages?.length ?? 0) > 0) {
            return;
        }
        setIsAvailableLanguagesLoading(true);
        fetchAvailableLanguages()
            .then((languages) => setAvailableLanguages(languages))
            .catch((e) => console.error(e))
            .finally(() => setIsAvailableLanguagesLoading(false));
    };

    // Function to change the language and update i18next
    const changeLanguage = (languageCode: string) => {
        client
            .setLanguage({
                languageCode,
            })
            .then((response) => {
                setCurrentLanguage(response); // Update state with the new language
                i18n.changeLanguage(languageCode); // Change language in i18n without page reload
                // Reload the page after changing the language
                window.location.reload();
            })
            .catch((e: any) => console.error(e));
    };

    // Update i18next language when currentLanguage changes
    useEffect(() => {
        if (currentLanguage) {
            i18n.changeLanguage(currentLanguage.code);
        }
    }, [currentLanguage]);

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            currentLanguage,
            changeLanguage,
            getAvailableLanguages,
            isAvailableLanguagesLoading,
            availableLanguages,
        }),
        [currentLanguage, availableLanguages, isAvailableLanguagesLoading]
    );

    return (
        <I18nextProvider i18n={i18n}>
            <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
        </I18nextProvider>
    );
}
