import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormikProps } from 'formik';
import { Form as BSForm, Col, Form, Row, Stack } from 'react-bootstrap';
import './UserImageProfile.scss';
import cat from '../../resources/image/menu/cat.png';
import { UserResponse } from '../../generated';

type Props = {
    editable?: boolean;
    formikProps: FormikProps<UserResponse>;
};

function UserImageProfile({ editable, formikProps }: Props) {
    const { t } = useTranslation(); // Use the translation hook
    const { errors, touched, values, setFieldValue } = formikProps;

    return (
        <div className="user-base-info">
            <div
                className="user-image mx-auto shadow mb-3"
                style={{
                    backgroundImage: `url(${values.isCat ? cat : values.image || cat})`,
                    backgroundSize: 'cover',
                    transition: 'background 0.3s ease-in-out',
                }}
            />
            {editable && (
                <Stack direction="horizontal" gap={2} className="text-center justify-content-center">
                    <Form.Check
                        type="switch"
                        defaultChecked={values.isCat}
                        onChange={() => {
                            setFieldValue('isCat', !values.isCat);
                        }}
                    />
                    {t('USER_IMAGE_PROFILE.iAmCat')}
                </Stack>
            )}
            <Row className="mt-5">
                <Col>
                    <BSForm.Group controlId="firstName" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_IMAGE_PROFILE.firstName')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="firstName"
                                    as={BSForm.Control}
                                    placeholder={t('USER_IMAGE_PROFILE.firstName')}
                                    value={values.firstName}
                                    className={`form-control ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.firstName ? (
                                    <p className="user-form-value">{values.firstName}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_IMAGE_PROFILE.noPhoneNumberFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>
                <Col>
                    <BSForm.Group controlId="lastName" className="mb-3">
                        <BSForm.Label className="user-form-label">{t('USER_IMAGE_PROFILE.lastName')}</BSForm.Label>
                        {editable ? (
                            <>
                                <Field
                                    type="text"
                                    name="lastName"
                                    as={BSForm.Control}
                                    placeholder={t('USER_IMAGE_PROFILE.lastName')}
                                    value={values.lastName}
                                    className={`form-control ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`}
                                />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback fs-7" />
                            </>
                        ) : (
                            <>
                                {values.lastName ? (
                                    <p className="user-form-value">{values.lastName}</p>
                                ) : (
                                    <p className="user-form-value-no-value">{t('USER_IMAGE_PROFILE.noPhoneNumberFound')}</p>
                                )}
                            </>
                        )}
                    </BSForm.Group>
                </Col>
            </Row>
            <BSForm.Group controlId="email" className="mb-3">
                <BSForm.Label className="user-form-label">{t('USER_IMAGE_PROFILE.email')}</BSForm.Label>
                {editable ? (
                    <>
                        <Field
                            type="email"
                            name="email"
                            as={BSForm.Control}
                            placeholder={t('USER_IMAGE_PROFILE.email')}
                            value={values.email}
                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback fs-7" />
                        <BSForm.Text className="text-muted fs-7">{t('USER_IMAGE_PROFILE.emailNote')}</BSForm.Text>
                    </>
                ) : (
                    <>
                        {values.email ? (
                            <p className="user-form-value">{values.email}</p>
                        ) : (
                            <p className="user-form-value-no-value">{t('USER_IMAGE_PROFILE.noPhoneNumberFound')}</p>
                        )}
                    </>
                )}
            </BSForm.Group>
        </div>
    );
}

export default UserImageProfile;
