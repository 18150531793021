import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { StaticRouter } from 'react-router-dom/server';
import { PhenixRoutes } from './pages/Routes';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import './font/Poppins/Poppins-Regular.ttf';
import SSRContext from './service/SSRContext';
import initializeI18n from './i18n';
import i18n from 'i18next';

const anyWindow: any = window;

anyWindow.renderApp = (state?: any) => {
    if (state) SSRContext.setState(state);
    const dontUseSSR = process.env.REACT_APP_SSR === 'false';
    const onDev = window?.location?.hostname === 'localhost';

    if (!i18n.isInitialized) {
        initializeI18n();
    }

    if (dontUseSSR || onDev) {
        createRoot(document.getElementById('root')!).render(
            <BrowserRouter>
                <PhenixRoutes />
            </BrowserRouter>
        );
    } else {
        hydrateRoot(
            document.getElementById('root')!,
            <BrowserRouter>
                <PhenixRoutes />
            </BrowserRouter>
        );
    }
};

anyWindow.renderAppOnServer = () => {
    //TODO add a try catch block for all of this function in production
    try {
        SSRContext.setSSRState(anyWindow);
    } catch (e) {
        console.error(e);
    }

    initializeI18n();
    return ReactDOMServer.renderToString(
        <StaticRouter location={anyWindow.requestUrl}>
            <PhenixRoutes />
        </StaticRouter>
    );
};
