/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface EditUserRequest
 */
export interface EditUserRequest {
    /**
     * 
     * @type {Address}
     * @memberof EditUserRequest
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    preferredCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    isCat?: boolean;
}

/**
 * Check if a given object implements the EditUserRequest interface.
 */
export function instanceOfEditUserRequest(value: object): value is EditUserRequest {
    return true;
}

export function EditUserRequestFromJSON(json: any): EditUserRequest {
    return EditUserRequestFromJSONTyped(json, false);
}

export function EditUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'image': json['image'] == null ? undefined : json['image'],
        'preferredLanguage': json['preferredLanguage'] == null ? undefined : json['preferredLanguage'],
        'preferredCurrency': json['preferredCurrency'] == null ? undefined : json['preferredCurrency'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'isCat': json['isCat'] == null ? undefined : json['isCat'],
    };
}

export function EditUserRequestToJSON(value?: EditUserRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': AddressToJSON(value['address']),
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'image': value['image'],
        'preferredLanguage': value['preferredLanguage'],
        'preferredCurrency': value['preferredCurrency'],
        'phoneNumber': value['phoneNumber'],
        'isCat': value['isCat'],
    };
}

