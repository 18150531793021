/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditTagRequest
 */
export interface EditTagRequest {
    /**
     * 
     * @type {string}
     * @memberof EditTagRequest
     */
    oldDefaultName?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EditTagRequest
     */
    newTagNameLanguages?: { [key: string]: string; };
}

/**
 * Check if a given object implements the EditTagRequest interface.
 */
export function instanceOfEditTagRequest(value: object): value is EditTagRequest {
    return true;
}

export function EditTagRequestFromJSON(json: any): EditTagRequest {
    return EditTagRequestFromJSONTyped(json, false);
}

export function EditTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTagRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'oldDefaultName': json['oldDefaultName'] == null ? undefined : json['oldDefaultName'],
        'newTagNameLanguages': json['newTagNameLanguages'] == null ? undefined : json['newTagNameLanguages'],
    };
}

export function EditTagRequestToJSON(value?: EditTagRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldDefaultName': value['oldDefaultName'],
        'newTagNameLanguages': value['newTagNameLanguages'],
    };
}

