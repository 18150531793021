import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CollectionsFilter from '../components/Collections/CollectionsFilter';
import { API_CONFIG } from '../resources/constants';
import { CollectionControllerApi } from '../generated';
import CollectionsListing from '../components/Collections/CollectionsListing';

const client: CollectionControllerApi = new CollectionControllerApi(API_CONFIG);

export default function CollectionsPage() {
    const { t } = useTranslation();

    return (
        <Container>
            <h1 className="display-4 fs-2 mt-4">{t('COLLECTIONS_PAGE.title')}</h1>
            <hr />
            <CollectionsFilter />
            <CollectionsListing />
        </Container>
    );
}
