import { SEED } from '../resources/constants';
import SSRContext from '../service/SSRContext';

class RandomGenerator {
    private m_w = 123456789;
    private m_z = 987654321;
    private readonly mask = 0xffffffff;

    constructor() {
        try {
            this.setSeed((SSRContext.get(SEED) as number) ?? 1);
        } catch (e) {
            console.error(e);
        }
    }

    // Takes any integer
    private setSeed(i: number) {
        this.m_w = (123456789 + i) & this.mask;
        this.m_z = (987654321 - i) & this.mask;
    }

    // Returns number between 0 (inclusive) and 1.0 (exclusive),
    // just like Math.random().
    public random() {
        this.m_z = (36969 * (this.m_z & 65535) + (this.m_z >> 16)) & this.mask;
        this.m_w = (18000 * (this.m_w & 65535) + (this.m_w >> 16)) & this.mask;
        let result = ((this.m_z << 16) + (this.m_w & 65535)) >>> 0;
        result /= 4294967296;
        return result;
    }
}

export default RandomGenerator;
