import React from 'react';
import PhoenixTabs, { Tabs } from '../Tabs/PhoenixTabs';
import UserSettings from './UserSettings';
import UserOrders from './UserOrders';
import './UserProfile.scss';

const tabs: Tabs = [
    {
        title: 'Settings',
        content: <UserSettings />,
    },
    {
        title: 'Orders',
        content: <UserOrders />,
    },
];

export default function UserProfile() {
    return (
        <div className="user-profile">
            <PhoenixTabs tabs={tabs} useLazyRendering={true} />
        </div>
    );
}
