/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PosterFilter
 */
export interface PosterFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof PosterFilter
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PosterFilter
     */
    aspectRatios?: Array<PosterFilterAspectRatiosEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PosterFilter
     */
    colors?: Array<PosterFilterColorsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PosterFilter
     */
    category?: string;
}


/**
 * @export
 */
export const PosterFilterAspectRatiosEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type PosterFilterAspectRatiosEnum = typeof PosterFilterAspectRatiosEnum[keyof typeof PosterFilterAspectRatiosEnum];

/**
 * @export
 */
export const PosterFilterColorsEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type PosterFilterColorsEnum = typeof PosterFilterColorsEnum[keyof typeof PosterFilterColorsEnum];


/**
 * Check if a given object implements the PosterFilter interface.
 */
export function instanceOfPosterFilter(value: object): value is PosterFilter {
    return true;
}

export function PosterFilterFromJSON(json: any): PosterFilter {
    return PosterFilterFromJSONTyped(json, false);
}

export function PosterFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PosterFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'tags': json['tags'] == null ? undefined : json['tags'],
        'aspectRatios': json['aspectRatios'] == null ? undefined : json['aspectRatios'],
        'colors': json['colors'] == null ? undefined : json['colors'],
        'category': json['category'] == null ? undefined : json['category'],
    };
}

export function PosterFilterToJSON(value?: PosterFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'aspectRatios': value['aspectRatios'],
        'colors': value['colors'],
        'category': value['category'],
    };
}

