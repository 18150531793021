import React, { ReactNode, useContext } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { TbCheck, TbHandClick, TbX } from 'react-icons/tb';
import { ModalContext } from '../../provider/ModalProvider';
import { capitalize } from '../../service/Utils';
import './Filter.scss';
import { ASPECT_RATIO_ICONS, FILTER_COLORS } from '../../resources/constants';
import { PosterFilterAspectRatiosEnum, PosterFilterColorsEnum, SimpleTagResponse } from '../../generated';
import { ListingContext } from '../../provider/ListingProvider';
import { useTranslation } from 'react-i18next';

export type Color = {
    name: string;
    hex: number;
};

function isVertical(aspect: PosterFilterAspectRatiosEnum) {
    const aspectRatio = aspect.slice(2);
    const [firstNumber, lastNumber] = aspectRatio.split('_').map(Number);
    return firstNumber >= lastNumber;
}

const luminanceThreshold = 0.179;

function calculateLuminance(hexColor: string) {
    const rgbColor = hexColor.match(/\w\w/g)?.map((x) => parseInt(x, 16));
    const sRGB = rgbColor?.map((x) => x / 255);
    const linearRGB = sRGB?.map((x) => (x <= 0.04045 ? x / 12.92 : ((x + 0.055) / 1.055) ** 2.4));
    if (!linearRGB || linearRGB.length < 3) return 1;
    const luminance = 0.2126 * linearRGB[0] + 0.7152 * linearRGB[1] + 0.0722 * linearRGB[2];
    return luminance;
}

type ColorButtonProps = {
    readonly color: PosterFilterColorsEnum;
    readonly selected: boolean;
    readonly onClick: () => void;
};

function ColorButton({ color, selected, onClick }: ColorButtonProps) {
    const border = color == 'WHITE' ? 'border border-dark' : color == 'YELLOW' ? 'border border-1' : '';

    const btn = (
        <div
            key={color}
            className={`rounded-circle mx-auto ${border} color-btn ${selected ? 'color-btn-selected' : ''}`}
            style={{
                backgroundColor: FILTER_COLORS.get(color) ?? '',
                color: calculateLuminance(FILTER_COLORS.get(color) || '') > luminanceThreshold ? 'black' : 'white',
            }}
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
        >
            {selected && (
                <div className="text-center">
                    <TbCheck className="color-btn-check" size={15} style={{ verticalAlign: 'middle' }} />
                </div>
            )}
        </div>
    );
    return (
        <>
            {selected ? (
                <div className="p-1 rounded-circle mx-auto border border-1 border-dark" style={{ width: 'fit-content' }}>
                    {btn}
                </div>
            ) : (
                <div className="p-1 mx-auto" style={{ width: 'fit-content', border: '1px solid transparent' }}>
                    {btn}
                </div>
            )}
        </>
    );
}

type Props = {
    categories: SimpleTagResponse[] | undefined;
};

export default function Filter({ categories }: Readonly<Props>) {
    const { t } = useTranslation();
    const { addColor, removeColor, colors, addAspectRatio, removeAspectRatio, aspectRatios, setCategory, category } = useContext(ListingContext);

    const toggleColor = (color: PosterFilterColorsEnum) => {
        if (colors.includes(color)) {
            removeColor(color);
        } else {
            addColor(color);
        }
    };

    const toggleAspect = (aspect: PosterFilterAspectRatiosEnum) => {
        if (aspectRatios.includes(aspect)) {
            removeAspectRatio(aspect);
        } else {
            addAspectRatio(aspect);
        }
    };

    const { showModal, closeModal } = useContext(ModalContext);

    const modal: ReactNode = (
        <Row>
            {categories && categories.length > 0 && (
                <>
                    <Col
                        className="mt-1 mb-1 pointer sublime-hover"
                        xs={6}
                        sm={4}
                        md={3}
                        key={'All'}
                        onClick={() => {
                            setCategory(undefined);
                            closeModal();
                        }}
                    >
                        {capitalize(t('FILTER.all'))}
                    </Col>
                    {categories
                        .sort((a, b) => (a.translatedName ?? '').localeCompare(b.translatedName ?? ''))
                        .map((category) => (
                            <Col
                                className="mt-1 mb-1 pointer sublime-hover"
                                xs={6}
                                sm={4}
                                md={3}
                                key={category.defaultName}
                                onClick={() => {
                                    setCategory(category.defaultName);
                                    closeModal();
                                }}
                            >
                                {capitalize(category?.translatedName ?? '')}
                            </Col>
                        ))}
                </>
            )}
        </Row>
    );

    const modalHeader: ReactNode = (
        <Stack direction="horizontal" className="w-100" gap={2}>
            <h3 className="mb-0">{t('FILTER.choose_category')}</h3>
            <div
                className="ms-auto rounded-circle border border-1 p-1 border-dark pointer"
                onClick={closeModal}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        closeModal();
                    }
                }}
            >
                <TbX
                    style={{
                        width: '25px',
                        height: '25px',
                    }}
                />
            </div>
        </Stack>
    );

    const showCategoryModal = () => {
        showModal(modal, '', modalHeader, {
            centered: true,
            size: 'lg',
        });
    };

    return (
        <Stack>
            {categories && categories.length > 0 && (
                <>
                    <h4 className="mb-3">{t('FILTER.category')}</h4>
                    <Stack direction="horizontal" gap={2}>
                        <div
                            className="rounded p-1 ps-2 w-100 category-display"
                            onClick={showCategoryModal}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    showCategoryModal();
                                }
                            }}
                        >
                            {capitalize(categories.find((c) => c.defaultName == category)?.translatedName ?? category ?? t('FILTER.all'))}
                        </div>
                        <Button variant="outline-secondary" onClick={showCategoryModal}>
                            <TbHandClick />
                        </Button>
                    </Stack>
                    <hr />
                </>
            )}
            <h4 className="mb-1 mt-1">{t('FILTER.color')}</h4>
            <Row className="mx-auto">
                {Object.values(PosterFilterColorsEnum).map((color) => (
                    <Col key={color} className="m-1 text-center" xs={3} sm={2} md={3}>
                        <ColorButton color={color} selected={colors.includes(color)} onClick={() => toggleColor(color)} />
                        <p className="font-weight-light fs-7">{capitalize(t(`FILTER.${color.toLowerCase()}`))}</p>
                    </Col>
                ))}
            </Row>
            <hr />
            <h4 className="mb-1 mt-1">{t('FILTER.aspect')}</h4>
            <p>{t('FILTER.vertical')}</p>
            <Row>
                {Object.values(PosterFilterAspectRatiosEnum)
                    .filter((a) => ASPECT_RATIO_ICONS.get(a))
                    .filter((a) => !isVertical(a))
                    .map((aspect) => (
                        <Col key={aspect} xs="4" md="6">
                            <Stack direction="horizontal" className="ms-1" key={aspect}>
                                <Form.Check
                                    className="aspect-ratio-checkbox"
                                    type="checkbox"
                                    label={
                                        <div className="position-relative">
                                            {ASPECT_RATIO_ICONS.get(aspect)}
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                            >
                                                <span className="fs-7">{aspect.slice(2).replace('_', ':')}</span>
                                            </div>
                                        </div>
                                    }
                                    onChange={() => toggleAspect(aspect as PosterFilterAspectRatiosEnum)}
                                    checked={aspectRatios.includes(aspect)}
                                />
                            </Stack>
                        </Col>
                    ))}
            </Row>
            <p>{t('FILTER.horizontal')}</p>
            <Row className="mb-4">
                {Object.values(PosterFilterAspectRatiosEnum)
                    .filter((a) => ASPECT_RATIO_ICONS.get(a))
                    .filter((a) => isVertical(a))
                    .map((aspect) => (
                        <Col key={aspect} xs="4" md="6">
                            <Stack direction="horizontal" className="ms-1" key={aspect}>
                                <Form.Check
                                    className="aspect-ratio-checkbox"
                                    type="checkbox"
                                    label={
                                        <div className="position-relative">
                                            {ASPECT_RATIO_ICONS.get(aspect)}
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                            >
                                                <span className="fs-7">{aspect.slice(2).replace('_', ':')}</span>
                                            </div>
                                        </div>
                                    }
                                    onChange={() => toggleAspect(aspect as PosterFilterAspectRatiosEnum)}
                                    checked={aspectRatios.includes(aspect)}
                                />
                            </Stack>
                        </Col>
                    ))}
            </Row>
        </Stack>
    );
}
