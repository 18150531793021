import { BackendModule, ReadCallback, InitOptions, CallbackError, MultiReadCallback, ResourceLanguage } from 'i18next';
import * as runtime from '../generated/runtime';
import { API_CONFIG } from '../resources/constants';
import SSRContext from './SSRContext';

interface CustomBackendOptions {}

class PhoenixTranslationFetcher extends runtime.BaseAPI implements BackendModule<CustomBackendOptions> {
    type: 'backend' = 'backend';

    constructor(private services: any, private options: CustomBackendOptions = {}, private coreOptions?: InitOptions) {
        super(API_CONFIG);
        this.init(services, options, coreOptions);
    }

    init(services: any, options: CustomBackendOptions = {}, coreOptions?: InitOptions) {
        this.services = services;
        this.options = options;
        this.coreOptions = coreOptions;
    }

    // Read a single language and namespace
    async read(language: string, namespace: string, callback: ReadCallback) {
        try {
            console.log(`Reading translations for ${language} and namespace ${namespace}`);
            const translations = SSRContext.isInClient() ? await this.loadTranslations(language) : SSRContext.getSSRTranslationResources();
            callback(null, translations?.[language] ?? {});
        } catch (err) {
            console.error(`Failed to load translations for ${language}:`, err);
            callback(err as CallbackError, false);
        }
    }

    // Read multiple languages and namespaces
    readMulti(languages: readonly string[], namespaces: readonly string[], callback: MultiReadCallback): void {
        Promise.all(languages.map((lang) => this.loadTranslations(lang)))
            .then((allTranslations) => {
                const combinedTranslations: { [language: string]: ResourceLanguage } = {};
                languages.forEach((lang, index) => {
                    combinedTranslations[lang] = allTranslations[index];
                });
                callback(null, combinedTranslations);
            })
            .catch((err) => {
                callback(err as CallbackError, null);
            });
    }

    // Load translations from the server
    async loadTranslations(language: string): Promise<ResourceLanguage> {
        const headerParameters: runtime.HTTPHeaders = {
            'Content-Type': 'application/json',
        };

        const response = await this.request({
            path: `/locales/${language}.min.json`,
            method: 'GET',
            headers: headerParameters,
        });

        const jsonResponse = await new runtime.JSONApiResponse(response, (jsonValue) => jsonValue).value();
        return { [language]: jsonResponse } as ResourceLanguage;
    }
}

export default PhoenixTranslationFetcher;
