/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddFormatRequest
 */
export interface AddFormatRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFormatRequest
     */
    posterId: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof AddFormatRequest
     */
    sizes?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AddFormatRequest
     */
    materials?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AddFormatRequest
     */
    frames?: Set<string>;
}

/**
 * Check if a given object implements the AddFormatRequest interface.
 */
export function instanceOfAddFormatRequest(value: object): value is AddFormatRequest {
    if (!('posterId' in value) || value['posterId'] === undefined) return false;
    return true;
}

export function AddFormatRequestFromJSON(json: any): AddFormatRequest {
    return AddFormatRequestFromJSONTyped(json, false);
}

export function AddFormatRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFormatRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'],
        'sizes': json['sizes'] == null ? undefined : json['sizes'],
        'materials': json['materials'] == null ? undefined : json['materials'],
        'frames': json['frames'] == null ? undefined : json['frames'],
    };
}

export function AddFormatRequestToJSON(value?: AddFormatRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'sizes': value['sizes'] == null ? undefined : Array.from(value['sizes'] as Set<any>),
        'materials': value['materials'] == null ? undefined : Array.from(value['materials'] as Set<any>),
        'frames': value['frames'] == null ? undefined : Array.from(value['frames'] as Set<any>),
    };
}

