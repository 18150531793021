import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { FrameResponse, MaterialResponse, PosterResponse, SizeResponse } from '../generated';

type ProductContextType = {
    sizes: SizeResponse[];
    setSize: (size: SizeResponse) => void;
    getSize: () => SizeResponse | undefined;
    materials: MaterialResponse[];
    setMaterial: (material: MaterialResponse) => void;
    getMaterial: () => MaterialResponse | undefined;
    frames: FrameResponse[];
    setFrame: (frame: FrameResponse) => void;
    getFrame: () => FrameResponse | undefined;
    getTotalPrice: () => number;
    product: PosterResponse;
};

export const ProductContext = createContext<ProductContextType>({
    sizes: [],
    setSize: () => {},
    getSize: () => undefined,
    materials: [],
    setMaterial: () => {},
    getMaterial: () => undefined,
    frames: [],
    setFrame: () => {},
    getFrame: () => undefined,
    getTotalPrice: () => 0,
    product: {}, // and here
});

type Props = {
    children: ReactNode;
    product: PosterResponse;
};

export function ProductProvider({ children, product }: Props) {
    const [sizes, setSizes] = useState<SizeResponse[]>(Array.from(product.sizes ?? []).sort((a, b) => b?.name?.localeCompare(a?.name ?? '') ?? 0));
    const [materials, setMaterials] = useState<MaterialResponse[]>(
        Array.from(product.materials ?? []).sort((a, b) => b?.name?.localeCompare(a?.name ?? '') ?? 0)
    );
    const [frames, setFrames] = useState<FrameResponse[]>(Array.from(product.frames ?? []).sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0));

    const [selectedSize, setSelectedSize] = useState<SizeResponse | undefined>();
    const [selectedMaterial, setSelectedMaterial] = useState<MaterialResponse | undefined>();
    const [selectedFrame, setSelectedFrame] = useState<FrameResponse | undefined>();

    const setSize = useCallback(
        (size: SizeResponse) => {
            setSelectedSize(size);
        },
        [setSelectedSize]
    );

    const setMaterial = useCallback(
        (material: MaterialResponse) => {
            setSelectedMaterial(material);
        },
        [setSelectedMaterial]
    );

    const setFrame = useCallback(
        (frame: FrameResponse) => {
            setSelectedFrame(frame);
        },
        [setSelectedFrame]
    );

    const getTotalPrice = useCallback(() => {
        return (product?.price ?? 0) + (selectedSize?.price ?? 0) + (selectedMaterial?.price ?? 0) + (selectedFrame?.price ?? 0);
    }, [product, selectedSize, selectedMaterial, selectedFrame]);

    useEffect(() => {
        if (product) {
            const sizes = Array.from(product.sizes ?? []).sort((a, b) => b?.name?.localeCompare(a?.name ?? '') ?? 0);
            const materials = Array.from(product.materials ?? []).sort((a, b) => b?.name?.localeCompare(a?.name ?? '') ?? 0);
            const frames = Array.from(product.frames ?? []).sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0);
            setSizes(sizes);
            setMaterials(materials);
            setFrames(frames);
            if (!selectedSize) setSelectedSize(sizes[0]);
            if (!selectedFrame) setSelectedFrame(frames[0]);
            if (!selectedMaterial) setSelectedMaterial(materials[0]);
        }
    }, [product, selectedFrame, selectedMaterial, selectedSize, setFrames, setMaterials, setSizes]);

    const contextValue = useMemo(
        () => ({
            sizes,
            setSize,
            getSize: () => selectedSize,
            materials,
            setMaterial,
            getMaterial: () => selectedMaterial,
            frames,
            setFrame,
            getFrame: () => selectedFrame,
            getTotalPrice,
            product,
        }),
        [sizes, setSize, selectedSize, materials, setMaterial, selectedMaterial, frames, setFrame, selectedFrame, getTotalPrice, product]
    );

    return <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>;
}
