/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface Material
 */
export interface Material {
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    translatableName?: string;
    /**
     * 
     * @type {number}
     * @memberof Material
     */
    price?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Material
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    image?: string;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    modifiedBy?: string;
}

/**
 * Check if a given object implements the Material interface.
 */
export function instanceOfMaterial(value: object): value is Material {
    return true;
}

export function MaterialFromJSON(json: any): Material {
    return MaterialFromJSONTyped(json, false);
}

export function MaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Material {
    if (json == null) {
        return json;
    }
    return {
        
        'materialId': json['materialId'] == null ? undefined : json['materialId'],
        'translatableName': json['translatableName'] == null ? undefined : json['translatableName'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : CurrencyFromJSON(json['currency']),
        'image': json['image'] == null ? undefined : json['image'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function MaterialToJSON(value?: Material | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'materialId': value['materialId'],
        'translatableName': value['translatableName'],
        'price': value['price'],
        'currency': CurrencyToJSON(value['currency']),
        'image': value['image'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

