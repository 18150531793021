/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimplesDiscountDTO
 */
export interface SimplesDiscountDTO {
    /**
     * 
     * @type {string}
     * @memberof SimplesDiscountDTO
     */
    discountId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplesDiscountDTO
     */
    discountType?: SimplesDiscountDTODiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimplesDiscountDTO
     */
    discountValue?: number;
    /**
     * 
     * @type {string}
     * @memberof SimplesDiscountDTO
     */
    discountAppliedTo?: SimplesDiscountDTODiscountAppliedToEnum;
}


/**
 * @export
 */
export const SimplesDiscountDTODiscountTypeEnum = {
    Percentage: 'PERCENTAGE',
    FixedAmount: 'FIXED_AMOUNT',
    FreeShipping: 'FREE_SHIPPING',
    FreeCheapestProduct: 'FREE_CHEAPEST_PRODUCT'
} as const;
export type SimplesDiscountDTODiscountTypeEnum = typeof SimplesDiscountDTODiscountTypeEnum[keyof typeof SimplesDiscountDTODiscountTypeEnum];

/**
 * @export
 */
export const SimplesDiscountDTODiscountAppliedToEnum = {
    PosterPrice: 'POSTER_PRICE',
    FramePrice: 'FRAME_PRICE',
    MaterialPrice: 'MATERIAL_PRICE',
    SizePrice: 'SIZE_PRICE',
    SubTotalPosterPrice: 'SUB_TOTAL_POSTER_PRICE',
    SubTotalCartPrice: 'SUB_TOTAL_CART_PRICE'
} as const;
export type SimplesDiscountDTODiscountAppliedToEnum = typeof SimplesDiscountDTODiscountAppliedToEnum[keyof typeof SimplesDiscountDTODiscountAppliedToEnum];


/**
 * Check if a given object implements the SimplesDiscountDTO interface.
 */
export function instanceOfSimplesDiscountDTO(value: object): value is SimplesDiscountDTO {
    return true;
}

export function SimplesDiscountDTOFromJSON(json: any): SimplesDiscountDTO {
    return SimplesDiscountDTOFromJSONTyped(json, false);
}

export function SimplesDiscountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplesDiscountDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'discountValue': json['discountValue'] == null ? undefined : json['discountValue'],
        'discountAppliedTo': json['discountAppliedTo'] == null ? undefined : json['discountAppliedTo'],
    };
}

export function SimplesDiscountDTOToJSON(value?: SimplesDiscountDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountId': value['discountId'],
        'discountType': value['discountType'],
        'discountValue': value['discountValue'],
        'discountAppliedTo': value['discountAppliedTo'],
    };
}

