/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePosterResponse } from './SimplePosterResponse';
import {
    SimplePosterResponseFromJSON,
    SimplePosterResponseFromJSONTyped,
    SimplePosterResponseToJSON,
} from './SimplePosterResponse';

/**
 * 
 * @export
 * @interface SimplerCollectionResponse
 */
export interface SimplerCollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof SimplerCollectionResponse
     */
    collectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplerCollectionResponse
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplerCollectionResponse
     */
    translatedName?: string;
    /**
     * 
     * @type {Array<SimplePosterResponse>}
     * @memberof SimplerCollectionResponse
     */
    posters?: Array<SimplePosterResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof SimplerCollectionResponse
     */
    hot?: boolean;
}

/**
 * Check if a given object implements the SimplerCollectionResponse interface.
 */
export function instanceOfSimplerCollectionResponse(value: object): value is SimplerCollectionResponse {
    return true;
}

export function SimplerCollectionResponseFromJSON(json: any): SimplerCollectionResponse {
    return SimplerCollectionResponseFromJSONTyped(json, false);
}

export function SimplerCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplerCollectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'collectionId': json['collectionId'] == null ? undefined : json['collectionId'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'translatedName': json['translatedName'] == null ? undefined : json['translatedName'],
        'posters': json['posters'] == null ? undefined : ((json['posters'] as Array<any>).map(SimplePosterResponseFromJSON)),
        'hot': json['hot'] == null ? undefined : json['hot'],
    };
}

export function SimplerCollectionResponseToJSON(value?: SimplerCollectionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectionId': value['collectionId'],
        'defaultName': value['defaultName'],
        'translatedName': value['translatedName'],
        'posters': value['posters'] == null ? undefined : ((value['posters'] as Array<any>).map(SimplePosterResponseToJSON)),
        'hot': value['hot'],
    };
}

