/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterResponse
 */
export interface NewsletterResponse {
    /**
     * 
     * @type {string}
     * @memberof NewsletterResponse
     */
    newsletterId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterResponse
     */
    name?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof NewsletterResponse
     */
    emails?: Set<string>;
}

/**
 * Check if a given object implements the NewsletterResponse interface.
 */
export function instanceOfNewsletterResponse(value: object): value is NewsletterResponse {
    return true;
}

export function NewsletterResponseFromJSON(json: any): NewsletterResponse {
    return NewsletterResponseFromJSONTyped(json, false);
}

export function NewsletterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'newsletterId': json['newsletterId'] == null ? undefined : json['newsletterId'],
        'name': json['name'] == null ? undefined : json['name'],
        'emails': json['emails'] == null ? undefined : json['emails'],
    };
}

export function NewsletterResponseToJSON(value?: NewsletterResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newsletterId': value['newsletterId'],
        'name': value['name'],
        'emails': value['emails'] == null ? undefined : Array.from(value['emails'] as Set<any>),
    };
}

