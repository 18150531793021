/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditDiscountRequest } from './EditDiscountRequest';
import {
    EditDiscountRequestFromJSON,
    EditDiscountRequestFromJSONTyped,
    EditDiscountRequestToJSON,
} from './EditDiscountRequest';

/**
 * 
 * @export
 * @interface EditCouponRequest
 */
export interface EditCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCouponRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponRequest
     */
    eligibilityRule?: string;
    /**
     * 
     * @type {Array<EditDiscountRequest>}
     * @memberof EditCouponRequest
     */
    discounts?: Array<EditDiscountRequest>;
    /**
     * 
     * @type {string}
     * @memberof EditCouponRequest
     */
    currency?: string;
}

/**
 * Check if a given object implements the EditCouponRequest interface.
 */
export function instanceOfEditCouponRequest(value: object): value is EditCouponRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function EditCouponRequestFromJSON(json: any): EditCouponRequest {
    return EditCouponRequestFromJSONTyped(json, false);
}

export function EditCouponRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditCouponRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'description': json['description'] == null ? undefined : json['description'],
        'eligibilityRule': json['eligibilityRule'] == null ? undefined : json['eligibilityRule'],
        'discounts': json['discounts'] == null ? undefined : ((json['discounts'] as Array<any>).map(EditDiscountRequestFromJSON)),
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function EditCouponRequestToJSON(value?: EditCouponRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'description': value['description'],
        'eligibilityRule': value['eligibilityRule'],
        'discounts': value['discounts'] == null ? undefined : ((value['discounts'] as Array<any>).map(EditDiscountRequestToJSON)),
        'currency': value['currency'],
    };
}

