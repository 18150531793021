import React, { useContext, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import Wishlist from '../components/Wishlist/Wishlist';
import ProductShelf from '../components/Shelf/ProductShelf';
import { GetShelfShelfEnum, WishlistControllerApi } from '../generated';
import { API_CONFIG } from '../resources/constants';
import { WishlistContext } from '../provider/WishlistProvider';
import { useTranslation } from 'react-i18next';
import NotFound from '../components/Product/NotFound';

const client: WishlistControllerApi = new WishlistControllerApi(API_CONFIG);

export default function WishlistPage() {
    const { wishlist } = useContext(WishlistContext);
    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.WHISLIST_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.WHISLIST_placeholders', { returnObjects: true }) as string[], [t]);

    return (
        <Container>
            {(wishlist?.items?.length ?? 0) == 0 ? (
                <NotFound
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                    title={t('NOT_FOUND.WHISLIST_title')}
                    description={descriptions}
                    placeholder={placeholders}
                />
            ) : (
                <>
                    <h1 className="display-4 fs-2 mt-4">{t('WHISHLIST.page_title')}</h1>
                    <hr />
                    <Wishlist products={Array.from(wishlist?.items ?? [])} />
                    <ProductShelf shelfEnum={GetShelfShelfEnum.YouAlsoMightLike} />
                </>
            )}
        </Container>
    );
}
