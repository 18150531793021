/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePosterRequest
 */
export interface CreatePosterRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreatePosterRequest
     */
    description: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreatePosterRequest
     */
    smallDescription: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreatePosterRequest
     */
    altText: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreatePosterRequest
     */
    imageLink: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreatePosterRequest
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof CreatePosterRequest
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePosterRequest
     */
    price?: number;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreatePosterRequest
     */
    tags?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CreatePosterRequest
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePosterRequest
     */
    color: CreatePosterRequestColorEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreatePosterRequest
     */
    sizes?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreatePosterRequest
     */
    materials?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreatePosterRequest
     */
    frames?: Set<string>;
}


/**
 * @export
 */
export const CreatePosterRequestColorEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type CreatePosterRequestColorEnum = typeof CreatePosterRequestColorEnum[keyof typeof CreatePosterRequestColorEnum];


/**
 * Check if a given object implements the CreatePosterRequest interface.
 */
export function instanceOfCreatePosterRequest(value: object): value is CreatePosterRequest {
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('smallDescription' in value) || value['smallDescription'] === undefined) return false;
    if (!('altText' in value) || value['altText'] === undefined) return false;
    if (!('imageLink' in value) || value['imageLink'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    return true;
}

export function CreatePosterRequestFromJSON(json: any): CreatePosterRequest {
    return CreatePosterRequestFromJSONTyped(json, false);
}

export function CreatePosterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePosterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'smallDescription': json['smallDescription'],
        'altText': json['altText'],
        'imageLink': json['imageLink'],
        'name': json['name'],
        'score': json['score'] == null ? undefined : json['score'],
        'price': json['price'] == null ? undefined : json['price'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'currency': json['currency'],
        'color': json['color'],
        'sizes': json['sizes'] == null ? undefined : json['sizes'],
        'materials': json['materials'] == null ? undefined : json['materials'],
        'frames': json['frames'] == null ? undefined : json['frames'],
    };
}

export function CreatePosterRequestToJSON(value?: CreatePosterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'smallDescription': value['smallDescription'],
        'altText': value['altText'],
        'imageLink': value['imageLink'],
        'name': value['name'],
        'score': value['score'],
        'price': value['price'],
        'tags': value['tags'] == null ? undefined : Array.from(value['tags'] as Set<any>),
        'currency': value['currency'],
        'color': value['color'],
        'sizes': value['sizes'] == null ? undefined : Array.from(value['sizes'] as Set<any>),
        'materials': value['materials'] == null ? undefined : Array.from(value['materials'] as Set<any>),
        'frames': value['frames'] == null ? undefined : Array.from(value['frames'] as Set<any>),
    };
}

