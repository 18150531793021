/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaterialResponse
 */
export interface MaterialResponse {
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MaterialResponse
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    currency?: string;
}

/**
 * Check if a given object implements the MaterialResponse interface.
 */
export function instanceOfMaterialResponse(value: object): value is MaterialResponse {
    return true;
}

export function MaterialResponseFromJSON(json: any): MaterialResponse {
    return MaterialResponseFromJSONTyped(json, false);
}

export function MaterialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'materialId': json['materialId'] == null ? undefined : json['materialId'],
        'name': json['name'] == null ? undefined : json['name'],
        'image': json['image'] == null ? undefined : json['image'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function MaterialResponseToJSON(value?: MaterialResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'materialId': value['materialId'],
        'name': value['name'],
        'image': value['image'],
        'price': value['price'],
        'currency': value['currency'],
    };
}

