import React from 'react';
import SSRContext from '../service/SSRContext';
import { UserControllerApi } from '../generated';
import { API_CONFIG } from '../resources/constants';
import { isJson } from '../service/Utils';
import { WISH_LIST_KEY } from '../provider/WishlistProvider';
import { useNavigate } from 'react-router-dom';

const client: UserControllerApi = new UserControllerApi(API_CONFIG);

export default function SuccessSignInPage() {
    const navigate = useNavigate();
    if (SSRContext.isInClient()) {
        const wishlistStorageValue = window.sessionStorage.getItem(WISH_LIST_KEY);
        const wishlist = isJson(wishlistStorageValue) ? JSON.parse(wishlistStorageValue ?? '{}') : undefined;
        window.sessionStorage.removeItem(WISH_LIST_KEY);

        if ((wishlist?.items?.length ?? 0) > 0) {
            Promise.all([wishlist && client.mergeAnonSessionWishlist({ wishlistRequest: { ...wishlist } }).catch((e: any) => console.error(e))]).finally(() => {
                console.log('navigating to /');
                navigate('/');
            });
        } else {
            console.log('navigating to /');
            navigate('/');
        }
    }
    return <></>;
}
