/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCartItemRequest,
  CartDTO,
  RemoveCartRequest,
  UpdateCartItemRequest,
} from '../models/index';
import {
    AddCartItemRequestFromJSON,
    AddCartItemRequestToJSON,
    CartDTOFromJSON,
    CartDTOToJSON,
    RemoveCartRequestFromJSON,
    RemoveCartRequestToJSON,
    UpdateCartItemRequestFromJSON,
    UpdateCartItemRequestToJSON,
} from '../models/index';

export interface AddCartItemOperationRequest {
    addCartItemRequest: AddCartItemRequest;
}

export interface EditCartItemRequest {
    updateCartItemRequest: UpdateCartItemRequest;
}

export interface RemoveCartItemRequest {
    removeCartRequest: RemoveCartRequest;
}

/**
 * 
 */
export class CartControllerApi extends runtime.BaseAPI {

    /**
     */
    async addCartItemRaw(requestParameters: AddCartItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        if (requestParameters['addCartItemRequest'] == null) {
            throw new runtime.RequiredError(
                'addCartItemRequest',
                'Required parameter "addCartItemRequest" was null or undefined when calling addCartItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cart/item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCartItemRequestToJSON(requestParameters['addCartItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async addCartItem(requestParameters: AddCartItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.addCartItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clearCartRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cart/clear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async clearCart(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.clearCartRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async editCartItemRaw(requestParameters: EditCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        if (requestParameters['updateCartItemRequest'] == null) {
            throw new runtime.RequiredError(
                'updateCartItemRequest',
                'Required parameter "updateCartItemRequest" was null or undefined when calling editCartItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cart/item`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCartItemRequestToJSON(requestParameters['updateCartItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async editCartItem(requestParameters: EditCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.editCartItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCartRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async getCart(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.getCartRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCartItemCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cart/item-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getCartItemCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCartItemCountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeCartItemRaw(requestParameters: RemoveCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        if (requestParameters['removeCartRequest'] == null) {
            throw new runtime.RequiredError(
                'removeCartRequest',
                'Required parameter "removeCartRequest" was null or undefined when calling removeCartItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cart/item`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveCartRequestToJSON(requestParameters['removeCartRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async removeCartItem(requestParameters: RemoveCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.removeCartItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
