import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { scrollToNext, scrollToPrevious, isRightArrowVisible, isLeftArrowVisible } from '../../utils/ScrollUtils';
import './DynamicFilters.scss';
import { API_CONFIG, DYNAMIC_FILTER_ICONS, LISTING_DYNAMIC_TAGS } from '../../resources/constants';
import { capitalize } from '../../service/Utils';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { TagControllerApi, PosterFilter, SimpleTagResponse } from '../../generated';
import { ListingContext } from '../../provider/ListingProvider';

/**
 * DynamicFilterElementProps type definition
 *
 * @typedef {Object} DynamicFilterElementProps
 * @property {DynamicFilter} filter - The filter object.
 * @property {Function} togleSelectedFilter - Function to toggle the selected filter.
 * @property {boolean} [active=false] - Whether the filter is active.
 * @property {Function} [forwardedRef] - Ref forwarding function.
 */
type DynamicFilterElementProps = {
    readonly filter: SimpleTagResponse;
    readonly forwardedRef?: (ref: HTMLDivElement | null) => void;
    readonly showIcon?: boolean;
};

/**
 * DynamicFilterElement functional component
 *
 * @param {DynamicFilterElementProps} props - Props for DynamicFilterElement.
 * @returns {ReactNode} Rendered DynamicFilterElement component
 */
function DynamicFilterElement({ filter, forwardedRef, showIcon = true }: DynamicFilterElementProps) {
    const { addTag, removeTag, tags } = useContext(ListingContext);

    const togleSelectedFilter = (filter: string) => {
        if (tags.includes(filter)) {
            removeTag(filter);
        } else {
            addTag(filter);
        }
    };

    return (
        <Stack
            onClick={(e) => togleSelectedFilter(filter.defaultName ?? '')}
            ref={forwardedRef}
            direction="horizontal"
            gap={1}
            className={`dynamic-filter-item no-break rounded-pill pt-2 pb-2 ps-3 pe-3 border user-select-none border-1 ${
                tags.includes(filter.defaultName ?? '') ? 'dynamic-filter-item-active' : ''
            }`}
        >
            {showIcon && DYNAMIC_FILTER_ICONS?.get(filter.defaultName ?? '') && (
                <div className="d-flex">{DYNAMIC_FILTER_ICONS.get(filter.defaultName ?? '')}</div>
            )}
            <span className="user-select-none disable" aria-disabled>
                {capitalize(filter?.translatedName?.replaceAll('_', ' ') ?? '')}
            </span>
        </Stack>
    );
}

const tagClient = new TagControllerApi(API_CONFIG);

export default function DynamicFilters() {
    const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>([]);

    const { filters } = useContext(ListingContext);

    const fetchDynamicTags = (filter?: PosterFilter): Promise<SimpleTagResponse[]> =>
        tagClient
            .getDynamic(filter ?? filters)
            .then((response) => Array.from(response))
            .catch((error) => {
                console.error(error, error.message);
                return [];
            });

    const [dynamicTags, setDynamicTags] = useStateSSRInit<SimpleTagResponse[]>(() => Promise.resolve([]), LISTING_DYNAMIC_TAGS, []);

    useEffect(() => {
        if ((filters.tags ?? []) == tags) return;
        setTags(filters.tags ?? []);
        fetchDynamicTags(filters).then((r) => setDynamicTags(r));
    }, [filters]);

    const ref = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const stackRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (dynamicTags && dynamicTags.length > 0) {
            setShowRightArrow(
                isRightArrowVisible(
                    ref,
                    (dynamicTags ?? []).map((t) => t.defaultName ?? ''),
                    stackRef.current
                )
            );
        }
    }, [dynamicTags]);

    return (
        <>
            {dynamicTags && dynamicTags.length > 0 && (
                <div style={{ position: 'relative' }}>
                    <Stack
                        ref={stackRef}
                        direction="horizontal"
                        gap={2}
                        className="mb-1 ps-1 pb-2 filter-container"
                        onScroll={(e) => {
                            const isRightVisible = isRightArrowVisible(
                                ref,
                                (dynamicTags ?? []).map((t) => t.defaultName ?? ''),
                                e.currentTarget
                            );
                            if (isRightVisible && !showRightArrow) {
                                setShowRightArrow(true);
                            } else if (!isRightVisible && showRightArrow) {
                                setShowRightArrow(false);
                            }

                            const isLeftVisible = isLeftArrowVisible(
                                ref,
                                (dynamicTags ?? []).map((t) => t.defaultName ?? ''),
                                e.currentTarget
                            );
                            if (isLeftVisible && !showLeftArrow) {
                                setShowLeftArrow(true);
                            } else if (!isLeftVisible && showLeftArrow) {
                                setShowLeftArrow(false);
                            }
                        }}
                    >
                        {(dynamicTags ?? []).map((filter) => (
                            <DynamicFilterElement
                                key={filter.defaultName}
                                filter={filter}
                                forwardedRef={(element) => (ref.current[filter.defaultName ?? ''] = element)} // Pass the ref
                            />
                        ))}
                        {showLeftArrow && (
                            <>
                                <div className="blur-left" />
                                <TbChevronLeft
                                    className="chevron-left"
                                    onClick={() =>
                                        scrollToPrevious(
                                            stackRef,
                                            ref,
                                            (dynamicTags ?? []).map((t) => t.defaultName ?? '')
                                        )
                                    }
                                    size={30}
                                />
                            </>
                        )}
                        {showRightArrow && (
                            <>
                                <div className="blur-right" />
                                <TbChevronRight
                                    className="chevron-right"
                                    onClick={() =>
                                        scrollToNext(
                                            stackRef,
                                            ref,
                                            (dynamicTags ?? []).map((t) => t.defaultName ?? '')
                                        )
                                    }
                                    size={30}
                                />
                            </>
                        )}
                    </Stack>
                </div>
            )}
        </>
    );
}
