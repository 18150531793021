import { S3_PATH, ROOT_BUCKET } from '../resources/constants';

export type BackoffType = 'linear' | 'exponential' | 'factorial';

export async function backoffRetrier<T>(
    func: () => Promise<T>,
    backoffType: BackoffType = 'linear',
    baseTime: number = 500,
    retries: number = 5,
    currentRetries: number = 0
): Promise<T | undefined> {
    if (currentRetries < retries) {
        try {
            return await func();
        } catch (error) {
            let delay: number;
            switch (backoffType) {
                case 'linear':
                    delay = linear(currentRetries) * baseTime;
                    break;
                case 'exponential':
                    delay = exponential(currentRetries) * baseTime;
                    break;
                case 'factorial':
                    delay = factorial(currentRetries) * baseTime;
                    break;
                default:
                    delay = linear(currentRetries) * baseTime;
                    break;
            }
            console.log(`Error occurred. Retrying in ${delay}ms... (Attempt ${currentRetries})`);
            await new Promise((resolve) => setTimeout(() => resolve(backoffRetrier(func, backoffType, baseTime, retries, currentRetries + 1)), delay));
        }
    }
    return undefined;
}

function linear(num: number): number {
    return num + 1; // + 1 to avoid 0
}

function factorial(num: number): number {
    if (num == 0) return 1;
    else return num * factorial(num - 1);
}

function exponential(num: number): number {
    return Math.pow(2, num);
}

export enum ImageSize {
    Normal = 'normal',
    Small = 'small',
    Original = 'original',
}

export const createPosterImageUrl = (size: ImageSize, image?: string) => {
    return `${S3_PATH}/${ROOT_BUCKET}/${size}/${image}`;
};
