/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface SimpleProviderResponse
 */
export interface SimpleProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleProviderResponse
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProviderResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProviderResponse
     */
    companyName?: string;
    /**
     * 
     * @type {Address}
     * @memberof SimpleProviderResponse
     */
    address?: Address;
}

/**
 * Check if a given object implements the SimpleProviderResponse interface.
 */
export function instanceOfSimpleProviderResponse(value: object): value is SimpleProviderResponse {
    return true;
}

export function SimpleProviderResponseFromJSON(json: any): SimpleProviderResponse {
    return SimpleProviderResponseFromJSONTyped(json, false);
}

export function SimpleProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleProviderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'providerId': json['providerId'] == null ? undefined : json['providerId'],
        'name': json['name'] == null ? undefined : json['name'],
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
    };
}

export function SimpleProviderResponseToJSON(value?: SimpleProviderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'providerId': value['providerId'],
        'name': value['name'],
        'companyName': value['companyName'],
        'address': AddressToJSON(value['address']),
    };
}

