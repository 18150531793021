import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ListingPage from './ListingPage';
import { SearchContext } from '../provider/SearchProvider';

export default function SearchPage() {
    const navigate = useNavigate();

    const { searchTerm } = useContext(SearchContext);

    if (!searchTerm) {
        navigate('/listing');
    }

    return (
        <>
            <Container className="mt-3 custom-container">
                <h1>Search: &quot;{searchTerm}&quot;</h1>
            </Container>
            <ListingPage searchTerm={searchTerm} />
        </>
    );
}
