/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SizeResponse
 */
export interface SizeResponse {
    /**
     * 
     * @type {string}
     * @memberof SizeResponse
     */
    sizeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SizeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SizeResponse
     */
    aspectRatio?: string;
    /**
     * 
     * @type {number}
     * @memberof SizeResponse
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponse
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof SizeResponse
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof SizeResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof SizeResponse
     */
    nameWithUnits?: string;
}

/**
 * Check if a given object implements the SizeResponse interface.
 */
export function instanceOfSizeResponse(value: object): value is SizeResponse {
    return true;
}

export function SizeResponseFromJSON(json: any): SizeResponse {
    return SizeResponseFromJSONTyped(json, false);
}

export function SizeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SizeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'sizeId': json['sizeId'] == null ? undefined : json['sizeId'],
        'name': json['name'] == null ? undefined : json['name'],
        'aspectRatio': json['aspectRatio'] == null ? undefined : json['aspectRatio'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'nameWithUnits': json['nameWithUnits'] == null ? undefined : json['nameWithUnits'],
    };
}

export function SizeResponseToJSON(value?: SizeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sizeId': value['sizeId'],
        'name': value['name'],
        'aspectRatio': value['aspectRatio'],
        'width': value['width'],
        'height': value['height'],
        'price': value['price'],
        'currency': value['currency'],
        'nameWithUnits': value['nameWithUnits'],
    };
}

