import React, { createContext, ReactNode, useMemo, useRef, useState } from 'react';
import {
    CollectionControllerApi,
    SearchCollectionFilter,
    CollectionResponse,
    SearchCollectionFilterOrderEnum,
    SimplerCollectionResponse,
    PageSimplerCollectionResponse,
} from '../generated';
import { API_CONFIG, COLLECTIONS_COLLECTIONS, LISTING_COLLECTION_FILTER } from '../resources/constants';
import SSRContext from '../service/SSRContext';
import { useStateSSRInit } from '../utils/DataWrapper';
import { saveQueryParam, removeQueryParam } from '../utils/WindowUtils';

type CollectionContextType = {
    collections: CollectionResponse[] | undefined;
    searchCollections: (filter: SearchCollectionFilter, resetPage?: boolean) => void;
    loadMoreCollections: () => void;
    setSearchTerm: (searchTerm: string) => void;
    setCategory: (category: string | undefined) => void;
    setOrder: (order: SearchCollectionFilterOrderEnum) => void;
    clearFilters: () => void;
    firstIsLoading: boolean;
    isLoading: boolean;
    searchTerm: string;
    category: string | undefined;
    order: SearchCollectionFilterOrderEnum;
};

export const CollectionContext = createContext<CollectionContextType>({
    collections: [],
    searchCollections: () => {},
    loadMoreCollections: () => {},
    setSearchTerm: () => {},
    setCategory: () => {},
    setOrder: () => {},
    clearFilters: () => {},
    firstIsLoading: true,
    isLoading: false,
    searchTerm: '',
    category: undefined,
    order: SearchCollectionFilterOrderEnum.Relevant,
});

type Props = {
    children: ReactNode;
};

const collectionClient = new CollectionControllerApi(API_CONFIG);

export const getSizeBasedOnDevice = () => 40; // Default size based on device

const collectionFallback = (page: number, size: number, filter: SearchCollectionFilter): Promise<SimplerCollectionResponse[] | undefined> => {
    return collectionClient
        .getCollections({ searchCollectionFilter: filter, page, size })
        .then((response) => Array.from(response.content ?? []))
        .catch((error) => {
            console.error(error, error.message);
            return undefined;
        });
};

export default function CollectionProvider({ children }: Props) {
    const initialFilter: SearchCollectionFilter = SSRContext.get(LISTING_COLLECTION_FILTER) || {};

    const queryParams = SSRContext.getQueryParameters();
    const categoryParam = queryParams.get('category');
    const searchTermParam = queryParams.get('searchTerm');
    const orderParam = queryParams.get('order') as SearchCollectionFilterOrderEnum;

    if (categoryParam) {
        initialFilter.category = categoryParam;
    }

    if (searchTermParam) {
        initialFilter.searchTerm = searchTermParam;
    }

    if (orderParam) {
        initialFilter.order = orderParam;
    }

    const [collections, setCollections] = useStateSSRInit<SimplerCollectionResponse[] | undefined>(
        () => collectionFallback(0, getSizeBasedOnDevice(), initialFilter).finally(() => setFirstIsLoading(false)),
        COLLECTIONS_COLLECTIONS,
        []
    );

    const [filters, setFilters] = useState<SearchCollectionFilter>(initialFilter);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstIsLoading, setFirstIsLoading] = useState<boolean>(!SSRContext.has(COLLECTIONS_COLLECTIONS));
    const [loadedAll, setLoadedAll] = useState<boolean>(false);

    const pageRef = useRef<number>(0);
    const mostRecentRequest = useRef<number>(0);

    const handleCollectionsResponse = (response: PageSimplerCollectionResponse, requestId: number, resetPage: boolean) => {
        if (mostRecentRequest.current === requestId) {
            const newCollections = Array.from(response.content ?? []);
            setCollections((prev) => (resetPage ? newCollections : [...(prev ?? []), ...newCollections]));

            if (response.last) {
                setLoadedAll(true);
            }
        }
    };

    const fetchCollections = (filter: SearchCollectionFilter, resetPage = false, searchTerm?: string) => {
        if (resetPage) {
            pageRef.current = 0;
            setCollections([]);
            setLoadedAll(false);
            setFirstIsLoading(true);
        }
        setIsLoading(true);
        const currentRequestId = Date.now();
        mostRecentRequest.current = currentRequestId;

        collectionClient
            .getCollections({ searchCollectionFilter: filter, page: pageRef.current, size: getSizeBasedOnDevice() })
            .then((res) => handleCollectionsResponse(res, currentRequestId, resetPage))
            .catch((error) => {
                console.error(error, error.message);
                setIsLoading(false);
            })
            .finally(() => {
                setFirstIsLoading(false);
                setIsLoading(false);
            });
    };

    const loadMoreCollections = () => {
        if (isLoading || loadedAll) return;
        pageRef.current += 1;
        fetchCollections(filters);
    };

    const handleSearchTermChange = (term: string) => {
        const updatedFilters = { ...filters, searchTerm: term };
        setFilters(updatedFilters);
        saveQueryParam('searchTerm', term);
        fetchCollections(updatedFilters, true, term);
    };

    const setCategoryFilter = (category: string | undefined) => {
        const updatedFilters = { ...filters, category };
        setFilters(updatedFilters);
        if (!category) {
            removeQueryParam('category');
        } else {
            saveQueryParam('category', category);
        }
        fetchCollections(updatedFilters, true);
    };

    const setOrder = (order: SearchCollectionFilterOrderEnum) => {
        const updatedFilters = { ...filters, order };
        setFilters(updatedFilters);
        saveQueryParam('order', order);
        fetchCollections(updatedFilters, true);
    };

    const clearFilters = () => {
        const updatedFilters: SearchCollectionFilter = { order: SearchCollectionFilterOrderEnum.Relevant };
        setFilters(updatedFilters);
        removeQueryParam('searchTerm');
        removeQueryParam('category');
        removeQueryParam('order');
        fetchCollections(updatedFilters, true);
    };

    const contextValue = useMemo(
        () => ({
            collections,
            searchCollections: fetchCollections,
            loadMoreCollections,
            setSearchTerm: handleSearchTermChange,
            setCategory: setCategoryFilter,
            setOrder,
            clearFilters,
            firstIsLoading,
            isLoading,
            searchTerm: filters.searchTerm || '',
            category: filters.category,
            order: filters.order || SearchCollectionFilterOrderEnum.Relevant,
        }),
        [filters, collections, isLoading]
    );

    return <CollectionContext.Provider value={contextValue}>{children}</CollectionContext.Provider>;
}
