/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPosterToWishlistRequest
 */
export interface AddPosterToWishlistRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPosterToWishlistRequest
     */
    posterId?: string;
    /**
     * 
     * @type {number}
     * @memberof AddPosterToWishlistRequest
     */
    sessionWishlistSize?: number;
}

/**
 * Check if a given object implements the AddPosterToWishlistRequest interface.
 */
export function instanceOfAddPosterToWishlistRequest(value: object): value is AddPosterToWishlistRequest {
    return true;
}

export function AddPosterToWishlistRequestFromJSON(json: any): AddPosterToWishlistRequest {
    return AddPosterToWishlistRequestFromJSONTyped(json, false);
}

export function AddPosterToWishlistRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPosterToWishlistRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'sessionWishlistSize': json['sessionWishlistSize'] == null ? undefined : json['sessionWishlistSize'],
    };
}

export function AddPosterToWishlistRequestToJSON(value?: AddPosterToWishlistRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'sessionWishlistSize': value['sessionWishlistSize'],
    };
}

