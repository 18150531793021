/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrencyConversionField
 */
export interface CurrencyConversionField {
    /**
     * 
     * @type {string}
     * @memberof CurrencyConversionField
     */
    fromCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyConversionField
     */
    toCurrency?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConversionField
     */
    conversionRate?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyConversionField
     */
    clazz?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyConversionField
     */
    clazzId?: string;
}

/**
 * Check if a given object implements the CurrencyConversionField interface.
 */
export function instanceOfCurrencyConversionField(value: object): value is CurrencyConversionField {
    return true;
}

export function CurrencyConversionFieldFromJSON(json: any): CurrencyConversionField {
    return CurrencyConversionFieldFromJSONTyped(json, false);
}

export function CurrencyConversionFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyConversionField {
    if (json == null) {
        return json;
    }
    return {
        
        'fromCurrency': json['fromCurrency'] == null ? undefined : json['fromCurrency'],
        'toCurrency': json['toCurrency'] == null ? undefined : json['toCurrency'],
        'conversionRate': json['conversionRate'] == null ? undefined : json['conversionRate'],
        'clazz': json['clazz'] == null ? undefined : json['clazz'],
        'clazzId': json['clazzId'] == null ? undefined : json['clazzId'],
    };
}

export function CurrencyConversionFieldToJSON(value?: CurrencyConversionField | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fromCurrency': value['fromCurrency'],
        'toCurrency': value['toCurrency'],
        'conversionRate': value['conversionRate'],
        'clazz': value['clazz'],
        'clazzId': value['clazzId'],
    };
}

