/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    unsorted?: boolean;
}

/**
 * Check if a given object implements the SortObject interface.
 */
export function instanceOfSortObject(value: object): value is SortObject {
    return true;
}

export function SortObjectFromJSON(json: any): SortObject {
    return SortObjectFromJSONTyped(json, false);
}

export function SortObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortObject {
    if (json == null) {
        return json;
    }
    return {
        
        'empty': json['empty'] == null ? undefined : json['empty'],
        'sorted': json['sorted'] == null ? undefined : json['sorted'],
        'unsorted': json['unsorted'] == null ? undefined : json['unsorted'],
    };
}

export function SortObjectToJSON(value?: SortObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'empty': value['empty'],
        'sorted': value['sorted'],
        'unsorted': value['unsorted'],
    };
}

