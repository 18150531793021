/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleRowUserResponse
 */
export interface SimpleRowUserResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleRowUserResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleRowUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleRowUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleRowUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleRowUserResponse
     */
    country?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleRowUserResponse
     */
    enabled?: boolean;
}

/**
 * Check if a given object implements the SimpleRowUserResponse interface.
 */
export function instanceOfSimpleRowUserResponse(value: object): value is SimpleRowUserResponse {
    return true;
}

export function SimpleRowUserResponseFromJSON(json: any): SimpleRowUserResponse {
    return SimpleRowUserResponseFromJSONTyped(json, false);
}

export function SimpleRowUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleRowUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'country': json['country'] == null ? undefined : json['country'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
    };
}

export function SimpleRowUserResponseToJSON(value?: SimpleRowUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'country': value['country'],
        'enabled': value['enabled'],
    };
}

