import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';
import { TbHeartFilled } from 'react-icons/tb';
import './Wishlist.scss';
import { SimplePosterResponse } from '../../generated';
import { WishlistContext } from '../../provider/WishlistProvider';
import { Link } from 'react-router-dom';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

function WishlistProduct({ product }: { product: SimplePosterResponse }) {
    const { removePoster } = useContext(WishlistContext);
    return (
        <div className="fit position-relative wishlist-product">
            <Link to={`/poster/${product.defaultName}`} className="fit d-block" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Image src={createPosterImageUrl(ImageSize.Normal, product.image)} alt={product.altText} className="custom-rounded img-wishlist" />
            </Link>
            <TbHeartFilled size={20} className="fav-icon-wishlist" onClick={() => product?.posterId && removePoster(product.posterId)} />
        </div>
    );
}

export default function Wishlist({ products }: { products: SimplePosterResponse[] }) {
    return (
        <div className="custom-row text-center custom-rounded">
            {products.map((item, index) => (
                <div key={index} className="custom-column">
                    <WishlistProduct product={item} />
                </div>
            ))}
        </div>
    );
}
