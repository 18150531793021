import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './UserOrders.scss';
import { GetShelfShelfEnum, OrderControllerApi, OrderResponse } from '../../generated';
import { API_CONFIG } from '../../resources/constants';
import Order from '../Order/Order';
import { Stack } from 'react-bootstrap';
import NotFound from '../Product/NotFound';
import { useTranslation } from 'react-i18next';

const client: OrderControllerApi = new OrderControllerApi(API_CONFIG);
const pageSize = 5;

export default function UserOrders() {
    const [page] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.ORDER_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.ORDER_placeholders', { returnObjects: true }) as string[], [t]);

    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await client.getOrders({
                page: page,
                size: pageSize,
                sort: {
                    sort: ['createdDate', 'DESC'],
                },
            });
            return response.content;
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [page, setIsLoading]);

    useEffect(() => {
        fetchOrders().then((orders) => setOrders(orders));
    }, [fetchOrders]);

    const [orders, setOrders] = useState<OrderResponse[] | undefined>();

    return (
        <Stack>
            {orders && orders.length > 0 ? (
                orders.map((order) => (
                    <div key={order.orderId}>
                        <Order order={order} />
                        {order.orderId !== orders[orders.length - 1].orderId && <hr />}
                    </div>
                ))
            ) : isLoading ? (
                <Order isLoading={true} />
            ) : (
                <NotFound
                    title={t('NOT_FOUND.ORDER_title')}
                    description={descriptions}
                    placeholder={placeholders}
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                />
            )}
            {isLoading && !orders && <Order isLoading={true} />}
        </Stack>
    );
}
