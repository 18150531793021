import React, { useMemo } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CategoriesShelf.scss';

const categories = [
    { category: 'Anime_Manga', image: 'https://s3.menoita.net/static/menoita_Manga__Anime_1bbe2752-aa4d-4f91-bce4-c5ad79242b68.png' },
    {
        category: 'Animals',
        image: 'https://s3.menoita.net/static/menoita_realistic_cute_white_fox_cinematic_national_geographic_7faa38af-89dc-4ab6-bbb0-bc8685dd76cd.png',
    },
    {
        category: 'Movies',
        image: 'https://s3.menoita.net/static/menoita_cinematic_Harry_Potter_movie_Harry_Potter_and_the_Priso_3552fc42-b846-43a9-a912-592073167437.png',
    },
    { category: 'Gaming', image: 'https://s3.menoita.net/static/menoita_Master_chief_cinematic_gamming_8fe23c31-5c3b-48e6-baf7-2ed3542ecee2.png' },
    {
        category: 'Music',
        image: 'https://s3.menoita.net/static/menoita_Taylor_swift_on_concert_cinematic_music_vivid_00a88109-2089-422c-8584-a5c383d77980.png',
    },
    {
        category: 'Sports',
        image: 'https://s3.menoita.net/static/menoita_Cristiano_Ronaldo_in_a_full_stadio_looking_at_the_publi_84b056e3-2030-4cea-9895-e8280d8bbb13.png',
    },
    { category: 'Space', image: 'https://s3.menoita.net/static/menoita_space_cosmos_stars_f6409aa1-180a-40db-b680-ba8eeff1dbea.png' },
    { category: 'Cities', image: 'https://s3.menoita.net/static/menoita_New_york_black_and_white_cinematic_b283c8c6-cec8-442e-95f3-b8af03f76c9c.png' },
    { category: 'Nature', image: 'https://s3.menoita.net/static/menoita_Nature_cinematic_6f81c9f5-fffa-4ff5-936b-f2d95dce1db7.png' },
    { category: 'Travel', image: 'https://s3.menoita.net/static/menoita_Egypt_paramids_cinematic_738a4c08-889e-43bc-8241-e8eb75f37fb6.png' },
    { category: 'Cars', image: 'https://s3.menoita.net/static/menoita_ferrari_track_cinematic_69b52763-2bf8-4174-a882-d3d3239dffbd.png' },
    { category: 'Fantasy', image: 'https://s3.menoita.net/static/menoita_fantasy_world_cinematic_2ed15248-00f8-4c59-baf4-4922202ab838.png' },
];

export default function CategoriesShelf() {
    const { t } = useTranslation();

    // Use useMemo to translate category names
    const translatedCategories = useMemo(() => {
        return categories.map((category) => {
            const name = t(`SHELF.CATEGORY_${category.category}`, category.category);
            return {
                ...category,
                name,
            };
        });
    }, [t]);

    return (
        <div>
            <Row className="px-3 categories-shelf-row">
                {translatedCategories.map((category) => (
                    <Col key={category.category} md={3} xs={12} sm={4} lg={2} className="p-2">
                        <Link to={`/listing?category=${category.category}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <div className="rounded categories-shelf-img-wrapper">
                                <Image className="rounded categories-shelf-image" src={category.image} />
                                <h2 className="categories-shelf-name">{category.name}</h2>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
