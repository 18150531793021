/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartItemDTO } from './CartItemDTO';
import {
    CartItemDTOFromJSON,
    CartItemDTOFromJSONTyped,
    CartItemDTOToJSON,
} from './CartItemDTO';
import type { SimplesDiscountDTO } from './SimplesDiscountDTO';
import {
    SimplesDiscountDTOFromJSON,
    SimplesDiscountDTOFromJSONTyped,
    SimplesDiscountDTOToJSON,
} from './SimplesDiscountDTO';

/**
 * 
 * @export
 * @interface CartDTO
 */
export interface CartDTO {
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    cartId?: string;
    /**
     * 
     * @type {Array<CartItemDTO>}
     * @memberof CartDTO
     */
    cartItems?: Array<CartItemDTO>;
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    coupon?: string;
    /**
     * 
     * @type {SimplesDiscountDTO}
     * @memberof CartDTO
     */
    discount?: SimplesDiscountDTO;
    /**
     * 
     * @type {number}
     * @memberof CartDTO
     */
    discountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CartDTO
     */
    subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof CartDTO
     */
    totalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    currencySymbol?: string;
}

/**
 * Check if a given object implements the CartDTO interface.
 */
export function instanceOfCartDTO(value: object): value is CartDTO {
    return true;
}

export function CartDTOFromJSON(json: any): CartDTO {
    return CartDTOFromJSONTyped(json, false);
}

export function CartDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'cartId': json['cartId'] == null ? undefined : json['cartId'],
        'cartItems': json['cartItems'] == null ? undefined : ((json['cartItems'] as Array<any>).map(CartItemDTOFromJSON)),
        'coupon': json['coupon'] == null ? undefined : json['coupon'],
        'discount': json['discount'] == null ? undefined : SimplesDiscountDTOFromJSON(json['discount']),
        'discountPrice': json['discountPrice'] == null ? undefined : json['discountPrice'],
        'subtotal': json['subtotal'] == null ? undefined : json['subtotal'],
        'totalPrice': json['totalPrice'] == null ? undefined : json['totalPrice'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'currencySymbol': json['currencySymbol'] == null ? undefined : json['currencySymbol'],
    };
}

export function CartDTOToJSON(value?: CartDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartId': value['cartId'],
        'cartItems': value['cartItems'] == null ? undefined : ((value['cartItems'] as Array<any>).map(CartItemDTOToJSON)),
        'coupon': value['coupon'],
        'discount': SimplesDiscountDTOToJSON(value['discount']),
        'discountPrice': value['discountPrice'],
        'subtotal': value['subtotal'],
        'totalPrice': value['totalPrice'],
        'currency': value['currency'],
        'currencySymbol': value['currencySymbol'],
    };
}

