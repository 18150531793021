/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFormatRequest,
  CreatePosterRequest,
  EditPosterRequest,
  PageSimplePosterResponse,
  Poster,
  PosterFilter,
  PosterResponse,
  RemoveFormatRequest,
} from '../models/index';
import {
    AddFormatRequestFromJSON,
    AddFormatRequestToJSON,
    CreatePosterRequestFromJSON,
    CreatePosterRequestToJSON,
    EditPosterRequestFromJSON,
    EditPosterRequestToJSON,
    PageSimplePosterResponseFromJSON,
    PageSimplePosterResponseToJSON,
    PosterFromJSON,
    PosterToJSON,
    PosterFilterFromJSON,
    PosterFilterToJSON,
    PosterResponseFromJSON,
    PosterResponseToJSON,
    RemoveFormatRequestFromJSON,
    RemoveFormatRequestToJSON,
} from '../models/index';

export interface AddFormatOperationRequest {
    addFormatRequest: AddFormatRequest;
}

export interface CreatePosterOperationRequest {
    createPosterRequest: CreatePosterRequest;
}

export interface DeletePosterByIdRequest {
    id: string;
}

export interface DeletePosterByNameRequest {
    name: string;
}

export interface EditPosterOperationRequest {
    editPosterRequest: EditPosterRequest;
}

export interface GetByIdRequest {
    id: string;
}

export interface GetByIdAdminRequest {
    id: string;
}

export interface GetByNameRequest {
    name: string;
}

export interface GetFilteredByRequest {
    posterFilter: PosterFilter;
    page?: number;
    size?: number;
    sort?: Array<string>;
    q?: string;
}

export interface RemoveFormatOperationRequest {
    removeFormatRequest: RemoveFormatRequest;
}

/**
 * 
 */
export class PosterControllerApi extends runtime.BaseAPI {

    /**
     */
    async addFormatRaw(requestParameters: AddFormatOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PosterResponse>> {
        if (requestParameters['addFormatRequest'] == null) {
            throw new runtime.RequiredError(
                'addFormatRequest',
                'Required parameter "addFormatRequest" was null or undefined when calling addFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/poster/format`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFormatRequestToJSON(requestParameters['addFormatRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async addFormat(requestParameters: AddFormatOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PosterResponse> {
        const response = await this.addFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPosterRaw(requestParameters: CreatePosterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Poster>> {
        if (requestParameters['createPosterRequest'] == null) {
            throw new runtime.RequiredError(
                'createPosterRequest',
                'Required parameter "createPosterRequest" was null or undefined when calling createPoster().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/poster`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePosterRequestToJSON(requestParameters['createPosterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterFromJSON(jsonValue));
    }

    /**
     */
    async createPoster(requestParameters: CreatePosterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Poster> {
        const response = await this.createPosterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePosterByIdRaw(requestParameters: DeletePosterByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePosterById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/poster/id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deletePosterById(requestParameters: DeletePosterByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deletePosterByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePosterByNameRaw(requestParameters: DeletePosterByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling deletePosterByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/poster/name/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deletePosterByName(requestParameters: DeletePosterByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deletePosterByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async editPosterRaw(requestParameters: EditPosterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Poster>> {
        if (requestParameters['editPosterRequest'] == null) {
            throw new runtime.RequiredError(
                'editPosterRequest',
                'Required parameter "editPosterRequest" was null or undefined when calling editPoster().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/poster`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditPosterRequestToJSON(requestParameters['editPosterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterFromJSON(jsonValue));
    }

    /**
     */
    async editPoster(requestParameters: EditPosterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Poster> {
        const response = await this.editPosterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PosterResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/poster/id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async getById(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PosterResponse> {
        const response = await this.getByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdAdminRaw(requestParameters: GetByIdAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Poster>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getByIdAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/poster/admin/id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterFromJSON(jsonValue));
    }

    /**
     */
    async getByIdAdmin(requestParameters: GetByIdAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Poster> {
        const response = await this.getByIdAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByNameRaw(requestParameters: GetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PosterResponse>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/poster/name/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async getByName(requestParameters: GetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PosterResponse> {
        const response = await this.getByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFilteredByRaw(requestParameters: GetFilteredByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSimplePosterResponse>> {
        if (requestParameters['posterFilter'] == null) {
            throw new runtime.RequiredError(
                'posterFilter',
                'Required parameter "posterFilter" was null or undefined when calling getFilteredBy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/poster/filtered`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PosterFilterToJSON(requestParameters['posterFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSimplePosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async getFilteredBy(requestParameters: GetFilteredByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSimplePosterResponse> {
        const response = await this.getFilteredByRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeFormatRaw(requestParameters: RemoveFormatOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PosterResponse>> {
        if (requestParameters['removeFormatRequest'] == null) {
            throw new runtime.RequiredError(
                'removeFormatRequest',
                'Required parameter "removeFormatRequest" was null or undefined when calling removeFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/poster/format`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveFormatRequestToJSON(requestParameters['removeFormatRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PosterResponseFromJSON(jsonValue));
    }

    /**
     */
    async removeFormat(requestParameters: RemoveFormatOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PosterResponse> {
        const response = await this.removeFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
