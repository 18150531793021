export const measurementId = 'G-3PXR89VZXF';
export const measurementIdDev = 'G-Z3KPPH16FE';

// Development Configuration  
export const devOptions = {
    testMode: false,
    gaOptions: {
        cookieDomain: "auto",
        anonymizeIp: true,
        send_page_view: false
    },
    gtagOptions: {}
};

// Production Configuration
export const prodOptions = {
    testMode: false,
    gaOptions: {
        cookieDomain: "auto",
        anonymizeIp: true
    },
    gtagOptions: {}
};
