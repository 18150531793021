/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountDTO
 */
export interface DiscountDTO {
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    discountId?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDTO
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    discountType?: DiscountDTODiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDTO
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    discountRule?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    applicableTo?: DiscountDTOApplicableToEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDTO
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDTO
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    modifiedBy?: string;
}


/**
 * @export
 */
export const DiscountDTODiscountTypeEnum = {
    Percentage: 'PERCENTAGE',
    FixedAmount: 'FIXED_AMOUNT',
    FreeShipping: 'FREE_SHIPPING',
    FreeCheapestProduct: 'FREE_CHEAPEST_PRODUCT'
} as const;
export type DiscountDTODiscountTypeEnum = typeof DiscountDTODiscountTypeEnum[keyof typeof DiscountDTODiscountTypeEnum];

/**
 * @export
 */
export const DiscountDTOApplicableToEnum = {
    PosterPrice: 'POSTER_PRICE',
    FramePrice: 'FRAME_PRICE',
    MaterialPrice: 'MATERIAL_PRICE',
    SizePrice: 'SIZE_PRICE',
    SubTotalPosterPrice: 'SUB_TOTAL_POSTER_PRICE',
    SubTotalCartPrice: 'SUB_TOTAL_CART_PRICE'
} as const;
export type DiscountDTOApplicableToEnum = typeof DiscountDTOApplicableToEnum[keyof typeof DiscountDTOApplicableToEnum];


/**
 * Check if a given object implements the DiscountDTO interface.
 */
export function instanceOfDiscountDTO(value: object): value is DiscountDTO {
    return true;
}

export function DiscountDTOFromJSON(json: any): DiscountDTO {
    return DiscountDTOFromJSONTyped(json, false);
}

export function DiscountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'order': json['order'] == null ? undefined : json['order'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'discountAmount': json['discountAmount'] == null ? undefined : json['discountAmount'],
        'discountRule': json['discountRule'] == null ? undefined : json['discountRule'],
        'applicableTo': json['applicableTo'] == null ? undefined : json['applicableTo'],
        'active': json['active'] == null ? undefined : json['active'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function DiscountDTOToJSON(value?: DiscountDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountId': value['discountId'],
        'order': value['order'],
        'discountType': value['discountType'],
        'discountAmount': value['discountAmount'],
        'discountRule': value['discountRule'],
        'applicableTo': value['applicableTo'],
        'active': value['active'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

