import React, { ReactNode, useContext, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { TbX, TbHandClick, TbFlame, TbTargetArrow } from 'react-icons/tb';
import { TiFlashOutline } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../provider/ModalProvider';
import { capitalize } from '../../service/Utils';
import './CollectionsFilter.scss';
import { SearchCollectionFilterOrderEnum, SimpleTagResponse, TagControllerApi } from '../../generated';
import { API_CONFIG, LISTING_CATEGORIES } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { CollectionContext } from '../../provider/CollectionProvider';

const switchValues = [
    {
        label: 'HOT',
        icon: <TbFlame size={22} className="pb-1" />,
        value: SearchCollectionFilterOrderEnum.Hot,
    },
    {
        label: 'NEW',
        icon: <TiFlashOutline size={22} className="pb-1" />,
        value: SearchCollectionFilterOrderEnum.New,
    },
    {
        label: 'RELEVANT',
        icon: <TbTargetArrow size={22} className="pb-1" />,
        value: SearchCollectionFilterOrderEnum.Relevant,
    },
];

// MultiSwitch Component for the sorting options
export function MultiSwitch() {
    const { t } = useTranslation();
    const { setOrder, order } = useContext(CollectionContext);
    const [selected, setSelected] = useState<SearchCollectionFilterOrderEnum>(switchValues.find((item) => item.value === order)!.value);

    const handleSwitchChange = (value: SearchCollectionFilterOrderEnum) => {
        setSelected(value);
        setOrder(value);
    };

    return (
        <Stack direction="horizontal" gap={1}>
            {switchValues.map((item, index) => (
                <div
                    key={index}
                    className={`switch-item ${selected === item.value ? 'switch-item-active' : ''}`}
                    onClick={() => handleSwitchChange(item.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSwitchChange(item.value);
                        }
                    }}
                >
                    {item.icon}
                    <span>{t(`COLLECTIONS_FILTER.${item.label.toLowerCase()}`)}</span>
                </div>
            ))}
        </Stack>
    );
}

const tagClient = new TagControllerApi(API_CONFIG);

const fetchCategoriesFallback = async (): Promise<SimpleTagResponse[] | undefined> => {
    return tagClient
        .getCategories()
        .then((response) => Array.from(response))
        .catch((error) => {
            console.error(error, error.message);
            return undefined;
        });
};

export default function CollectionsFilter() {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { setCategory, setSearchTerm, category, searchTerm } = useContext(CollectionContext); // Use CollectionContext to update filters
    const [searchValue, setSearchValue] = useState<string>(searchTerm); // Local state for search input

    // Fetch categories for filtering
    const [categories] = useStateSSRInit<SimpleTagResponse[] | undefined>(fetchCategoriesFallback, LISTING_CATEGORIES, []);

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setSearchTerm(searchValue); // Update search term only on Enter key press
        }
    };

    const modal: ReactNode = (
        <Row>
            {/* Add 'All' option to clear category */}
            <Col
                className={`mt-1 mb-1 pointer sublime-hover ${!category && 'category-active'}`}
                xs={6}
                sm={4}
                md={3}
                key="All"
                onClick={() => {
                    setCategory(undefined);
                    closeModal();
                }}
            >
                <span className={`${!category && 'category-active '} px-2 py-1`}>{t('COLLECTIONS_FILTER.all')}</span>
            </Col>
            {/* Render other categories */}
            {categories?.map((c) => (
                <Col
                    className={`mt-1 mb-1 pointer sublime-hover `}
                    xs={6}
                    sm={4}
                    md={3}
                    key={c.defaultName}
                    onClick={() => {
                        setCategory(c.defaultName === 'All' ? undefined : c.defaultName); // Set category filter
                        closeModal();
                    }}
                >
                    <span className={`${c === category && 'category-active'} px-2 py-1`}>{capitalize(c.translatedName ?? '')}</span>
                </Col>
            ))}
        </Row>
    );

    const modalHeader: ReactNode = (
        <Stack direction="horizontal" className="w-100" gap={2}>
            <h3 className="mb-0">{t('COLLECTIONS_FILTER.chooseCategory')}</h3>
            <div className="ms-auto rounded-circle border border-1 p-1 border-dark pointer" onClick={closeModal} onKeyDown={(e) => closeModal()}>
                <TbX
                    style={{
                        width: '25px',
                        height: '25px',
                    }}
                />
            </div>
        </Stack>
    );

    return (
        <Stack direction="horizontal" className="mt-5 mb-4 filters" gap={4}>
            {/* Search input to filter collections */}
            <Form.Group className="w-100">
                <Form.Label>{t('COLLECTIONS_FILTER.search')}</Form.Label>
                <Form.Control
                    className="border border-gray"
                    type="text"
                    placeholder={t('COLLECTIONS_FILTER.searchPlaceholder')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)} // Update local state on input change
                    onKeyDown={handleSearchKeyDown} // Trigger search on Enter key press
                />
            </Form.Group>
            {/* Category selection */}
            <Form.Group className="category">
                <Form.Label>{t('COLLECTIONS_FILTER.category')}</Form.Label>
                <Stack direction="horizontal" gap={2}>
                    <div
                        className="rounded p-1 ps-2 w-100 category-display"
                        onClick={() =>
                            showModal(modal, '', modalHeader, {
                                centered: true,
                                size: 'lg',
                            })
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                showModal(modal, '', modalHeader, {
                                    centered: true,
                                    size: 'lg',
                                });
                            }
                        }}
                    >
                        {capitalize(category ?? t('COLLECTIONS_FILTER.all'))}
                    </div>
                    <Button
                        variant="outline-secondary"
                        onClick={() =>
                            showModal(modal, '', modalHeader, {
                                centered: true,
                                size: 'lg',
                            })
                        }
                    >
                        <TbHandClick />
                    </Button>
                </Stack>
            </Form.Group>
            {/* Sorting options */}
            <Form.Group>
                <Form.Label>{t('COLLECTIONS_FILTER.sort')}</Form.Label>
                <MultiSwitch />
            </Form.Group>
        </Stack>
    );
}
