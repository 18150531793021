import { SimplePosterResponseAspectRatioEnum } from '../generated';

export function capitalize(str?: string): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function isJson(str?: string | null | undefined): boolean {
    try {
        if (!str) return false;
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const getAspectRatioValue = (aspectRatio?: SimplePosterResponseAspectRatioEnum) => {
    if (!aspectRatio) {
        return 1; // Default ratio if the key is not provided
    }

    // Extract numbers from the aspect ratio key (e.g., 'R_2_3' -> ['2', '3'])
    const parts = aspectRatio.split('_').slice(1).map(Number);

    // Calculate and return the aspect ratio, default to 1 if invalid
    return parts.length === 2 && parts[1] !== 0 ? parts[0] / parts[1] : 1;
};

export const debounce = (func: Function, delay: number) => {
    let inDebounce: any;
    return function () {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func(), delay);
    };
};
