import React, { ReactNode, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

type ModalContextType = {
    showModal: (body: ReactNode, footer?: ReactNode, header?: ReactNode, modalProps?: ModalProps) => void;
    closeModal: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({
    showModal: () => {},
    closeModal: () => {},
});

type Props = {
    children: ReactNode;
};

export function ModalProvider({ children }: Props) {
    const [show, setShow] = useState(false);
    const [body, setBody] = useState<ReactNode>('');
    const [footer, setFooter] = useState<ReactNode>('');
    const [header, setHeader] = useState<ReactNode>();
    const [modalProps, setModalProps] = useState<ModalProps>();

    const showModal = (body: ReactNode, footer?: ReactNode, header?: ReactNode, modalProps?: ModalProps) => {
        setShow(true);
        setBody(body);
        setFooter(footer);
        setHeader(header);
        setModalProps(modalProps);
    };

    const closeModal = () => {
        setShow(false);
    };

    const contextValue = useMemo(() => ({ showModal, closeModal }), []);

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            <Modal show={show} {...modalProps} onHide={closeModal}>
                {header && <Modal.Header>{header}</Modal.Header>}
                <Modal.Body>{body}</Modal.Body>
                {footer && <Modal.Footer>{footer}</Modal.Footer>}
            </Modal>
        </ModalContext.Provider>
    );
}
