import React, { useState, useEffect } from 'react';
import { Placeholder, Image, ImageProps } from 'react-bootstrap';
import SSRContext from '../../service/SSRContext';

type ImageWithPlaceholderProps = ImageProps & {
    aspectRatio?: number; // Optional aspect ratio (default to 2/3)
    parentSelector?: string; // CSS selector for the parent element
    placeholderWidth?: number; // Placeholder width
    placeholderHeight?: number; // Placeholder height
    defineImageSize?: boolean; // Define image size based on parent element
    placeholderClassName?: string; // Placeholder class name
    placeholderStyle?: React.CSSProperties; // Placeholder style
    responsive?: boolean; // Resize image based on parent element
};

export function ImageWithPlaceHolder({
    aspectRatio = 2 / 3, // Default aspect ratio 2:3
    parentSelector = undefined,
    placeholderWidth = 0,
    placeholderHeight = 0, // CSS selector to find the parent
    defineImageSize = false,
    className,
    style,
    placeholderClassName,
    placeholderStyle,
    responsive = true,
    ...props
}: ImageWithPlaceholderProps & React.RefAttributes<HTMLImageElement>) {
    const [imageLoading, setImageLoading] = useState(SSRContext.isInClient());
    const [imageSize, setImageSize] = useState({ width: placeholderWidth, height: placeholderHeight });

    useEffect(() => {
        const calculateSize = () => {
            if (parentSelector) {
                const parentElement = document.querySelector(parentSelector);
                if (parentElement) {
                    const parentWidth = parentElement.clientWidth;
                    const parentHeight = parentElement.clientHeight;

                    // Calculate based on width-first by default
                    let calculatedWidth = parentWidth;
                    let calculatedHeight = parentWidth / aspectRatio;

                    // If calculated height is bigger than the parent height, adjust by height instead
                    if (calculatedHeight > parentHeight) {
                        calculatedHeight = parentHeight;
                        calculatedWidth = parentHeight * aspectRatio;
                    }

                    setImageSize({
                        width: Math.min(calculatedWidth, parentWidth),
                        height: Math.min(calculatedHeight, parentHeight),
                    });
                }
            }
        };

        // Calculate size on mount and when the window resizes
        calculateSize();
        if (responsive) window.addEventListener('resize', calculateSize);

        return () => {
            if (responsive) window.removeEventListener('resize', calculateSize);
        };
    }, [aspectRatio, parentSelector]);

    const handleImageLoaded = () => {
        setImageLoading(false); // Hide placeholder when image is fully loaded
    };

    const handleImageError = () => {
        setImageLoading(true); // Keep showing the placeholder if the image fails to load
    };

    return (
        <>
            {/* Placeholder remains visible until image is fully loaded */}
            {imageLoading && (
                <Placeholder animation="glow" className={`product-background-image custom-rounded ${placeholderClassName}`}>
                    <Placeholder
                        className={placeholderClassName}
                        style={{ width: `${imageSize.width}px`, height: `${imageSize.height}px`, ...placeholderStyle }}
                        bg="secondary"
                    />
                </Placeholder>
            )}
            {/* Image is rendered but hidden until it is loaded */}
            <Image
                {...(props as ImageProps)}
                onLoad={handleImageLoaded}
                onError={handleImageError} // Handle image loading failure
                className={`${imageLoading ? 'd-none' : 'd-block'} ${className}`}
                style={defineImageSize && imageSize.width && imageSize.height ? { width: imageSize.width, height: imageSize.height, ...style } : { ...style }}
            />
        </>
    );
}
