import React from 'react';
import { Col, Container, Row, Stack, Button, Form as BSForm } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TbBell, TbBrandFacebook, TbBrandInstagram, TbBrandPinterest, TbBrandTwitterFilled } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { NewsletterControllerApi } from '../../generated/apis/NewsletterControllerApi';
import { API_CONFIG, DEFAULT_NEWSLETTER_NAME } from '../../resources/constants';

type Props = {
    readonly newsletter?: boolean;
};

const newsletterClient = new NewsletterControllerApi(API_CONFIG);

export default function Footer({ newsletter = true }: Props) {
    const { t } = useTranslation();

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(t('FORM_VALIDATION.InvalidEmailAddress')).required(t('FORM_VALIDATION.Required')),
    });

    const subscribeToNewsletter = async (values: any, formikHelpers: FormikHelpers<any>) => {
        await newsletterClient
            .subscribeToNewsletter({
                newsletterName: DEFAULT_NEWSLETTER_NAME,
                subscribeToNewsletterRequest: { email: values.email },
            })
            .catch((error) => {
                console.error(error, error.message);
                formikHelpers.setFieldError('email', error.message);
            });
    };

    return (
        <footer className="footer pt-4 pb-1">
            <Container>
                <Stack direction="horizontal" gap={3} className="social-container">
                    <TbBrandFacebook size={25} />
                    <TbBrandInstagram size={25} />
                    <TbBrandTwitterFilled size={25} />
                    <TbBrandPinterest size={25} />
                </Stack>

                <div className="p-2 w-100 my-4">
                    <p className="fs-5 text-center m-0">{t('FOOTER.description')}</p>
                </div>

                {newsletter && (
                    <div>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={subscribeToNewsletter}>
                            {({ isSubmitting, errors, touched }) => (
                                <Form>
                                    <div className="footer-newsletter-form">
                                        <Stack>
                                            <p className="mb-2">{t('FOOTER.newsletterTitle')}</p>
                                            <Stack direction="horizontal" gap={3}>
                                                <div className="w-100">
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        placeholder={t('FOOTER.emailPlaceholder')}
                                                        as={BSForm.Control}
                                                        className={`form-control mb-2 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                    />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback fs-7" />
                                                </div>
                                                <div>
                                                    <Button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        className={`no-break ps-2 ${errors.email && touched.email ? 'mb-4' : 'mb-2'}`}
                                                    >
                                                        <TbBell size={22} className="pb-1" /> {t('FOOTER.subscribeButton')}
                                                    </Button>
                                                </div>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
                <Row className="pt-4 mb-4">
                    <Col className="d-flex justify-content-center">
                        <div>
                            <Stack gap={2}>
                                <p className="fw-bold mb-1">{t('FOOTER.quickLinks')}</p>
                                <p className="mb-0">{t('FOOTER.shop')}</p>
                                <p className="mb-0">{t('FOOTER.collections')}</p>
                                <p className="mb-0">{t('FOOTER.paymentOptions')}</p>
                            </Stack>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <div>
                            <Stack gap={2}>
                                <p className="fw-bold mb-1">{t('FOOTER.explore')}</p>
                                <p className="mb-0">{t('FOOTER.aboutUs')}</p>
                                <p className="mb-0">{t('FOOTER.contactUs')}</p>
                                <p className="mb-0">{t('FOOTER.privacyPolicy')}</p>
                            </Stack>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <div>
                            <Stack gap={2}>
                                <p className="fw-bold mb-1">{t('FOOTER.support')}</p>
                                <p className="mb-0">{t('FOOTER.faq')}</p>
                                <p className="mb-0">{t('FOOTER.shippingReturns')}</p>
                            </Stack>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div>
                    <p className="text-center muted m-0">{t('FOOTER.copyright', { year: new Date().getFullYear() })}</p>
                </div>
            </Container>
        </footer>
    );
}
