/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CartDTO,
  CouponResponse,
  CreateCouponRequest,
  EditCouponRequest,
  PageCouponResponse,
} from '../models/index';
import {
    CartDTOFromJSON,
    CartDTOToJSON,
    CouponResponseFromJSON,
    CouponResponseToJSON,
    CreateCouponRequestFromJSON,
    CreateCouponRequestToJSON,
    EditCouponRequestFromJSON,
    EditCouponRequestToJSON,
    PageCouponResponseFromJSON,
    PageCouponResponseToJSON,
} from '../models/index';

export interface ActivateCouponRequest {
    body: string;
}

export interface ApplyCouponRequest {
    couponCode: string;
}

export interface CreateCouponOperationRequest {
    createCouponRequest: CreateCouponRequest;
}

export interface DeactivateCouponRequest {
    body: string;
}

export interface EditCouponOperationRequest {
    editCouponRequest: EditCouponRequest;
}

export interface GetAllCouponsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface GetCouponByIdRequest {
    couponId: string;
}

/**
 * 
 */
export class CouponControllerApi extends runtime.BaseAPI {

    /**
     */
    async activateCouponRaw(requestParameters: ActivateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling activateCoupon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/coupon/activate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateCoupon(requestParameters: ActivateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateCouponRaw(requestParameters, initOverrides);
    }

    /**
     */
    async applyCouponRaw(requestParameters: ApplyCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartDTO>> {
        if (requestParameters['couponCode'] == null) {
            throw new runtime.RequiredError(
                'couponCode',
                'Required parameter "couponCode" was null or undefined when calling applyCoupon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/coupon/apply/{couponCode}`.replace(`{${"couponCode"}}`, encodeURIComponent(String(requestParameters['couponCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartDTOFromJSON(jsonValue));
    }

    /**
     */
    async applyCoupon(requestParameters: ApplyCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartDTO> {
        const response = await this.applyCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCouponRaw(requestParameters: CreateCouponOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CouponResponse>> {
        if (requestParameters['createCouponRequest'] == null) {
            throw new runtime.RequiredError(
                'createCouponRequest',
                'Required parameter "createCouponRequest" was null or undefined when calling createCoupon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/coupon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCouponRequestToJSON(requestParameters['createCouponRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async createCoupon(requestParameters: CreateCouponOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CouponResponse> {
        const response = await this.createCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deactivateCouponRaw(requestParameters: DeactivateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling deactivateCoupon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/coupon/deactivate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deactivateCoupon(requestParameters: DeactivateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateCouponRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editCouponRaw(requestParameters: EditCouponOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CouponResponse>> {
        if (requestParameters['editCouponRequest'] == null) {
            throw new runtime.RequiredError(
                'editCouponRequest',
                'Required parameter "editCouponRequest" was null or undefined when calling editCoupon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/coupon`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditCouponRequestToJSON(requestParameters['editCouponRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async editCoupon(requestParameters: EditCouponOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CouponResponse> {
        const response = await this.editCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCouponsRaw(requestParameters: GetAllCouponsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCouponResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/coupon/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAllCoupons(requestParameters: GetAllCouponsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCouponResponse> {
        const response = await this.getAllCouponsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCouponByIdRaw(requestParameters: GetCouponByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CouponResponse>> {
        if (requestParameters['couponId'] == null) {
            throw new runtime.RequiredError(
                'couponId',
                'Required parameter "couponId" was null or undefined when calling getCouponById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/coupon/id/{couponId}`.replace(`{${"couponId"}}`, encodeURIComponent(String(requestParameters['couponId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCouponById(requestParameters: GetCouponByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CouponResponse> {
        const response = await this.getCouponByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
