/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditPosterRequest
 */
export interface EditPosterRequest {
    /**
     * 
     * @type {string}
     * @memberof EditPosterRequest
     */
    posterId: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EditPosterRequest
     */
    smallDescription?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EditPosterRequest
     */
    description?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EditPosterRequest
     */
    altText?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof EditPosterRequest
     */
    imageLink?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EditPosterRequest
     */
    name?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof EditPosterRequest
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof EditPosterRequest
     */
    color?: EditPosterRequestColorEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPosterRequest
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditPosterRequest
     */
    isHot?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EditPosterRequest
     */
    price?: number;
    /**
     * 
     * @type {Set<string>}
     * @memberof EditPosterRequest
     */
    tags?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof EditPosterRequest
     */
    sizes?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof EditPosterRequest
     */
    materials?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof EditPosterRequest
     */
    frames?: Set<string>;
}


/**
 * @export
 */
export const EditPosterRequestColorEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type EditPosterRequestColorEnum = typeof EditPosterRequestColorEnum[keyof typeof EditPosterRequestColorEnum];


/**
 * Check if a given object implements the EditPosterRequest interface.
 */
export function instanceOfEditPosterRequest(value: object): value is EditPosterRequest {
    if (!('posterId' in value) || value['posterId'] === undefined) return false;
    return true;
}

export function EditPosterRequestFromJSON(json: any): EditPosterRequest {
    return EditPosterRequestFromJSONTyped(json, false);
}

export function EditPosterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditPosterRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'],
        'smallDescription': json['smallDescription'] == null ? undefined : json['smallDescription'],
        'description': json['description'] == null ? undefined : json['description'],
        'altText': json['altText'] == null ? undefined : json['altText'],
        'imageLink': json['imageLink'] == null ? undefined : json['imageLink'],
        'name': json['name'] == null ? undefined : json['name'],
        'score': json['score'] == null ? undefined : json['score'],
        'color': json['color'] == null ? undefined : json['color'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'isHot': json['isHot'] == null ? undefined : json['isHot'],
        'price': json['price'] == null ? undefined : json['price'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'sizes': json['sizes'] == null ? undefined : json['sizes'],
        'materials': json['materials'] == null ? undefined : json['materials'],
        'frames': json['frames'] == null ? undefined : json['frames'],
    };
}

export function EditPosterRequestToJSON(value?: EditPosterRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'smallDescription': value['smallDescription'],
        'description': value['description'],
        'altText': value['altText'],
        'imageLink': value['imageLink'],
        'name': value['name'],
        'score': value['score'],
        'color': value['color'],
        'currency': value['currency'],
        'isHot': value['isHot'],
        'price': value['price'],
        'tags': value['tags'] == null ? undefined : Array.from(value['tags'] as Set<any>),
        'sizes': value['sizes'] == null ? undefined : Array.from(value['sizes'] as Set<any>),
        'materials': value['materials'] == null ? undefined : Array.from(value['materials'] as Set<any>),
        'frames': value['frames'] == null ? undefined : Array.from(value['frames'] as Set<any>),
    };
}

