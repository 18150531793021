import React from 'react';
import SSRContext from '../../service/SSRContext';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
    SSRContext.setStatusCode(404);
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Page not found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <Link to="/">Go To Homepage</Link>
            </div>
        </div>
    );
}
