import React from 'react';
import SSRContext from '../service/SSRContext';
import { JAVA_EXCEPTION } from '../resources/constants';
import { Container } from 'react-bootstrap';

export function ErrorPage() {
    const exception: any = SSRContext.get(JAVA_EXCEPTION);

    return (
        <Container>
            <h1>On no, there was a problem</h1>
            <p>{exception?.severity}</p>
            <p>{exception?.status}</p>
            <p>{exception?.id}</p>
            <p>{exception?.developerMessage}</p>
            <p>{exception?.userMessage}</p>
        </Container>
    );
}
