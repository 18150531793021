import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner from '../../resources/image/home/banner.webp';
import banner2 from '../../resources/image/home/banner2.png';
import { Button } from 'react-bootstrap';
import './HomeBanner.scss';
import { Link } from 'react-router-dom';

export default function HomeBanner() {
    return (
        <Carousel className="banner" interval={10_000}>
            <Carousel.Item>
                <div className="banner-img" style={{ backgroundImage: `url(${banner})` }}></div>
                <Carousel.Caption>
                    <h1 className="display-4 fs-3">Don&apos;t miss your chance</h1>
                    <Link to="/listing">
                        <Button size="lg">Shop Now</Button>
                    </Link>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="banner-img" style={{ backgroundImage: `url(${banner2})` }}></div>
                <Carousel.Caption>
                    <Link to="/listing">
                        <Button size="lg">Shop Now</Button>
                    </Link>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}
