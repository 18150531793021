/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePosterResponse } from './SimplePosterResponse';
import {
    SimplePosterResponseFromJSON,
    SimplePosterResponseFromJSONTyped,
    SimplePosterResponseToJSON,
} from './SimplePosterResponse';

/**
 * 
 * @export
 * @interface WishlistRequest
 */
export interface WishlistRequest {
    /**
     * 
     * @type {string}
     * @memberof WishlistRequest
     */
    wishlistId?: string;
    /**
     * 
     * @type {Array<SimplePosterResponse>}
     * @memberof WishlistRequest
     */
    items?: Array<SimplePosterResponse>;
    /**
     * 
     * @type {string}
     * @memberof WishlistRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WishlistRequest
     */
    description?: string;
}

/**
 * Check if a given object implements the WishlistRequest interface.
 */
export function instanceOfWishlistRequest(value: object): value is WishlistRequest {
    return true;
}

export function WishlistRequestFromJSON(json: any): WishlistRequest {
    return WishlistRequestFromJSONTyped(json, false);
}

export function WishlistRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WishlistRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'wishlistId': json['wishlistId'] == null ? undefined : json['wishlistId'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(SimplePosterResponseFromJSON)),
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function WishlistRequestToJSON(value?: WishlistRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wishlistId': value['wishlistId'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(SimplePosterResponseToJSON)),
        'name': value['name'],
        'description': value['description'],
    };
}

