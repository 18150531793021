/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCollectionRequest
 */
export interface CreateCollectionRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateCollectionRequest
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateCollectionRequest
     */
    description: { [key: string]: string; };
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateCollectionRequest
     */
    tags: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateCollectionRequest
     */
    posterIds: Set<string>;
}

/**
 * Check if a given object implements the CreateCollectionRequest interface.
 */
export function instanceOfCreateCollectionRequest(value: object): value is CreateCollectionRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('posterIds' in value) || value['posterIds'] === undefined) return false;
    return true;
}

export function CreateCollectionRequestFromJSON(json: any): CreateCollectionRequest {
    return CreateCollectionRequestFromJSONTyped(json, false);
}

export function CreateCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'tags': json['tags'],
        'posterIds': json['posterIds'],
    };
}

export function CreateCollectionRequestToJSON(value?: CreateCollectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'tags': Array.from(value['tags'] as Set<any>),
        'posterIds': Array.from(value['posterIds'] as Set<any>),
    };
}

