import React, { useCallback, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import CollectionHeader from '../../components/Collection/CollectionHeader';
import CollectionListing from '../../components/Collection/CollectionListing';
import './CollectionPage.scss';
import CollectionShelf from '../../components/Shelf/CollectionsShelf';
import { useParams } from 'react-router-dom';
import { CollectionControllerApi, CollectionResponse, GetCollectionShelfShelfEnum, GetShelfShelfEnum } from '../../generated';
import { API_CONFIG, COLLECTION_POSTER } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import CollectionDescription from '../../components/Collection/CollectionDescription';
import SSRContext from '../../service/SSRContext';
import { useTranslation } from 'react-i18next';
import NotFound from '../../components/Product/NotFound';

const client: CollectionControllerApi = new CollectionControllerApi(API_CONFIG);

export default function CollectionPage() {
    const { collectionName } = useParams();
    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.COLLECTIONS_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.COLLECTIONS_placeholders', { returnObjects: true }) as string[], [t]);

    const fetchCollection = useCallback(async (): Promise<CollectionResponse | undefined> => {
        if (!collectionName) return undefined;
        try {
            return await client.getCollectionByName({ collectionName: collectionName });
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }, [collectionName]);

    const [collection, setCollection] = useStateSSRInit<CollectionResponse | undefined>(fetchCollection, COLLECTION_POSTER);

    useEffect(() => {
        if (collection && collection.defaultName !== collectionName) {
            fetchCollection()
                .then((collection) => setCollection(collection))
                .catch((error) => console.error(error));
        }
    }, [collection, collectionName, fetchCollection, setCollection]);

    if (SSRContext.isInServer() && !collection) SSRContext.setStatusCode(404);

    return (
        <>
            {collection ? (
                <>
                    <CollectionHeader collection={collection} />
                    <Container className="collection-header-margin">
                        <CollectionDescription collection={collection} />
                        <CollectionListing collection={collection} className="mb-5" />
                        <CollectionShelf className="mt-5 pt-4" shelfEnum={GetCollectionShelfShelfEnum.YouAlsoMightLike} />
                    </Container>
                </>
            ) : (
                <NotFound
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                    title={t('NOT_FOUND.COLLECTIONS_title')}
                    description={descriptions}
                    placeholder={placeholders}
                />
            )}
        </>
    );
}
