import React, { useCallback, useMemo } from 'react';

type MenuContextType = {
    update: () => void;
    addCallback: (callback: () => void) => void;
    removeCallback: (callback: () => void) => void;
};

export const MenuContext = React.createContext<MenuContextType>({
    update: () => {},
    addCallback: () => {},
    removeCallback: () => {},
});

type Props = {
    readonly children: React.ReactNode;
};

export default function MenuProvider({ children }: Props) {
    const [callbacks, setCallbacks] = React.useState<(() => void)[]>([]);

    const update = useCallback(() => {
        callbacks.forEach((callback) => callback());
    }, [callbacks]);

    const addCallback = useCallback(
        (callback: () => void) => {
            setCallbacks((callbacks) => [...callbacks, callback]);
        },
        [setCallbacks]
    );

    const removeCallback = useCallback(
        (callback: () => void) => {
            setCallbacks((callbacks) => callbacks.filter((cb) => cb !== callback));
        },
        [setCallbacks]
    );

    const contextValue = useMemo(() => ({ update, addCallback, removeCallback }), [update, addCallback, removeCallback]);

    return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
}
