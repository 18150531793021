/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CheckoutSessionCompletedRequest {
    stripeSignature: string;
    body: string;
}

export interface CreateCheckoutSessionRequest {
    couponCode?: string;
}

/**
 * 
 */
export class PaymentControllerApi extends runtime.BaseAPI {

    /**
     */
    async checkoutSessionCompletedRaw(requestParameters: CheckoutSessionCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['stripeSignature'] == null) {
            throw new runtime.RequiredError(
                'stripeSignature',
                'Required parameter "stripeSignature" was null or undefined when calling checkoutSessionCompleted().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling checkoutSessionCompleted().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['stripeSignature'] != null) {
            headerParameters['Stripe-Signature'] = String(requestParameters['stripeSignature']);
        }

        const response = await this.request({
            path: `/api/payment/checkout-hook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkoutSessionCompleted(requestParameters: CheckoutSessionCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkoutSessionCompletedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createCheckoutSessionRaw(requestParameters: CreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/payment/create-checkout-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createCheckoutSession(requestParameters: CreateCheckoutSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
