/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrameResponse
 */
export interface FrameResponse {
    /**
     * 
     * @type {string}
     * @memberof FrameResponse
     */
    frameId?: string;
    /**
     * 
     * @type {string}
     * @memberof FrameResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FrameResponse
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof FrameResponse
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof FrameResponse
     */
    currency?: string;
}

/**
 * Check if a given object implements the FrameResponse interface.
 */
export function instanceOfFrameResponse(value: object): value is FrameResponse {
    return true;
}

export function FrameResponseFromJSON(json: any): FrameResponse {
    return FrameResponseFromJSONTyped(json, false);
}

export function FrameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'frameId': json['frameId'] == null ? undefined : json['frameId'],
        'name': json['name'] == null ? undefined : json['name'],
        'image': json['image'] == null ? undefined : json['image'],
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function FrameResponseToJSON(value?: FrameResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frameId': value['frameId'],
        'name': value['name'],
        'image': value['image'],
        'price': value['price'],
        'currency': value['currency'],
    };
}

