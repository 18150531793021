import React, { useState, useRef, useEffect, ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';
import { Button, Col, Fade, Nav, Row, Stack } from 'react-bootstrap';
import './TopMenu.scss';
import {
    TbBallFootball,
    TbBrush,
    TbCat,
    TbDeviceGamepad2,
    TbDeviceTv,
    TbFrame,
    TbGift,
    TbHome,
    TbMap,
    TbMoustache,
    TbPiano,
    TbPizza,
    TbSnowman,
} from 'react-icons/tb';
import { TiFlashOutline } from 'react-icons/ti';
import { animated, useSpring } from 'react-spring';
import { Link } from 'react-router-dom';

type Props = {
    readonly content: {
        label: string;
        link: string;
        content: ReactNode;
        width: number;
    }[];
};

export default function HoverDropdown({ content }: Props) {
    const [popupVisibleNumber, setPopupVisibleNumber] = useState<number | null>(null);
    const [prevPopupVisible, setPrevPopupVisible] = useState<number | null>(null);
    const [arrowLeft, setArrowLeft] = useState<number | null>(null);
    const [popupLeft, setPopupLeft] = useState<number | null>(null);

    const hoverRefs = useRef<(HTMLDivElement | null)[]>([]);
    const hidePopupTimeout = useRef<NodeJS.Timeout | null>(null);

    const setPopupVisible = (index: number | null) => {
        setPrevPopupVisible(popupVisibleNumber);
        setPopupVisibleNumber(index);
    };

    const slideProps = useSpring({
        from: {
            transform: `translateX(${(prevPopupVisible ?? 0) - (popupVisibleNumber ?? 0) > 0 ? '' : '-'}10%)`,
            opacity: 0,
        },
        to: {
            transform: 'translateX(0)',
            opacity: 1,
        },
        reset: true,
        reverse: popupVisibleNumber === null,
        config: {
            duration: 300,
            easing: (t) => t,
        },
    });

    const [widthProps, setWidth] = useSpring(() => ({
        width: '800px',
        config: {
            duration: 300,
            easing: (t) => t,
        },
    }));

    const handleMouseEnter = (index: number) => {
        if (hidePopupTimeout.current) {
            clearTimeout(hidePopupTimeout.current);
            hidePopupTimeout.current = null;
        }
        if (popupVisibleNumber != index) {
            setPopupVisible(index);
        }
        const hoveredElement = hoverRefs.current[index];
        if (hoveredElement) {
            const rect = hoveredElement.getBoundingClientRect();
            const popupWidth = content[index].width;
            setWidth({ width: `${popupWidth}px` });
            let left = rect.left + rect.width / 2 - popupWidth / 2;
            // Ensure the popup is within the viewport
            left = Math.max(10, Math.min(window.innerWidth - popupWidth - 10, left));
            setPopupLeft(left);

            // Calculate the left position for the arrow
            const arrowLeft = rect.left + rect.width / 2 - left;
            setArrowLeft(arrowLeft);
        }
    };

    const handleMouseLeave = () => {
        hidePopupTimeout.current = setTimeout(() => {
            setPopupVisible(null);
            setPopupLeft(null);
        }, 150);
    };

    const handlePopupMouseEnter = () => {
        if (hidePopupTimeout.current) {
            clearTimeout(hidePopupTimeout.current);
            hidePopupTimeout.current = null;
        }
    };

    const handlePopupMouseLeave = () => {
        hidePopupTimeout.current = setTimeout(() => {
            setPopupVisible(null);
            setPopupLeft(null);
        }, 200);
    };

    useEffect(() => {
        return () => {
            if (hidePopupTimeout.current) {
                clearTimeout(hidePopupTimeout.current);
            }
        };
    }, []);

    return (
        <>
            {content.map((item, index) => (
                <div
                    key={item.label}
                    className="hover-dropdown py-1"
                    ref={(el) => (hoverRefs.current[index] = el)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <Nav.Link as={Link} to={item.link}>
                        {item.label}
                    </Nav.Link>
                </div>
            ))}
            <animated.div
                className={`popup shadow-lg p-3 ${popupVisibleNumber !== null ? 'popup-visible animated' : ''} ${
                    prevPopupVisible != null ? 'left-animated ' : ''
                } `}
                onMouseEnter={handlePopupMouseEnter}
                onMouseLeave={handlePopupMouseLeave}
                style={{
                    ...widthProps,
                    left: popupLeft ?? 0,
                }}
            >
                <div className="arrow" style={{ left: arrowLeft ?? 'initial' }} />
                <div className="popup-body m-3">
                    {content.map((item, index) => (
                        <animated.div key={item.label} style={slideProps}>
                            {popupVisibleNumber === index ? item.content : null}
                        </animated.div>
                    ))}
                </div>
            </animated.div>
        </>
    );
}

export function Content1(htmlProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const size = 20;
    return (
        <div {...htmlProps}>
            <Row>
                <Col>
                    <MenuElement
                        link="/listing"
                        text="Best Sellers"
                        description="Our most popular posters, all in one place."
                        icon={<TiFlashOutline size={size} />}
                    />
                    <MenuElement
                        link="/listing"
                        text="Home & Living"
                        description="Transform your space with our eclectic range of posters."
                        icon={<TbHome size={size} />}
                    />
                    <MenuElement
                        link="/listing"
                        text="Sports Sphere"
                        description="From football fandom to athletic feats, find a poster that champions your favorite sports."
                        icon={<TbBallFootball size={size} />}
                    />
                    <MenuElement link="/listing" text="Vintage Vaults" description="Retro art that transcends time." icon={<TbMoustache size={size} />} />
                    <MenuElement
                        link="/listing"
                        text="Anime Arena"
                        description="Immerse in the vibrancy of anime-themed artwork."
                        icon={<TbSnowman size={size} />}
                    />
                    <MenuElement
                        link="/listing"
                        text="Gaming Gallery"
                        description="Level up your space with gaming-themed artwork."
                        icon={<TbDeviceGamepad2 size={size} />}
                    />
                    <MenuElement link="/listing" text="Music Mania" description="Find your rhythm with music-themed artwork." icon={<TbPiano size={size} />} />
                </Col>
                <Col>
                    <MenuElement
                        link="/listing"
                        text="Gift Ideas"
                        description="Discover the perfect poster gifts for every occasion and recipient."
                        icon={<TbGift size={size} />}
                    />
                    <MenuElement
                        link="/listing"
                        text="TV Time"
                        description="Binge-watch your favorite shows with TV-themed artwork."
                        icon={<TbDeviceTv size={size} />}
                    />
                    <MenuElement link="/listing" text="Travel Tales" description="Explore the world with travel-themed artwork." icon={<TbMap size={size} />} />
                    <MenuElement
                        link="/listing"
                        text="Animal Kingdom"
                        description="Find your spirit animal with animal-themed artwork."
                        icon={<TbCat size={size} />}
                    />
                    <MenuElement
                        link="/listing"
                        text="Food Fiesta"
                        description="Satisfy your cravings with food-themed artwork."
                        icon={<TbPizza size={size} />}
                    />
                    <MenuElement link="/listing" text="Abstract Artistry" description="Explore the depths of abstract art." icon={<TbFrame size={size} />} />
                    <MenuElement link="/listing" text="Minimalist Moods" description="Find your zen with minimalist artwork." icon={<TbBrush size={size} />} />
                </Col>
            </Row>
        </div>
    );
}

export function Content2(htmlProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const size = 20;
    return (
        <div {...htmlProps}>
            <Row>
                <Col>
                    <h1>Etiam iaculis nunc</h1>
                    <p>
                        Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Nullam tincidunt adipiscing enim. Donec interdum, metus et hendrerit aliquet,
                        dolor diam sagittis ligula, eget egestas libero turpis vel mi. Sed aliquam ultrices mauris. Phasellus gravida semper nisi. Nam pretium
                        turpis et arcu.
                    </p>
                </Col>
                <Col>
                    <MenuElement
                        link="/collections"
                        text="Gift Ideas"
                        description="Discover the perfect poster gifts for every occasion and recipient."
                        icon={<TbGift size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="TV Time"
                        description="Binge-watch your favorite shows with TV-themed artwork."
                        icon={<TbDeviceTv size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="Travel Tales"
                        description="Explore the world with travel-themed artwork."
                        icon={<TbMap size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="Animal Kingdom"
                        description="Find your spirit animal with animal-themed artwork."
                        icon={<TbCat size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="Food Fiesta"
                        description="Satisfy your cravings with food-themed artwork."
                        icon={<TbPizza size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="Abstract Artistry"
                        description="Explore the depths of abstract art."
                        icon={<TbFrame size={size} />}
                    />
                    <MenuElement
                        link="/collections"
                        text="Minimalist Moods"
                        description="Find your zen with minimalist artwork."
                        icon={<TbBrush size={size} />}
                    />
                </Col>
            </Row>
        </div>
    );
}

export function Content3() {
    const [open, setOpen] = useState(true);

    return (
        <>
            <Button onClick={() => setOpen(!open)} aria-controls="example-fade-text" aria-expanded={open}>
                Toggle text
            </Button>
            <Fade in={open} appear>
                <div id="example-fade-text">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
                    labore wes anderson cred nesciunt sapiente ea proident.
                </div>
            </Fade>
        </>
    );
}

type ElementProps = {
    readonly link: string;
    readonly text: string;
    readonly description?: string;
    readonly icon?: ReactNode;
};

function MenuElement({ link, text, icon, description }: ElementProps) {
    return (
        <div
            style={{
                color: 'grey',
            }}
        >
            <Stack direction="vertical" gap={0} className="mb-2 mt-1">
                <Stack direction="horizontal" gap={2}>
                    {icon}
                    <Nav.Link className="p-0 m-0" to={link} as={Link}>
                        {text}
                    </Nav.Link>
                </Stack>
                {description && <p className="fs-7 p-0 m-0">{description}</p>}
            </Stack>
        </div>
    );
}
