/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderResponse,
  OrderResponseAdmin,
  PageOrderResponse,
  PageSimpleOrderResponse,
  SearchOrderFilter,
  Sort,
} from '../models/index';
import {
    OrderResponseFromJSON,
    OrderResponseToJSON,
    OrderResponseAdminFromJSON,
    OrderResponseAdminToJSON,
    PageOrderResponseFromJSON,
    PageOrderResponseToJSON,
    PageSimpleOrderResponseFromJSON,
    PageSimpleOrderResponseToJSON,
    SearchOrderFilterFromJSON,
    SearchOrderFilterToJSON,
    SortFromJSON,
    SortToJSON,
} from '../models/index';

export interface GetCheckoutOrderRequest {
    checkoutId: string;
}

export interface GetOrderRequest {
    orderId: string;
}

export interface GetOrderAdminRequest {
    orderId: string;
}

export interface GetOrdersRequest {
    page: number;
    size: number;
    sort: Sort;
}

export interface GetOrdersAdminRequest {
    searchOrderFilter: SearchOrderFilter;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class OrderControllerApi extends runtime.BaseAPI {

    /**
     */
    async getCheckoutOrderRaw(requestParameters: GetCheckoutOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderResponse>> {
        if (requestParameters['checkoutId'] == null) {
            throw new runtime.RequiredError(
                'checkoutId',
                'Required parameter "checkoutId" was null or undefined when calling getCheckoutOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order/checkout/{checkoutId}`.replace(`{${"checkoutId"}}`, encodeURIComponent(String(requestParameters['checkoutId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCheckoutOrder(requestParameters: GetCheckoutOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderResponse> {
        const response = await this.getCheckoutOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrderRaw(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderResponse>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order/id/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOrder(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderResponse> {
        const response = await this.getOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrderAdminRaw(requestParameters: GetOrderAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderResponseAdmin>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getOrderAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order/admin/id/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseAdminFromJSON(jsonValue));
    }

    /**
     */
    async getOrderAdmin(requestParameters: GetOrderAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderResponseAdmin> {
        const response = await this.getOrderAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrdersRaw(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageOrderResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getOrders().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getOrders().'
            );
        }

        if (requestParameters['sort'] == null) {
            throw new runtime.RequiredError(
                'sort',
                'Required parameter "sort" was null or undefined when calling getOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOrders(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageOrderResponse> {
        const response = await this.getOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrdersAdminRaw(requestParameters: GetOrdersAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSimpleOrderResponse>> {
        if (requestParameters['searchOrderFilter'] == null) {
            throw new runtime.RequiredError(
                'searchOrderFilter',
                'Required parameter "searchOrderFilter" was null or undefined when calling getOrdersAdmin().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/order/admin/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchOrderFilterToJSON(requestParameters['searchOrderFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSimpleOrderResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOrdersAdmin(requestParameters: GetOrdersAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSimpleOrderResponse> {
        const response = await this.getOrdersAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
