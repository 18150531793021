/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimplePosterResponse
 */
export interface SimplePosterResponse {
    /**
     * 
     * @type {string}
     * @memberof SimplePosterResponse
     */
    posterId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePosterResponse
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePosterResponse
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePosterResponse
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof SimplePosterResponse
     */
    aspectRatio?: SimplePosterResponseAspectRatioEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SimplePosterResponse
     */
    isHot?: boolean;
}


/**
 * @export
 */
export const SimplePosterResponseAspectRatioEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type SimplePosterResponseAspectRatioEnum = typeof SimplePosterResponseAspectRatioEnum[keyof typeof SimplePosterResponseAspectRatioEnum];


/**
 * Check if a given object implements the SimplePosterResponse interface.
 */
export function instanceOfSimplePosterResponse(value: object): value is SimplePosterResponse {
    return true;
}

export function SimplePosterResponseFromJSON(json: any): SimplePosterResponse {
    return SimplePosterResponseFromJSONTyped(json, false);
}

export function SimplePosterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplePosterResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'image': json['image'] == null ? undefined : json['image'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'altText': json['altText'] == null ? undefined : json['altText'],
        'aspectRatio': json['aspectRatio'] == null ? undefined : json['aspectRatio'],
        'isHot': json['isHot'] == null ? undefined : json['isHot'],
    };
}

export function SimplePosterResponseToJSON(value?: SimplePosterResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'image': value['image'],
        'defaultName': value['defaultName'],
        'altText': value['altText'],
        'aspectRatio': value['aspectRatio'],
        'isHot': value['isHot'],
    };
}

