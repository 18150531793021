import React, { useContext } from 'react';
import { Stack, Image, Button } from 'react-bootstrap';
import { TbHeart, TbHeartFilled } from 'react-icons/tb';
import './ProductShelf.scss';
import { Link } from 'react-router-dom';
import Shelf from './Shelf';
import { GetShelfShelfEnum, ShelfControllerApi, ShelfDetails, SimplePosterResponse } from '../../generated';
import { API_CONFIG } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { WishlistContext } from '../../provider/WishlistProvider';
import { useTranslation } from 'react-i18next';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

type Props = {
    shelfEnum: GetShelfShelfEnum;
    size?: 'sm' | 'md' | 'lg';
    addCart?: 'none' | 'hover' | 'bottom';
};

function FavIcon({ posterId }: Readonly<{ posterId: string }>) {
    const { isFav, addPoster, removePoster } = useContext(WishlistContext);
    return (
        <>
            {isFav(posterId) ? (
                <TbHeartFilled size={20} className="fav-icon" onClick={() => removePoster(posterId)} />
            ) : (
                <TbHeart size={20} className="fav-icon" onClick={() => addPoster(posterId)} />
            )}
        </>
    );
}

type ProductPlaceProps = {
    size?: 'sm' | 'md' | 'lg';
    product: SimplePosterResponse;
    addCart?: 'none' | 'hover' | 'bottom';
};

export const ProductPlace = React.forwardRef<HTMLDivElement, ProductPlaceProps>(({ size, product, addCart }, ref) => {
    const { t } = useTranslation();
    const handleClick = () => {
        //TODO call the cart and add it with the minimal required data
    };

    return (
        <div className="unselectable">
            <Stack direction="vertical" gap={2}>
                <div className="align-self-center">
                    <div className="product-place">
                        <Link to={`/poster/${product.defaultName}`} className="fit d-block" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <Image
                                src={createPosterImageUrl(ImageSize.Small, product.image)}
                                alt={product.defaultName}
                                className={`${size} img-fluid-shelf custom-rounded cursor-pointer`}
                            />
                        </Link>
                        {product.posterId && <FavIcon posterId={product.posterId} />}
                    </div>
                </div>
                {addCart == 'bottom' && (
                    <Button
                        variant="outline-secondary"
                        style={{ width: 'fit-content' }}
                        size="sm"
                        className="mt-1 cursor-pointer rounded-pill align-self-center px-3"
                        onClick={handleClick}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleClick();
                            }
                        }}
                    >
                        + {t('BUTTON.addToCart')}
                    </Button>
                )}
            </Stack>
        </div>
    );
});

ProductPlace.displayName = 'ProductPlace';

const shelfClient = new ShelfControllerApi(API_CONFIG);

export default function ProductShelf({ shelfEnum, size = 'md', className, addCart, ...htmlProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { t } = useTranslation();
    const fetchShelf = async () => {
        if (!shelfEnum) return undefined;
        try {
            const response = await shelfClient.getShelf({ shelf: shelfEnum });
            setShelf(response);
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const [shelf, setShelf] = useStateSSRInit<ShelfDetails | undefined>(fetchShelf, shelfEnum);

    return (
        <>
            {shelf?.posters && shelf.posters.length >= 1 ? (
                <Shelf title={shelfEnum && t('SHELF.' + shelfEnum)} className={className} {...htmlProps}>
                    {Array.from(shelf.posters).map((product) => (
                        <ProductPlace key={`${product.posterId}`} size={size} product={product} addCart={addCart} />
                    ))}
                </Shelf>
            ) : (
                <></>
            )}
        </>
    );
}
