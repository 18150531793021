import React, { useState, useEffect } from 'react';
import { Button, Spinner, Image } from 'react-bootstrap';
import successAction from '../../resources/image/animatedIcons/successAction.gif';

type ActionButtonProps = {
    onActionClick: (actionComplete: (error?: string | undefined) => void) => void;
    children: React.ReactNode;
    successMessage: string;
    loadingMessage: string;
    duration?: number;
    size: 'sm' | 'lg' | undefined;
};

export function ActionButton({
    children,
    onActionClick,
    successMessage,
    loadingMessage,
    size,
    duration = 2000,
    ...htmlProps
}: ActionButtonProps & React.HTMLAttributes<HTMLDivElement>) {
    const [isActionComplete, setIsActionComplete] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const [disabled, setDisabled] = useState(false);

    const actionComplete = (error?: string) => {
        setIsActionComplete(true);
        setError(error);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsActionComplete(false);
        }, duration);
        return () => clearTimeout(timeout);
    }, [isActionComplete, duration]);

    return (
        <div {...htmlProps}>
            <Button
                disabled={disabled}
                variant={isActionComplete && !error ? 'success' : 'primary'}
                size={size}
                onClick={() => {
                    try {
                        setDisabled(true);
                        if (!isActionComplete) {
                            onActionClick(actionComplete);
                        }
                    } finally {
                        setDisabled(false);
                    }
                }}
            >
                {disabled ? (
                    <>
                        <Spinner className="me-2" /> {loadingMessage}
                    </>
                ) : isActionComplete && !error ? (
                    <>
                        <Image src={successAction} width={25} height={25} className="me-2" />
                        {successMessage}
                    </>
                ) : (
                    children
                )}
            </Button>
            {error && <p className="text-danger fs-7">{error}</p>}
        </div>
    );
}
