/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveCartRequest
 */
export interface RemoveCartRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveCartRequest
     */
    itemId: string;
}

/**
 * Check if a given object implements the RemoveCartRequest interface.
 */
export function instanceOfRemoveCartRequest(value: object): value is RemoveCartRequest {
    if (!('itemId' in value) || value['itemId'] === undefined) return false;
    return true;
}

export function RemoveCartRequestFromJSON(json: any): RemoveCartRequest {
    return RemoveCartRequestFromJSONTyped(json, false);
}

export function RemoveCartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveCartRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'itemId': json['itemId'],
    };
}

export function RemoveCartRequestToJSON(value?: RemoveCartRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'itemId': value['itemId'],
    };
}

