import React from "react";
import { ReactElement } from "react";
import { Configuration, PosterFilterAspectRatiosEnum, PosterFilterColorsEnum } from "../generated";
import endpoints from './endpoints.json';
import { TbBallFootball, TbCar, TbCat, TbCheese, TbDeviceTvOld, TbFlower, TbLifebuoy, TbMap, TbMusic, TbPalette, TbPlanet, TbRectangle, TbRectangleVertical, TbSquare, TbSword, TbWind } from "react-icons/tb";
import { LuRectangleHorizontal, LuRectangleVertical } from "react-icons/lu";

//UTILS
export const SEED = "SEED";

//LISTING CONSTANTS

export const LISTING_POSTERS = 'LISTING_POSTERS';
export const LISTING_FAVORITES = "LISTING_FAVORITES";
export const LISTING_DYNAMIC_TAGS = "LISTING_DYNAMIC_TAGS";
export const LISTING_CATEGORIES = "LISTING_CATEGORIES";
export const LISTING_POSTER_FILTERS = "LISTING_POSTER_FILTERS";

//CART CONSTANTS
export const CART_CART = "CART_CART";

//WISHLIST CONSTANTS
export const WISHLIST_WISHLIST = "WISHLIST_WISHLIST";

//PRODUCT CONSTANTS

export const PRODUCT_POSTER = "PRODUCT_POSTER";

//COLLECTION CONSTANTS

export const COLLECTION_POSTER = "COLLECTION_POSTER";

//COLLECTIONS CONSTANTS

export const LISTING_COLLECTION_FILTER = "LISTING_COLLECTION_FILTER";
export const COLLECTIONS_COLLECTIONS = "COLLECTIONS_COLLECTIONS";

//USER CONSTANTS

export const USER_DETAILS = "USER_DETAILS";
export const USER = "user"
export const CURRENCY = "CURRENCY";
export const LANGUAGE = "LANGUAGE";
export const AVAILABLE_LANGUAGES = "AVAILABLE_LANGUAGES";

//ANALYTICS

export const USER_ID = "USER_ID";
export const USER_TYPE = "USER_TYPE";
export const USER_AGE_GROUP = "USER_AGE_GROUP";
export const USER_LANGUAGE = "USER_LANGUAGE";
export const USER_PREFERRED_CATEGORY = "USER_PREFERRED_CATEGORY";
export const USER_CART_ITEMS = "USER_CART_ITEMS";
export const USER_WISHLIST_ITEMS = "USER_WISHLIST_ITEMS";

//SHELF CONSTANTS

export const SHELF_PREFIX = "SHELF_";

//ERROR

export const JAVA_EXCEPTION = "JAVA_EXCEPTION";

//API CALLS

export const API_CONFIG = new Configuration({
  basePath: window?.location?.origin === 'http://localhost:3000' ? endpoints.baseUrl : window?.location?.origin || endpoints.baseUrl,
  credentials: 'include'
});

// FILTERS

export const DYNAMIC_FILTER_ICONS: Map<string,ReactElement> = new Map([
    ['FLOWERS', <TbFlower size={20} key={'FLOWERS'} />],
    ['SPACE', <TbPlanet size={20} key={'SPACE'} />],
    ['LIFE', <TbLifebuoy size={20} key={'LIFE'}/>],
    ['SPORT', <TbBallFootball size={20} key={'SPORT'}/>],
    ['MUSIC', <TbMusic size={20} key={'MUSIC'}/>],
    ['ART', <TbPalette size={20} key={'ART'}/>],
    ['INSPIRING', <TbWind size={20} key={'INSPIRING'}/>],
    ['VINTAGE', <TbDeviceTvOld size={20} key={'VINTAGE'}/>],
    ['FOOD', <TbCheese size={20} key={'FOOD'}/>],
    ['WARRIOR', <TbSword size={20} key={'WARRIOR'}/>],
    ['MAP', <TbMap size={20} key={'MAP'}/>],
    ['ANIMAL', <TbCat size={20} key={'ANIMAL'}/>],
    ['CAR', <TbCar size={20} key={'CAR'}/>],
    ['VEHICLE', <TbCar size={20} key={'VEHICLE'}/>],
    ['STYLE', <TbPalette size={20} key={'STYLE'}/>],
]);

export const ASPECT_RATIO_ICONS: Map<PosterFilterAspectRatiosEnum, ReactElement> = new Map([
    [PosterFilterAspectRatiosEnum._12, <LuRectangleVertical size={40} key={PosterFilterAspectRatiosEnum._12} />],
    [PosterFilterAspectRatiosEnum._21, <LuRectangleHorizontal size={40} key={PosterFilterAspectRatiosEnum._21} />],
    [PosterFilterAspectRatiosEnum._23, <TbRectangleVertical size={40} key={PosterFilterAspectRatiosEnum._23} />],
    [PosterFilterAspectRatiosEnum._43, <TbRectangle size={40} key={PosterFilterAspectRatiosEnum._43} />],
]);


export const FILTER_COLORS: Map<PosterFilterColorsEnum,string> = new Map([
    [PosterFilterColorsEnum.Black, "#000000"],
    [PosterFilterColorsEnum.White, "#FFFFFF"],
    [PosterFilterColorsEnum.Blue, "#0000FF"],   
    [PosterFilterColorsEnum.Teal, "#03C0C6"],
    [PosterFilterColorsEnum.Purple, "#762CA7"],
    [PosterFilterColorsEnum.Pink, "#FF98BF"],
    [PosterFilterColorsEnum.Orange, "#FB940B"],
    [PosterFilterColorsEnum.Green, "#00CC00"],
    [PosterFilterColorsEnum.Brown, "#885418"],
    [PosterFilterColorsEnum.Yellow, "#FFFF00"],
    [PosterFilterColorsEnum.Red, "#CC0000"],
    [PosterFilterColorsEnum.Gray, "#999999"],
]);
    

//Newsletter

export const DEFAULT_NEWSLETTER_NAME = "news";

export const S3_PATH = 'https://s3.menoita.net';
export const ROOT_BUCKET = 'lumiglyph';
