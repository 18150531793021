import React, { useContext } from 'react';
import './Cart.scss';
import { Row, Col, Stack, Form, Image } from 'react-bootstrap';
import { TbX } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { CartContext } from '../../provider/CartProvider';
import { capitalize } from '../../service/Utils';
import { API_CONFIG } from '../../resources/constants';
import { CartControllerApi, CartItemDTO } from '../../generated';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';
import { useTranslation } from 'react-i18next';

const client = new CartControllerApi(API_CONFIG);

type Props = {
    readonly item: CartItemDTO;
};

export function CartProduct({ item }: Props) {
    const { removeItem, editItem, isExecutingOperation } = useContext(CartContext);
    const { t } = useTranslation();

    const setQuantity = (quantity: number) => {
        editItem({
            cartItemId: item?.itemId ?? '',
            frameId: item?.selectedFrame?.frameId,
            materialId: item?.selectedMaterial?.materialId,
            sizeId: item?.selectedSize?.sizeId,
            quantity: quantity,
        });
    };

    const setSize = (sizeId: string) => {
        editItem({
            cartItemId: item?.itemId ?? '',
            frameId: item?.selectedFrame?.frameId,
            materialId: item?.selectedMaterial?.materialId,
            sizeId: sizeId,
            quantity: item?.quantity,
        });
    };

    const setFrame = (frameId: string) => {
        editItem({
            cartItemId: item?.itemId ?? '',
            frameId: frameId,
            materialId: item?.selectedMaterial?.materialId,
            sizeId: item?.selectedSize?.sizeId,
            quantity: item?.quantity,
        });
    };

    const setMaterial = (materialId: string) => {
        editItem({
            cartItemId: item?.itemId ?? '',
            frameId: item?.selectedFrame?.frameId,
            materialId: materialId,
            sizeId: item?.selectedSize?.sizeId,
            quantity: item?.quantity,
        });
    };

    return (
        <>
            {item && (
                <Row className="p-4">
                    <Col md={4} style={{ maxHeight: '220px' }}>
                        <div
                            className="mx-auto"
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <Link to={`/poster/${item.posterDefaultName}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                <Image
                                    src={createPosterImageUrl(ImageSize.Small, item.posterImage)}
                                    alt={item.posterAltImage}
                                    fluid
                                    className="custom-rounded cart-product-image"
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h2 className="fs-5 f2-bold">{item.posterTranslatedName}</h2>
                        <Stack direction="horizontal" gap={4} className="mb-3 lead">
                            <Form.Group>
                                <Form.Label className="fs-6">{t('CART_PRODUCT.quantity')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={item.quantity}
                                    style={{ width: 'fit-content', maxWidth: '70px' }}
                                    disabled={isExecutingOperation}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (value < 1) return;
                                        setQuantity(value);
                                    }}
                                />
                            </Form.Group>
                            {item?.selectedSize && item?.sizes && item.sizes.length >= 1 && (
                                <Form.Group>
                                    <Form.Label className="fs-6">{t('CART_PRODUCT.size')}</Form.Label>
                                    <Form.Select
                                        defaultValue={item.selectedSize.sizeId}
                                        disabled={isExecutingOperation}
                                        onChange={(e) => setSize(String(e.target.value))}
                                    >
                                        {Array.from(item.sizes)?.map((size) => (
                                            <option key={size.sizeId} value={size.sizeId}>
                                                {`${size.name} - ${size.width} cm / ${size.height} cm`}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                        </Stack>
                        {item?.selectedFrame && item?.frames && item.frames.length >= 1 && (
                            <Form.Group>
                                <Form.Label className="fs-6 lead">{t('CART_PRODUCT.frame')}</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    defaultValue={item.selectedFrame.frameId}
                                    disabled={isExecutingOperation}
                                    onChange={(e) => setFrame(String(e.target.value))}
                                >
                                    {Array.from(item.frames)?.map((frame) => (
                                        <option key={frame.frameId} value={frame.frameId}>
                                            {capitalize(frame.name ?? '')}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                        {item?.selectedMaterial && item?.materials && item.materials.length >= 1 && (
                            <Form.Group>
                                <Form.Label className="fs-6 lead">{t('CART_PRODUCT.material')}</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    aria-label="Default select example"
                                    value={item.selectedMaterial.materialId}
                                    disabled={isExecutingOperation}
                                    onChange={(e) => setMaterial(String(e.target.value))}
                                >
                                    {Array.from(item.materials)?.map((material) => (
                                        <option key={material.materialId} value={material.materialId}>
                                            {capitalize(material.name ?? '')}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                    </Col>
                    <Col md={2}>
                        <h2 className="fs-5 f2-bold text-end cursor-pointer">
                            <TbX onClick={() => item.itemId && removeItem(item.itemId)} />
                        </h2>
                        <p className="fs-6 lead">{t('CART_PRODUCT.price')}</p>
                        {(item?.totalDiscount ?? 0) > 0 && (
                            <p className="fs-6 lead text-decoration-line-through text-muted mb-1 no-wrap">
                                {item.currencySymbol} {item.subTotal}
                            </p>
                        )}
                        <p className="fw-bold fs-4 no-wrap">
                            {item.currencySymbol} {item.totalPrice}
                        </p>
                    </Col>
                </Row>
            )}
        </>
    );
}
