/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDiscountRequest
 */
export interface CreateDiscountRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountRequest
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    discountType: CreateDiscountRequestDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountRequest
     */
    discountAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    discountRule?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    applicableTo: CreateDiscountRequestApplicableToEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    currency?: string;
}


/**
 * @export
 */
export const CreateDiscountRequestDiscountTypeEnum = {
    Percentage: 'PERCENTAGE',
    FixedAmount: 'FIXED_AMOUNT',
    FreeShipping: 'FREE_SHIPPING',
    FreeCheapestProduct: 'FREE_CHEAPEST_PRODUCT'
} as const;
export type CreateDiscountRequestDiscountTypeEnum = typeof CreateDiscountRequestDiscountTypeEnum[keyof typeof CreateDiscountRequestDiscountTypeEnum];

/**
 * @export
 */
export const CreateDiscountRequestApplicableToEnum = {
    PosterPrice: 'POSTER_PRICE',
    FramePrice: 'FRAME_PRICE',
    MaterialPrice: 'MATERIAL_PRICE',
    SizePrice: 'SIZE_PRICE',
    SubTotalPosterPrice: 'SUB_TOTAL_POSTER_PRICE',
    SubTotalCartPrice: 'SUB_TOTAL_CART_PRICE'
} as const;
export type CreateDiscountRequestApplicableToEnum = typeof CreateDiscountRequestApplicableToEnum[keyof typeof CreateDiscountRequestApplicableToEnum];


/**
 * Check if a given object implements the CreateDiscountRequest interface.
 */
export function instanceOfCreateDiscountRequest(value: object): value is CreateDiscountRequest {
    if (!('discountType' in value) || value['discountType'] === undefined) return false;
    if (!('discountAmount' in value) || value['discountAmount'] === undefined) return false;
    if (!('applicableTo' in value) || value['applicableTo'] === undefined) return false;
    return true;
}

export function CreateDiscountRequestFromJSON(json: any): CreateDiscountRequest {
    return CreateDiscountRequestFromJSONTyped(json, false);
}

export function CreateDiscountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDiscountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'order': json['order'] == null ? undefined : json['order'],
        'discountType': json['discountType'],
        'discountAmount': json['discountAmount'],
        'discountRule': json['discountRule'] == null ? undefined : json['discountRule'],
        'applicableTo': json['applicableTo'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function CreateDiscountRequestToJSON(value?: CreateDiscountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'order': value['order'],
        'discountType': value['discountType'],
        'discountAmount': value['discountAmount'],
        'discountRule': value['discountRule'],
        'applicableTo': value['applicableTo'],
        'currency': value['currency'],
    };
}

