/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagId?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    defaultName: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    translatableName?: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    score?: number;
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    modifiedBy?: string;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): value is Tag {
    if (!('defaultName' in value) || value['defaultName'] === undefined) return false;
    return true;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if (json == null) {
        return json;
    }
    return {
        
        'tagId': json['tagId'] == null ? undefined : json['tagId'],
        'defaultName': json['defaultName'],
        'translatableName': json['translatableName'] == null ? undefined : json['translatableName'],
        'score': json['score'] == null ? undefined : json['score'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tagId': value['tagId'],
        'defaultName': value['defaultName'],
        'translatableName': value['translatableName'],
        'score': value['score'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
    };
}

