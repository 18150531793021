import React, { useState, useCallback } from 'react';
import { Stack, Collapse } from 'react-bootstrap';
import { TbChevronUp } from 'react-icons/tb';

export type SectionProps = {
    readonly title: string;
    readonly children: React.ReactNode;
};

export type CustomAccordionProps = {
    sections: SectionProps[];
};

export default function PhoenixAccordion({ sections }: CustomAccordionProps) {
    const [open, setOpen] = useState<string>('0');

    const handleToggle = useCallback((eventKey: any) => {
        setOpen((prevOpen) => (prevOpen === eventKey ? null : eventKey));
    }, []);

    return (
        <div className="product-details-accordion">
            {sections.map((section, index) => (
                <React.Fragment key={section.title}>
                    <Stack
                        onClick={() => handleToggle(index.toString())}
                        aria-controls={`section-${index}`}
                        aria-expanded={open === index.toString()}
                        className={`product-accordion ${open === index.toString() ? 'accor-active' : ''}`}
                        direction="horizontal"
                    >
                        {section.title}
                        <div className="ms-auto">
                            <TbChevronUp
                                style={{
                                    transition: 'transform 0.3s ease-in-out',
                                    transform: open === index.toString() ? 'rotate(0deg)' : 'rotate(180deg)',
                                }}
                            />
                        </div>
                    </Stack>
                    <Collapse in={open === index.toString()}>
                        <div id={`section-${index}`} className="accordion-body">
                            {section.children}
                        </div>
                    </Collapse>
                </React.Fragment>
            ))}
        </div>
    );
}
