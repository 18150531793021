/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteCollectionRequest
 */
export interface DeleteCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCollectionRequest
     */
    collectionId: string;
}

/**
 * Check if a given object implements the DeleteCollectionRequest interface.
 */
export function instanceOfDeleteCollectionRequest(value: object): value is DeleteCollectionRequest {
    if (!('collectionId' in value) || value['collectionId'] === undefined) return false;
    return true;
}

export function DeleteCollectionRequestFromJSON(json: any): DeleteCollectionRequest {
    return DeleteCollectionRequestFromJSONTyped(json, false);
}

export function DeleteCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'collectionId': json['collectionId'],
    };
}

export function DeleteCollectionRequestToJSON(value?: DeleteCollectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collectionId': value['collectionId'],
    };
}

