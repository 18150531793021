import React, { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
    PosterFilter,
    PosterFilterAspectRatiosEnum,
    PosterFilterColorsEnum,
    SimplePosterResponse,
    PosterControllerApi,
    PageSimplePosterResponse,
} from '../generated';
import SSRContext from '../service/SSRContext';
import { API_CONFIG, LISTING_POSTER_FILTERS, LISTING_POSTERS } from '../resources/constants';
import { useStateSSRInit } from '../utils/DataWrapper';
import { saveQueryParam, removeQueryParam } from '../utils/WindowUtils';

type ListingContextType = {
    addTag: (tag: string) => void;
    removeTag: (tag: string) => void;
    addColor: (color: PosterFilterColorsEnum) => void;
    removeColor: (color: PosterFilterColorsEnum) => void;
    addAspectRatio: (aspectRatio: PosterFilterAspectRatiosEnum) => void;
    removeAspectRatio: (aspectRatio: PosterFilterAspectRatiosEnum) => void;
    setCategory: (category: string | undefined) => void;
    clearFilters: () => void;
    loadMoreProducts: () => void;
    setSearchTerm: (searchTerm: string) => void;
    searchTerm: string;
    tags: string[];
    colors: PosterFilterColorsEnum[];
    aspectRatios: PosterFilterAspectRatiosEnum[];
    category: string | undefined;
    filters: PosterFilter;
    posters: SimplePosterResponse[] | undefined;
    isLoading: boolean;
    firstIsLoading: boolean;
};

export const ListingContext = createContext<ListingContextType>({
    addTag: () => {},
    removeTag: () => {},
    addColor: () => {},
    removeColor: () => {},
    addAspectRatio: () => {},
    removeAspectRatio: () => {},
    setCategory: () => {},
    clearFilters: () => {},
    loadMoreProducts: () => {},
    setSearchTerm: () => {},
    searchTerm: '',
    tags: [],
    colors: [],
    aspectRatios: [],
    category: undefined,
    filters: {},
    posters: [],
    isLoading: false,
    firstIsLoading: true,
});

type Props = {
    children: ReactNode;
};

const posterClient = new PosterControllerApi(API_CONFIG);

// export const getSizeBasedOnDevice = () => {
//     if (typeof window !== 'undefined' && window.innerWidth) {
//         return window.innerWidth <= 768 ? 10 : 40;
//     }
//     return 40;
// };
export const getSizeBasedOnDevice = () => 40;

const posterFallback = (page: number, size: number, filter: PosterFilter, searchterm: string): Promise<SimplePosterResponse[] | undefined> => {
    return posterClient
        .getFilteredBy({
            page,
            size,
            posterFilter: filter,
            q: searchterm,
        })
        .then((response) => Array.from(response.content ?? []))
        .catch((error) => {
            console.error(error, error.message);
            return undefined;
        });
};

export default function ListingProvider({ children }: Props) {
    const initialFilters: PosterFilter = SSRContext.get(LISTING_POSTER_FILTERS) || {};
    let initialSearchTerm: string = '';

    const queryParams = SSRContext.getQueryParameters();
    const tagsParam = queryParams.get('tags');
    const aspectRatiosParam = queryParams.get('aspectRatios');
    const colorsParam = queryParams.get('colors');
    const categoryParam = queryParams.get('category');
    const searchTermParam = queryParams.get('q');

    if (tagsParam) {
        initialFilters.tags = tagsParam.split(',');
    }

    if (aspectRatiosParam) {
        initialFilters.aspectRatios = aspectRatiosParam.split(',').map((ar) => ar as PosterFilterAspectRatiosEnum);
    }

    if (colorsParam) {
        initialFilters.colors = colorsParam.split(',').map((c) => c as PosterFilterColorsEnum);
    }

    if (categoryParam) {
        initialFilters.category = categoryParam;
    }

    if (searchTermParam) {
        initialSearchTerm = searchTermParam;
    }

    const [filters, setFilters] = useState<PosterFilter>(initialFilters);
    const [posters, setPosters] = useStateSSRInit<SimplePosterResponse[] | undefined>(
        () => posterFallback(0, getSizeBasedOnDevice(), initialFilters, initialSearchTerm).finally(() => setFirstIsLoading(false)),
        LISTING_POSTERS,
        []
    );

    const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstIsLoading, setFirstIsLoading] = useState<boolean>(true);
    const [loadedAll, setLoadedAll] = useState<boolean>(false);

    const pageRef = useRef<number>(0);
    const mostRecentRequest = useRef<number>(0);

    const handlePostersResponse = (response: PageSimplePosterResponse, requestId: number, resetPage: boolean) => {
        if (mostRecentRequest.current === requestId) {
            const newPosters = Array.from(response.content ?? []);
            setPosters((prev) => (resetPage ? newPosters : [...(prev ?? []), ...newPosters]));

            if (response.last) {
                setLoadedAll(true);
            }
        }
    };

    const fetchPosters = (filter: PosterFilter, resetPage = false, searchTerm?: string) => {
        if (resetPage) {
            pageRef.current = 0;
            setPosters([]);
            setLoadedAll(false);
            setFirstIsLoading(true);
        }
        setIsLoading(true);
        const currentRequestId = Date.now();
        mostRecentRequest.current = currentRequestId;

        posterClient
            .getFilteredBy({ page: pageRef.current, size: getSizeBasedOnDevice(), posterFilter: filter, q: searchTerm })
            .then((res) => handlePostersResponse(res, currentRequestId, resetPage))
            .catch((error) => {
                console.error(error, error.message);
                setIsLoading(false);
            })
            .finally(() => {
                setFirstIsLoading(false);
                setIsLoading(false);
            });
    };

    const loadMoreProducts = () => {
        if (isLoading || loadedAll) return;
        pageRef.current += 1;
        fetchPosters(filters);
    };

    // Add a tag and update filters and URL
    const addTag = (tag: string) => {
        const newTags = [...(filters.tags ?? []), tag];
        const updatedFilters = { ...filters, tags: newTags };
        setFilters(updatedFilters);
        saveQueryParam('tags', newTags.join(','));
        fetchPosters(updatedFilters, true);
    };

    // Remove a tag and update filters and URL
    const removeTag = (tag: string) => {
        const newTags = (filters.tags ?? []).filter((t) => t !== tag);
        const updatedFilters = { ...filters, tags: newTags };
        setFilters(updatedFilters);
        if (!newTags.length) {
            removeQueryParam('tags');
        } else {
            saveQueryParam('tags', newTags.join(','));
        }
        fetchPosters(updatedFilters, true);
    };

    // Add a color and update filters and URL
    const addColor = (color: PosterFilterColorsEnum) => {
        const newColors = [...(filters.colors ?? []), color];
        const updatedFilters = { ...filters, colors: newColors };
        setFilters(updatedFilters);
        saveQueryParam('colors', newColors.join(','));
        fetchPosters(updatedFilters, true);
    };

    // Remove a color and update filters and URL
    const removeColor = (color: PosterFilterColorsEnum) => {
        const newColors = (filters.colors ?? []).filter((c) => c !== color);
        const updatedFilters = { ...filters, colors: newColors };
        setFilters(updatedFilters);
        if (!newColors.length) {
            removeQueryParam('colors');
        } else {
            saveQueryParam('colors', newColors.join(','));
        }
        fetchPosters(updatedFilters, true);
    };

    // Add an aspect ratio and update filters and URL
    const addAspectRatio = (aspectRatio: PosterFilterAspectRatiosEnum) => {
        const newAspectRatios = [...(filters.aspectRatios ?? []), aspectRatio];
        const updatedFilters = { ...filters, aspectRatios: newAspectRatios };
        setFilters(updatedFilters);
        saveQueryParam('aspectRatios', newAspectRatios.join(','));
        fetchPosters(updatedFilters, true);
    };

    // Remove an aspect ratio and update filters and URL
    const removeAspectRatio = (aspectRatio: PosterFilterAspectRatiosEnum) => {
        const newAspectRatios = (filters.aspectRatios ?? []).filter((ar) => ar !== aspectRatio);
        const updatedFilters = { ...filters, aspectRatios: newAspectRatios };
        setFilters(updatedFilters);
        if (!newAspectRatios.length) {
            removeQueryParam('aspectRatios');
        } else {
            saveQueryParam('aspectRatios', newAspectRatios.join(','));
        }
        fetchPosters(updatedFilters, true);
    };

    // Set category and update filters and URL
    const setCategory = (category: string | undefined) => {
        const updatedFilters = { ...filters, category };
        setFilters(updatedFilters);
        if (!category) {
            removeQueryParam('category');
        } else {
            saveQueryParam('category', category);
        }
        fetchPosters(updatedFilters, true);
    };

    const handleSearchTermChange = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (searchTerm) {
            saveQueryParam('q', searchTerm);
        } else {
            removeQueryParam('q');
        }
        fetchPosters(filters, true, searchTerm);
    };

    // Clear all filters and reset
    const clearFilters = () => {
        const updatedFilters = {};
        setFilters(updatedFilters);
        removeQueryParam('tags');
        removeQueryParam('colors');
        removeQueryParam('aspectRatios');
        removeQueryParam('category');
        fetchPosters(updatedFilters, true);
    };

    const contextValue = useMemo(
        () => ({
            addTag,
            removeTag,
            addColor,
            removeColor,
            addAspectRatio,
            removeAspectRatio,
            setCategory,
            clearFilters,
            loadMoreProducts,
            setSearchTerm: handleSearchTermChange,
            searchTerm,
            tags: filters.tags ?? [],
            colors: filters.colors ?? [],
            aspectRatios: filters.aspectRatios ?? [],
            category: filters.category,
            filters,
            posters,
            isLoading,
            firstIsLoading,
        }),
        [filters, posters, isLoading]
    );

    return <ListingContext.Provider value={contextValue}>{children}</ListingContext.Provider>;
}
