import React, { useCallback, useState } from 'react';
import { Col, Container, Row, Image, Stack, Collapse } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import './ProductDetails.scss';
import livingRoom from '../../resources/image/product/living_room2.jpg';
import { PosterResponse } from '../../generated';
import { TbChevronUp } from 'react-icons/tb';
import { createPosterImageUrl, ImageSize } from '../../utils/Utils';

type Props = {
    product: PosterResponse;
};

export default function ProductDetails({ product, className, ...htmlProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<string>('0');

    const handleToggle = useCallback((eventKey: any) => {
        setOpen((prevOpen) => (prevOpen === eventKey ? null : eventKey));
    }, []);

    return (
        <section {...htmlProps} className={`mt-5 product-details ${className ?? ''}`}>
            <Container className="pb-5 pt-5">
                <Row>
                    <Col>
                        <h1 className="display-4 fs-2 mb-4">{t('PRODUCT_DETAILS.title')}</h1>
                        <div className="product-details-accordion">
                            {/* Section 1 */}
                            <Stack
                                onClick={() => handleToggle('0')}
                                aria-controls="section-0"
                                aria-expanded={open === '0'}
                                className={`product-accordion ${open === '0' ? 'accor-active' : ''}`}
                                direction="horizontal"
                            >
                                {t('PRODUCT_DETAILS.aboutThisPoster')}
                                <div className="ms-auto">
                                    <TbChevronUp
                                        style={{
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: open === '0' ? 'rotate(0deg)' : 'rotate(180deg)',
                                        }}
                                    />
                                </div>
                            </Stack>
                            <Collapse in={open === '0'}>
                                <div id="section-0">
                                    <p className="fs-5">{t('PRODUCT_DETAILS.section1_title')}</p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text1">
                                            At Lumiglyph, <span className="fw-bold">perfection</span> is not just a goal; it&apos;s our standard.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text2">
                                            Brace yourself for an explosion of vibrant <span className="fw-bold">colors</span> and
                                            <span className="fw-bold">razor-sharp details</span>.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text3">
                                            Your space deserves nothing less than <span className="fw-bold">perfection</span>, and we deliver it.
                                        </Trans>
                                    </p>
                                    <p className="fs-5 mt-4">{t('PRODUCT_DETAILS.section1_versatilityTitle')}</p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text4">
                                            But that&apos;s not where the magic ends! With each purchase, we include our innovative{' '}
                                            <span className="font-italic">transparent reusable adhesive hooks</span> as a special bonus.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text5">
                                            These hooks aren&apos;t just convenient; they&apos;re a complete game-changer in home decor. Bid farewell to the
                                            hassle of drills and wall damage!
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text6">
                                            Our hooks are not only <span className="fw-bold">waterproof</span> and <span className="fw-bold">oilproof</span> but
                                            also <span className="fw-bold">reusable</span>.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text7">
                                            You can effortlessly <span className="font-italic">adjust and reposition</span> your poster as many times as your
                                            creative heart desires while keeping your walls pristine.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5">
                                        <Trans i18nKey="PRODUCT_DETAILS.section1_text8">
                                            It&apos;s the perfect pairing to unlock the full potential of your decor and express your unique{' '}
                                            <span className="font-italic">style</span> with ease.
                                        </Trans>
                                    </p>
                                </div>
                            </Collapse>
                            {/* Section 2 */}
                            <Stack
                                onClick={() => handleToggle('1')}
                                aria-controls="section-1"
                                aria-expanded={open === '1'}
                                className={`product-accordion ${open === '1' ? 'accor-active' : ''}`}
                                direction="horizontal"
                            >
                                {t('PRODUCT_DETAILS.shippingTitle')}
                                <div className="ms-auto">
                                    <TbChevronUp
                                        style={{
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: open === '1' ? 'rotate(0deg)' : 'rotate(180deg)',
                                        }}
                                    />
                                </div>
                            </Stack>
                            <Collapse in={open === '1'}>
                                <div id="section-1" className="accordion-body">
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.shippingText1">
                                            Say goodbye to shipping worries! We take <span className="font-italic">immense pride</span> in delivering your
                                            poster with the utmost care and efficiency.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.shippingText2">
                                            Our team ensures that your artwork arrives at your doorstep in <span className="fw-bold">impeccable condition</span>
                                            .
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.shippingText3">
                                            Rest assured, your poster will be handled with the same <span className="font-italic">love and care</span> as the
                                            art itself.
                                        </Trans>
                                    </p>
                                </div>
                            </Collapse>
                            {/* Section 3 */}
                            <Stack
                                onClick={() => handleToggle('2')}
                                aria-controls="section-2"
                                aria-expanded={open === '2'}
                                className={`product-accordion ${open !== '2' ? 'last-accord-element' : 'accor-active'}`}
                                direction="horizontal"
                            >
                                {t('PRODUCT_DETAILS.returnPolicyTitle')}
                                <div className="ms-auto">
                                    <TbChevronUp
                                        style={{
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: open === '2' ? 'rotate(0deg)' : 'rotate(180deg)',
                                        }}
                                    />
                                </div>
                            </Stack>
                            <Collapse in={open === '2'}>
                                <div id="section-2" className="product-accordion-body">
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.returnPolicyText1">
                                            We understand that choosing art is a <span className="font-italic">personal experience</span>, and we want you to
                                            make your selection with <span className="fw-bold">confidence</span>.
                                        </Trans>
                                    </p>
                                    <p className="fs-6-5 mb-2">
                                        <Trans i18nKey="PRODUCT_DETAILS.returnPolicyText2">
                                            We&apos;re confident that you&apos;ll <span className="font-italic">fall in love</span> with your new poster, but
                                            if, for any reason, it doesn&apos;t meet your expectations, fear not.
                                        </Trans>
                                    </p>
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                    {/* Image Section */}
                    <Col xs={12} lg={6}>
                        <div
                            className="w-100 h-100 custom-rounded"
                            style={{
                                backgroundImage: `url(${livingRoom})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'relative',
                                minHeight: '60vh',
                                maxHeight: '60vh',
                            }}
                        >
                            <Image
                                className="custom-rounded shadow-img"
                                src={createPosterImageUrl(ImageSize.Small, product.image)}
                                style={{
                                    position: 'absolute',
                                    top: '15%',
                                    left: '50%',
                                    transform: 'translate(-50%, 0)',
                                    width: '15%',
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
