/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CurrencyResponse,
} from '../models/index';
import {
    CurrencyResponseFromJSON,
    CurrencyResponseToJSON,
} from '../models/index';

export interface SetCurrencyRequest {
    currencyCode: string;
}

/**
 * 
 */
export class CurrencyControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAllCurrenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CurrencyResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/currency/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrencyResponseFromJSON));
    }

    /**
     */
    async getAllCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CurrencyResponse>> {
        const response = await this.getAllCurrenciesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentCurrencyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrencyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/currency`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrencyResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCurrentCurrency(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrencyResponse> {
        const response = await this.getCurrentCurrencyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setCurrencyRaw(requestParameters: SetCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrencyResponse>> {
        if (requestParameters['currencyCode'] == null) {
            throw new runtime.RequiredError(
                'currencyCode',
                'Required parameter "currencyCode" was null or undefined when calling setCurrency().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['currencyCode'] != null) {
            queryParameters['currencyCode'] = requestParameters['currencyCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/currency`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrencyResponseFromJSON(jsonValue));
    }

    /**
     */
    async setCurrency(requestParameters: SetCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrencyResponse> {
        const response = await this.setCurrencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
