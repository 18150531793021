/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrencyResponse
 */
export interface CurrencyResponse {
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    symbol?: string;
}

/**
 * Check if a given object implements the CurrencyResponse interface.
 */
export function instanceOfCurrencyResponse(value: object): value is CurrencyResponse {
    return true;
}

export function CurrencyResponseFromJSON(json: any): CurrencyResponse {
    return CurrencyResponseFromJSONTyped(json, false);
}

export function CurrencyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'name': json['name'] == null ? undefined : json['name'],
        'symbol': json['symbol'] == null ? undefined : json['symbol'],
    };
}

export function CurrencyResponseToJSON(value?: CurrencyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'name': value['name'],
        'symbol': value['symbol'],
    };
}

