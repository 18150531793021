/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplerCollectionResponse } from './SimplerCollectionResponse';
import {
    SimplerCollectionResponseFromJSON,
    SimplerCollectionResponseFromJSONTyped,
    SimplerCollectionResponseToJSON,
} from './SimplerCollectionResponse';

/**
 * 
 * @export
 * @interface CollectionShelfDetails
 */
export interface CollectionShelfDetails {
    /**
     * 
     * @type {string}
     * @memberof CollectionShelfDetails
     */
    title?: string;
    /**
     * 
     * @type {Array<SimplerCollectionResponse>}
     * @memberof CollectionShelfDetails
     */
    collections?: Array<SimplerCollectionResponse>;
}

/**
 * Check if a given object implements the CollectionShelfDetails interface.
 */
export function instanceOfCollectionShelfDetails(value: object): value is CollectionShelfDetails {
    return true;
}

export function CollectionShelfDetailsFromJSON(json: any): CollectionShelfDetails {
    return CollectionShelfDetailsFromJSONTyped(json, false);
}

export function CollectionShelfDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionShelfDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'collections': json['collections'] == null ? undefined : ((json['collections'] as Array<any>).map(SimplerCollectionResponseFromJSON)),
    };
}

export function CollectionShelfDetailsToJSON(value?: CollectionShelfDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'collections': value['collections'] == null ? undefined : ((value['collections'] as Array<any>).map(SimplerCollectionResponseToJSON)),
    };
}

