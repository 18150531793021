import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CheckoutWhatNext from '../components/Checkout/CheckoutWhatNext';
import SSRContext from '../service/SSRContext';
import RandomGenerator from '../utils/Random';
import { TbHome } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import Order from '../components/Order/Order';
import illustration from '../resources/image/payment/menoita_flat_illustration_cheerful_character_at_home_with_a_can_4a39f6c6-1af4-471c-8422-9500f895acfa.png';
import lost_illustration from '../resources/image/payment/lost_astr.png';
import { API_CONFIG } from '../resources/constants';
import { CartControllerApi, OrderControllerApi, OrderResponse } from '../generated';
import { backoffRetrier } from '../utils/Utils';
import { CartContext } from '../provider/CartProvider';

const client: OrderControllerApi = new OrderControllerApi(API_CONFIG);
const cartClient: CartControllerApi = new CartControllerApi(API_CONFIG);

async function getOrderFromCheckout(checkoutId: string): Promise<OrderResponse | undefined> {
    return backoffRetrier<OrderResponse | undefined>(() => client.getCheckoutOrder({ checkoutId }), 'exponential');
}

export default function PaymentCompletePage() {
    const checkoutId = SSRContext.getQueryParameter('checkout_session_id');
    const userName = SSRContext.getUser()?.firstName;
    const index = Math.floor(new RandomGenerator().random() * 3); // Assuming 3 titles/paragraphs in the JSON

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState<OrderResponse | undefined>();
    const { t } = useTranslation();

    const { clearCartStorage } = useContext(CartContext);

    useEffect(() => {
        if (checkoutId) {
            setLoading(true);
            getOrderFromCheckout(checkoutId)
                .then((order) => {
                    setOrder(order);
                    clearCartStorage();
                    cartClient.clearCart({}).catch((e: any) => console.error(e));
                })
                .catch((e: any) => console.error(e))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [checkoutId, clearCartStorage]);

    return (
        <Container>
            {checkoutId && (loading || order) ? (
                <>
                    <Row>
                        <Col>
                            <h1 className="display-3 text-center mt-5">
                                {userName ? t(`PAYMENT.title_loggin.${index}`, { user_name: userName }) : t(`PAYMENT.title_anon.${index}`)}
                            </h1>
                            <p className="text-center w-75 mx-auto mt-4 mb-4 lead">
                                {userName ? t(`PAYMENT.paragraph_loggin.${index}`, { user_name: userName }) : t(`PAYMENT.paragraph_anon.${index}`)}
                            </p>
                            <div className="text-center mt-4">
                                <Link to="/">
                                    <Button className="mx-auto">
                                        <TbHome size={22} className="pb-1" /> {t('PAYMENT.button_back_to_home')}
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col md="4">
                            <div>
                                <Image src={illustration} className="w-100" />
                            </div>
                        </Col>
                    </Row>
                    {(order || loading) && (
                        <div>
                            <h4 className="display-4 fs-2 mt-5 mb-4">{t('PAYMENT.order_details_title')}</h4>
                            <Order order={order} isLoading={loading} />
                        </div>
                    )}
                    <CheckoutWhatNext />
                </>
            ) : (
                <>
                    <Row>
                        <Col>
                            <h1 className="display-3 text-center mt-5">{t('PAYMENT.oops_message_title')}</h1>
                            <p className="text-center w-75 mx-auto mt-4 lead">{t('PAYMENT.oops_message_paragraph')}</p>
                            <div className="text-center mt-4">
                                <Link to="/">
                                    <Button className="mx-auto">
                                        <TbHome size={22} className="pb-1" /> {t('PAYMENT.button_back_to_home')}
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col md="4">
                            <div>
                                <Image src={lost_illustration} className="w-100" />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
}
