/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FrameResponse } from './FrameResponse';
import {
    FrameResponseFromJSON,
    FrameResponseFromJSONTyped,
    FrameResponseToJSON,
} from './FrameResponse';
import type { SizeResponse } from './SizeResponse';
import {
    SizeResponseFromJSON,
    SizeResponseFromJSONTyped,
    SizeResponseToJSON,
} from './SizeResponse';
import type { SimpleTagResponse } from './SimpleTagResponse';
import {
    SimpleTagResponseFromJSON,
    SimpleTagResponseFromJSONTyped,
    SimpleTagResponseToJSON,
} from './SimpleTagResponse';
import type { MaterialResponse } from './MaterialResponse';
import {
    MaterialResponseFromJSON,
    MaterialResponseFromJSONTyped,
    MaterialResponseToJSON,
} from './MaterialResponse';

/**
 * 
 * @export
 * @interface PosterResponse
 */
export interface PosterResponse {
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    posterId?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    smallDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    translatedName?: string;
    /**
     * 
     * @type {Set<SimpleTagResponse>}
     * @memberof PosterResponse
     */
    tags?: Set<SimpleTagResponse>;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    aspectRatio?: PosterResponseAspectRatioEnum;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    color?: PosterResponseColorEnum;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PosterResponse
     */
    currencySymbol?: string;
    /**
     * 
     * @type {Set<SizeResponse>}
     * @memberof PosterResponse
     */
    sizes?: Set<SizeResponse>;
    /**
     * 
     * @type {Set<MaterialResponse>}
     * @memberof PosterResponse
     */
    materials?: Set<MaterialResponse>;
    /**
     * 
     * @type {Set<FrameResponse>}
     * @memberof PosterResponse
     */
    frames?: Set<FrameResponse>;
    /**
     * 
     * @type {number}
     * @memberof PosterResponse
     */
    price?: number;
}


/**
 * @export
 */
export const PosterResponseAspectRatioEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type PosterResponseAspectRatioEnum = typeof PosterResponseAspectRatioEnum[keyof typeof PosterResponseAspectRatioEnum];

/**
 * @export
 */
export const PosterResponseColorEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type PosterResponseColorEnum = typeof PosterResponseColorEnum[keyof typeof PosterResponseColorEnum];


/**
 * Check if a given object implements the PosterResponse interface.
 */
export function instanceOfPosterResponse(value: object): value is PosterResponse {
    return true;
}

export function PosterResponseFromJSON(json: any): PosterResponse {
    return PosterResponseFromJSONTyped(json, false);
}

export function PosterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PosterResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'description': json['description'] == null ? undefined : json['description'],
        'altText': json['altText'] == null ? undefined : json['altText'],
        'smallDescription': json['smallDescription'] == null ? undefined : json['smallDescription'],
        'image': json['image'] == null ? undefined : json['image'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'translatedName': json['translatedName'] == null ? undefined : json['translatedName'],
        'tags': json['tags'] == null ? undefined : (new Set((json['tags'] as Array<any>).map(SimpleTagResponseFromJSON))),
        'aspectRatio': json['aspectRatio'] == null ? undefined : json['aspectRatio'],
        'color': json['color'] == null ? undefined : json['color'],
        'currencyCode': json['currencyCode'] == null ? undefined : json['currencyCode'],
        'currencySymbol': json['currencySymbol'] == null ? undefined : json['currencySymbol'],
        'sizes': json['sizes'] == null ? undefined : (new Set((json['sizes'] as Array<any>).map(SizeResponseFromJSON))),
        'materials': json['materials'] == null ? undefined : (new Set((json['materials'] as Array<any>).map(MaterialResponseFromJSON))),
        'frames': json['frames'] == null ? undefined : (new Set((json['frames'] as Array<any>).map(FrameResponseFromJSON))),
        'price': json['price'] == null ? undefined : json['price'],
    };
}

export function PosterResponseToJSON(value?: PosterResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'description': value['description'],
        'altText': value['altText'],
        'smallDescription': value['smallDescription'],
        'image': value['image'],
        'defaultName': value['defaultName'],
        'translatedName': value['translatedName'],
        'tags': value['tags'] == null ? undefined : (Array.from(value['tags'] as Set<any>).map(SimpleTagResponseToJSON)),
        'aspectRatio': value['aspectRatio'],
        'color': value['color'],
        'currencyCode': value['currencyCode'],
        'currencySymbol': value['currencySymbol'],
        'sizes': value['sizes'] == null ? undefined : (Array.from(value['sizes'] as Set<any>).map(SizeResponseToJSON)),
        'materials': value['materials'] == null ? undefined : (Array.from(value['materials'] as Set<any>).map(MaterialResponseToJSON)),
        'frames': value['frames'] == null ? undefined : (Array.from(value['frames'] as Set<any>).map(FrameResponseToJSON)),
        'price': value['price'],
    };
}

