import React, { useContext, useState } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import UserAddressForm from './UserAddressForm';
import { TbEdit, TbX } from 'react-icons/tb';
import UserImageProfile from './UserImageProfile';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { UserControllerApi, UserResponse } from '../../generated';
import { API_CONFIG, USER_DETAILS } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { MenuContext } from '../../provider/MenuProvider';
import { useTranslation } from 'react-i18next'; // Added for translations

const client: UserControllerApi = new UserControllerApi(API_CONFIG);

export default function UserSettings() {
    const { t } = useTranslation(); // Initialize translation hook
    const [editable, setEditable] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchUserDetails = async () => {
        try {
            setIsLoading(true);
            return await client.getUserDetails();
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const { update } = useContext(MenuContext);

    const [userDetails, setUserDetails] = useStateSSRInit<UserResponse | undefined>(fetchUserDetails, USER_DETAILS);

    const submitUserDetails = (values: UserResponse, formikHelpers: FormikHelpers<UserResponse>) => {
        formikHelpers.setSubmitting(true);
        client
            .editUser({
                editUserRequest: values,
            })
            .then((response) => {
                setUserDetails(response);
                setEditable(false);
                update();
            })
            .catch((e: any) => {
                console.error(e);
                formikHelpers.setValues(userDetails || {});
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        address: Yup.object().shape({
            addressLine: Yup.string().required(t('FORM_VALIDATION.Required')),
            postalCode: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            state: Yup.string(),
        }),
        firstName: Yup.string().required(t('FORM_VALIDATION.Required')),
        lastName: Yup.string().required(t('FORM_VALIDATION.Required')),
        email: Yup.string().email(t('FORM_VALIDATION.InvalidEmailAddress')).required(t('FORM_VALIDATION.Required')),
        phoneNumber: Yup.string(),
        image: Yup.string(),
        preferredLanguage: Yup.string(),
        isCat: Yup.boolean(),
    });

    return (
        <>
            {userDetails && (
                <Formik initialValues={userDetails} validationSchema={validationSchema} onSubmit={submitUserDetails}>
                    {(formikProps) => (
                        <Form className="lead pt-3" onSubmit={formikProps.handleSubmit}>
                            <Row>
                                <Col md={8}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '0px',
                                        }}
                                    ></div>
                                    <UserAddressForm editable={editable} formikProps={formikProps} />
                                </Col>
                                <Col
                                    xs={{
                                        span: 12,
                                        order: 'first',
                                    }}
                                    md={{
                                        span: 4,
                                        order: 'last',
                                    }}
                                >
                                    {editable ? (
                                        <TbX size={23} className="cursor-pointer" onClick={() => setEditable(!editable)} title={t('BUTTON.cancelEdit')} />
                                    ) : (
                                        <TbEdit size={23} className="cursor-pointer" onClick={() => setEditable(!editable)} title={t('BUTTON.editProfile')} />
                                    )}
                                    <UserImageProfile editable={editable} formikProps={formikProps} />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
}
