import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Stack, Image } from 'react-bootstrap';
import { ReactComponent as BrandGoogle } from '../../resources/image/signIn/google.svg';
import { ReactComponent as Brandtwitter } from '../../resources/image/signIn/twitter.svg';
import { ReactComponent as BrandInsta } from '../../resources/image/signIn/insta.svg';
import { ReactComponent as BrandFacebook } from '../../resources/image/signIn/facebook.svg';
import endpoints from '../../resources/endpoints.json';
import signInIllustration from '../../resources/image/signIn/Login-amico.svg';
import './SignIn.scss';

export default function SignIn() {
    const { t } = useTranslation();

    return (
        <div className="sign-in">
            <Container className="sign-in-contaier shadow-lg rounded">
                <Row className="w-100 h-100">
                    <Col md={6} lg={8} className="pb-3">
                        <div
                            className="sign-in-background"
                            style={{
                                backgroundImage: `url(${signInIllustration})`,
                            }}
                        />
                    </Col>
                    <Col className="d-flex">
                        <Stack gap={3} className="align-self-center">
                            <div>
                                <div className="text-center">
                                    <Image src="/android-chrome-192x192.png" width={80} height={80} className="mb-2" />
                                </div>
                                <h3 className="text-center fw-light mb-3">{t('SIGN_IN.appName')}</h3>
                                <h4 className="text-center fw-light">{t('SIGN_IN.title')}</h4>
                            </div>
                            <Button className="btn btn-light text-start btn-sign-in mt-3" href={endpoints.signIn.google}>
                                <BrandGoogle width={24} height={24} /> {t('SIGN_IN.google')}
                            </Button>
                            <Button className="btn btn-light text-start btn-sign-in" href={endpoints.signIn.twitter}>
                                <Brandtwitter width={24} height={24} /> {t('SIGN_IN.twitter')}
                            </Button>
                            <Button className="btn btn-light text-start btn-sign-in" href={endpoints.signIn.facebook}>
                                <BrandFacebook width={24} height={24} strokeWidth={2} /> {t('SIGN_IN.facebook')}
                            </Button>
                            <Button className="btn btn-light text-start btn-sign-in" href={endpoints.signIn.instragram}>
                                <BrandInsta width={24} height={24} strokeWidth={2} /> {t('SIGN_IN.instagram')}
                            </Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
