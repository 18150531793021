/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Frame } from './Frame';
import {
    FrameFromJSON,
    FrameFromJSONTyped,
    FrameToJSON,
} from './Frame';
import type { Size } from './Size';
import {
    SizeFromJSON,
    SizeFromJSONTyped,
    SizeToJSON,
} from './Size';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { Material } from './Material';
import {
    MaterialFromJSON,
    MaterialFromJSONTyped,
    MaterialToJSON,
} from './Material';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface Poster
 */
export interface Poster {
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    posterId?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    translatableDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    translatableAltText?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    translatableSmallDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    translatableName?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    defaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    image?: string;
    /**
     * 
     * @type {Set<Tag>}
     * @memberof Poster
     */
    tags?: Set<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    aspectRatio?: PosterAspectRatioEnum;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    color?: PosterColorEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Poster
     */
    isHot?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Poster
     */
    score?: number;
    /**
     * 
     * @type {Set<Size>}
     * @memberof Poster
     */
    sizes?: Set<Size>;
    /**
     * 
     * @type {Set<Material>}
     * @memberof Poster
     */
    materials?: Set<Material>;
    /**
     * 
     * @type {Set<Frame>}
     * @memberof Poster
     */
    frames?: Set<Frame>;
    /**
     * 
     * @type {number}
     * @memberof Poster
     */
    price?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Poster
     */
    currency?: Currency;
    /**
     * 
     * @type {Date}
     * @memberof Poster
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Poster
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Poster
     */
    modifiedBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Poster
     */
    hot?: boolean;
}


/**
 * @export
 */
export const PosterAspectRatioEnum = {
    _12: 'R_1_2',
    _23: 'R_2_3',
    _21: 'R_2_1',
    _43: 'R_4_3'
} as const;
export type PosterAspectRatioEnum = typeof PosterAspectRatioEnum[keyof typeof PosterAspectRatioEnum];

/**
 * @export
 */
export const PosterColorEnum = {
    Black: 'BLACK',
    White: 'WHITE',
    Gray: 'GRAY',
    Blue: 'BLUE',
    Teal: 'TEAL',
    Purple: 'PURPLE',
    Pink: 'PINK',
    Orange: 'ORANGE',
    Green: 'GREEN',
    Brown: 'BROWN',
    Yellow: 'YELLOW',
    Red: 'RED'
} as const;
export type PosterColorEnum = typeof PosterColorEnum[keyof typeof PosterColorEnum];


/**
 * Check if a given object implements the Poster interface.
 */
export function instanceOfPoster(value: object): value is Poster {
    return true;
}

export function PosterFromJSON(json: any): Poster {
    return PosterFromJSONTyped(json, false);
}

export function PosterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Poster {
    if (json == null) {
        return json;
    }
    return {
        
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'translatableDescription': json['translatableDescription'] == null ? undefined : json['translatableDescription'],
        'translatableAltText': json['translatableAltText'] == null ? undefined : json['translatableAltText'],
        'translatableSmallDescription': json['translatableSmallDescription'] == null ? undefined : json['translatableSmallDescription'],
        'translatableName': json['translatableName'] == null ? undefined : json['translatableName'],
        'defaultName': json['defaultName'] == null ? undefined : json['defaultName'],
        'image': json['image'] == null ? undefined : json['image'],
        'tags': json['tags'] == null ? undefined : (new Set((json['tags'] as Array<any>).map(TagFromJSON))),
        'aspectRatio': json['aspectRatio'] == null ? undefined : json['aspectRatio'],
        'color': json['color'] == null ? undefined : json['color'],
        'isHot': json['isHot'] == null ? undefined : json['isHot'],
        'score': json['score'] == null ? undefined : json['score'],
        'sizes': json['sizes'] == null ? undefined : (new Set((json['sizes'] as Array<any>).map(SizeFromJSON))),
        'materials': json['materials'] == null ? undefined : (new Set((json['materials'] as Array<any>).map(MaterialFromJSON))),
        'frames': json['frames'] == null ? undefined : (new Set((json['frames'] as Array<any>).map(FrameFromJSON))),
        'price': json['price'] == null ? undefined : json['price'],
        'currency': json['currency'] == null ? undefined : CurrencyFromJSON(json['currency']),
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'hot': json['hot'] == null ? undefined : json['hot'],
    };
}

export function PosterToJSON(value?: Poster | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posterId': value['posterId'],
        'translatableDescription': value['translatableDescription'],
        'translatableAltText': value['translatableAltText'],
        'translatableSmallDescription': value['translatableSmallDescription'],
        'translatableName': value['translatableName'],
        'defaultName': value['defaultName'],
        'image': value['image'],
        'tags': value['tags'] == null ? undefined : (Array.from(value['tags'] as Set<any>).map(TagToJSON)),
        'aspectRatio': value['aspectRatio'],
        'color': value['color'],
        'isHot': value['isHot'],
        'score': value['score'],
        'sizes': value['sizes'] == null ? undefined : (Array.from(value['sizes'] as Set<any>).map(SizeToJSON)),
        'materials': value['materials'] == null ? undefined : (Array.from(value['materials'] as Set<any>).map(MaterialToJSON)),
        'frames': value['frames'] == null ? undefined : (Array.from(value['frames'] as Set<any>).map(FrameToJSON)),
        'price': value['price'],
        'currency': CurrencyToJSON(value['currency']),
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'createdBy': value['createdBy'],
        'modifiedBy': value['modifiedBy'],
        'hot': value['hot'],
    };
}

