/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePosterResponse } from './SimplePosterResponse';
import {
    SimplePosterResponseFromJSON,
    SimplePosterResponseFromJSONTyped,
    SimplePosterResponseToJSON,
} from './SimplePosterResponse';

/**
 * 
 * @export
 * @interface ShelfDetails
 */
export interface ShelfDetails {
    /**
     * 
     * @type {Array<SimplePosterResponse>}
     * @memberof ShelfDetails
     */
    posters?: Array<SimplePosterResponse>;
}

/**
 * Check if a given object implements the ShelfDetails interface.
 */
export function instanceOfShelfDetails(value: object): value is ShelfDetails {
    return true;
}

export function ShelfDetailsFromJSON(json: any): ShelfDetails {
    return ShelfDetailsFromJSONTyped(json, false);
}

export function ShelfDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShelfDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'posters': json['posters'] == null ? undefined : ((json['posters'] as Array<any>).map(SimplePosterResponseFromJSON)),
    };
}

export function ShelfDetailsToJSON(value?: ShelfDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'posters': value['posters'] == null ? undefined : ((value['posters'] as Array<any>).map(SimplePosterResponseToJSON)),
    };
}

