import React, { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { CurrencyControllerApi, CurrencyResponse } from '../../generated';
import { API_CONFIG, CURRENCY } from '../../resources/constants';
import { useStateSSRInit } from '../../utils/DataWrapper';
import { useTranslation } from 'react-i18next';
import './CurrencySelector.scss';

const client: CurrencyControllerApi = new CurrencyControllerApi(API_CONFIG);

const fetchCurrentCurrency = (): Promise<CurrencyResponse | undefined> => {
    return client.getCurrentCurrency();
};

export default function CurrencySelector() {
    const { t } = useTranslation(); // Initialize translation hook
    const [currency, setCurrency] = useStateSSRInit<CurrencyResponse | undefined>(fetchCurrentCurrency, CURRENCY);
    const [availableCurrencies, setAvailableCurrencies] = useState<CurrencyResponse[]>([]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShow = () => {
        setShow(true);
        setIsLoading(true);

        client
            .getAllCurrencies()
            .then((response) => setAvailableCurrencies(response))
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    };

    const handleSelect = (selectedCurrency: CurrencyResponse) => {
        if (selectedCurrency.code != currency?.code) {
            setShow(false);
            client
                .setCurrency({
                    currencyCode: selectedCurrency.code ?? '',
                })
                .then((currencyFromServer) => {
                    setCurrency(currencyFromServer);
                    // refresh page
                    window.location.reload();
                })
                .catch((error) => console.error(error));
        }
    };

    return (
        <>
            <div onClick={handleShow} className="d-flex justify-content-between fs-6-8 cursor-pointer p-2">
                <span className="me-2">{t('CURRENCY_SELECTOR.label')}</span>
                <strong>
                    {currency?.code} ({currency?.symbol})
                </strong>
            </div>
            <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
                <Modal.Body>
                    <h3 className="fw-bolder opacity-75">{t('CURRENCY_SELECTOR.modalTitle')}</h3>
                    {isLoading ? (
                        <div className="w-100 pt-4 pb-3 text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <Row className="p-2 mt-3">
                            {availableCurrencies
                                .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                                .map((c) => (
                                    <Col key={c.code} onClick={() => handleSelect(c)} md={6}>
                                        <div className={`px-2 py-1 my-1 cursor-pointer ${c.code == currency?.code && 'currency-selected'}`}>
                                            <span className="fw-bolder opacity-75"> {c.code} - </span>
                                            <span className="fw-bolder opacity-75"> {c.name} </span>
                                            <span className="fw-bolder opacity-50">({c.symbol})</span>
                                        </div>
                                    </Col>
                                ))}
                        </Row>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}
