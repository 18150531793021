/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CollectionShelfDetails,
  ShelfDetails,
} from '../models/index';
import {
    CollectionShelfDetailsFromJSON,
    CollectionShelfDetailsToJSON,
    ShelfDetailsFromJSON,
    ShelfDetailsToJSON,
} from '../models/index';

export interface GetCollectionShelfRequest {
    shelf: GetCollectionShelfShelfEnum;
}

export interface GetShelfRequest {
    shelf: GetShelfShelfEnum;
}

export interface GetShelfByCategoryRequest {
    category: string;
}

/**
 * 
 */
export class ShelfControllerApi extends runtime.BaseAPI {

    /**
     */
    async getCollectionShelfRaw(requestParameters: GetCollectionShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionShelfDetails>> {
        if (requestParameters['shelf'] == null) {
            throw new runtime.RequiredError(
                'shelf',
                'Required parameter "shelf" was null or undefined when calling getCollectionShelf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shelf/collection/{shelf}`.replace(`{${"shelf"}}`, encodeURIComponent(String(requestParameters['shelf']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionShelfDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getCollectionShelf(requestParameters: GetCollectionShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionShelfDetails> {
        const response = await this.getCollectionShelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShelfRaw(requestParameters: GetShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShelfDetails>> {
        if (requestParameters['shelf'] == null) {
            throw new runtime.RequiredError(
                'shelf',
                'Required parameter "shelf" was null or undefined when calling getShelf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shelf/{shelf}`.replace(`{${"shelf"}}`, encodeURIComponent(String(requestParameters['shelf']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShelfDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getShelf(requestParameters: GetShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShelfDetails> {
        const response = await this.getShelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShelfByCategoryRaw(requestParameters: GetShelfByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShelfDetails>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling getShelfByCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/shelf/category/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShelfDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getShelfByCategory(requestParameters: GetShelfByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShelfDetails> {
        const response = await this.getShelfByCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetCollectionShelfShelfEnum = {
    FrequentlyBoughtTogether: 'FREQUENTLY_BOUGHT_TOGETHER',
    YouAlsoMightLike: 'YOU_ALSO_MIGHT_LIKE',
    ListingShelf: 'LISTING_SHELF'
} as const;
export type GetCollectionShelfShelfEnum = typeof GetCollectionShelfShelfEnum[keyof typeof GetCollectionShelfShelfEnum];
/**
 * @export
 */
export const GetShelfShelfEnum = {
    FrequentlyBoughtTogether: 'FREQUENTLY_BOUGHT_TOGETHER',
    YouAlsoMightLike: 'YOU_ALSO_MIGHT_LIKE',
    ListingShelf: 'LISTING_SHELF'
} as const;
export type GetShelfShelfEnum = typeof GetShelfShelfEnum[keyof typeof GetShelfShelfEnum];
