/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleUserResponse
 */
export interface SimpleUserResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleUserResponse
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleUserResponse
     */
    cat?: boolean;
}

/**
 * Check if a given object implements the SimpleUserResponse interface.
 */
export function instanceOfSimpleUserResponse(value: object): value is SimpleUserResponse {
    return true;
}

export function SimpleUserResponseFromJSON(json: any): SimpleUserResponse {
    return SimpleUserResponseFromJSONTyped(json, false);
}

export function SimpleUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'preferredLanguage': json['preferredLanguage'] == null ? undefined : json['preferredLanguage'],
        'image': json['image'] == null ? undefined : json['image'],
        'cat': json['cat'] == null ? undefined : json['cat'],
    };
}

export function SimpleUserResponseToJSON(value?: SimpleUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'preferredLanguage': value['preferredLanguage'],
        'image': value['image'],
        'cat': value['cat'],
    };
}

