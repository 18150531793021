/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchUserFilter
 */
export interface SearchUserFilter {
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserFilter
     */
    addressLine?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserFilter
     */
    enabled?: boolean;
}

/**
 * Check if a given object implements the SearchUserFilter interface.
 */
export function instanceOfSearchUserFilter(value: object): value is SearchUserFilter {
    return true;
}

export function SearchUserFilterFromJSON(json: any): SearchUserFilter {
    return SearchUserFilterFromJSONTyped(json, false);
}

export function SearchUserFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchUserFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': json['country'] == null ? undefined : json['country'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'addressLine': json['addressLine'] == null ? undefined : json['addressLine'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
    };
}

export function SearchUserFilterToJSON(value?: SearchUserFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'city': value['city'],
        'state': value['state'],
        'country': value['country'],
        'postalCode': value['postalCode'],
        'addressLine': value['addressLine'],
        'enabled': value['enabled'],
    };
}

