/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LanguageResponse,
} from '../models/index';
import {
    LanguageResponseFromJSON,
    LanguageResponseToJSON,
} from '../models/index';

export interface SetLanguageRequest {
    languageCode: string;
}

/**
 * 
 */
export class LanguageControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAvailableLanguagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LanguageResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/language/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageResponseFromJSON));
    }

    /**
     */
    async getAvailableLanguages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LanguageResponse>> {
        const response = await this.getAvailableLanguagesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentLanguageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LanguageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/language`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LanguageResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCurrentLanguage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LanguageResponse> {
        const response = await this.getCurrentLanguageRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setLanguageRaw(requestParameters: SetLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LanguageResponse>> {
        if (requestParameters['languageCode'] == null) {
            throw new runtime.RequiredError(
                'languageCode',
                'Required parameter "languageCode" was null or undefined when calling setLanguage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageCode'] != null) {
            queryParameters['languageCode'] = requestParameters['languageCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/language`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LanguageResponseFromJSON(jsonValue));
    }

    /**
     */
    async setLanguage(requestParameters: SetLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LanguageResponse> {
        const response = await this.setLanguageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
