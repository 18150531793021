import React, { useContext, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import Cart from '../components/Cart/Cart';
import ProductShelf from '../components/Shelf/ProductShelf';
import { GetShelfShelfEnum } from '../generated';
import NotFound from '../components/Product/NotFound';
import { CartContext } from '../provider/CartProvider';
import { useTranslation } from 'react-i18next';

export default function CartPage() {
    return (
        <Container className="mt-5">
            <CartPageContent />
        </Container>
    );
}

function CartPageContent() {
    const { fetchCart, getItems, isLoading } = useContext(CartContext);
    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.CART_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.CART_placeholders', { returnObjects: true }) as string[], [t]);

    useEffect(() => {
        fetchCart();
    }, [fetchCart]);
    return (
        <>
            {getItems()?.length > 0 || isLoading ? (
                <>
                    <Cart />
                    <ProductShelf shelfEnum={GetShelfShelfEnum.FrequentlyBoughtTogether} />
                </>
            ) : (
                <NotFound
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                    title={t('NOT_FOUND.CART_title')}
                    description={descriptions}
                    placeholder={placeholders}
                />
            )}
        </>
    );
}
