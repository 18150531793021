import React from 'react';
import { CollectionResponse } from '../../generated';

type Props = {
    collection: CollectionResponse;
};

export default function CollectionDescription({ collection, ...otherProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div>
            <h1 className="display-4 fs-2 mt-4">{collection.translatedName}</h1>
            <hr />
            <p className="lead fs-6">{collection.description}</p>
        </div>
    );
}
