/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FrameResponse } from './FrameResponse';
import {
    FrameResponseFromJSON,
    FrameResponseFromJSONTyped,
    FrameResponseToJSON,
} from './FrameResponse';
import type { SizeResponse } from './SizeResponse';
import {
    SizeResponseFromJSON,
    SizeResponseFromJSONTyped,
    SizeResponseToJSON,
} from './SizeResponse';
import type { MaterialResponse } from './MaterialResponse';
import {
    MaterialResponseFromJSON,
    MaterialResponseFromJSONTyped,
    MaterialResponseToJSON,
} from './MaterialResponse';
import type { SimplesDiscountDTO } from './SimplesDiscountDTO';
import {
    SimplesDiscountDTOFromJSON,
    SimplesDiscountDTOFromJSONTyped,
    SimplesDiscountDTOToJSON,
} from './SimplesDiscountDTO';

/**
 * 
 * @export
 * @interface CartItemDTO
 */
export interface CartItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    posterId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    posterDefaultName?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    posterTranslatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    posterImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    posterAltImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItemDTO
     */
    posterPrice?: number;
    /**
     * 
     * @type {SizeResponse}
     * @memberof CartItemDTO
     */
    selectedSize?: SizeResponse;
    /**
     * 
     * @type {FrameResponse}
     * @memberof CartItemDTO
     */
    selectedFrame?: FrameResponse;
    /**
     * 
     * @type {MaterialResponse}
     * @memberof CartItemDTO
     */
    selectedMaterial?: MaterialResponse;
    /**
     * 
     * @type {Array<SizeResponse>}
     * @memberof CartItemDTO
     */
    sizes?: Array<SizeResponse>;
    /**
     * 
     * @type {Array<FrameResponse>}
     * @memberof CartItemDTO
     */
    frames?: Array<FrameResponse>;
    /**
     * 
     * @type {Array<MaterialResponse>}
     * @memberof CartItemDTO
     */
    materials?: Array<MaterialResponse>;
    /**
     * 
     * @type {Date}
     * @memberof CartItemDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CartItemDTO
     */
    quantity?: number;
    /**
     * 
     * @type {SimplesDiscountDTO}
     * @memberof CartItemDTO
     */
    discount?: SimplesDiscountDTO;
    /**
     * 
     * @type {number}
     * @memberof CartItemDTO
     */
    totalDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDTO
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDTO
     */
    totalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItemDTO
     */
    currencySymbol?: string;
}

/**
 * Check if a given object implements the CartItemDTO interface.
 */
export function instanceOfCartItemDTO(value: object): value is CartItemDTO {
    return true;
}

export function CartItemDTOFromJSON(json: any): CartItemDTO {
    return CartItemDTOFromJSONTyped(json, false);
}

export function CartItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'itemId': json['itemId'] == null ? undefined : json['itemId'],
        'posterId': json['posterId'] == null ? undefined : json['posterId'],
        'posterDefaultName': json['posterDefaultName'] == null ? undefined : json['posterDefaultName'],
        'posterTranslatedName': json['posterTranslatedName'] == null ? undefined : json['posterTranslatedName'],
        'posterImage': json['posterImage'] == null ? undefined : json['posterImage'],
        'posterAltImage': json['posterAltImage'] == null ? undefined : json['posterAltImage'],
        'posterPrice': json['posterPrice'] == null ? undefined : json['posterPrice'],
        'selectedSize': json['selectedSize'] == null ? undefined : SizeResponseFromJSON(json['selectedSize']),
        'selectedFrame': json['selectedFrame'] == null ? undefined : FrameResponseFromJSON(json['selectedFrame']),
        'selectedMaterial': json['selectedMaterial'] == null ? undefined : MaterialResponseFromJSON(json['selectedMaterial']),
        'sizes': json['sizes'] == null ? undefined : ((json['sizes'] as Array<any>).map(SizeResponseFromJSON)),
        'frames': json['frames'] == null ? undefined : ((json['frames'] as Array<any>).map(FrameResponseFromJSON)),
        'materials': json['materials'] == null ? undefined : ((json['materials'] as Array<any>).map(MaterialResponseFromJSON)),
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'discount': json['discount'] == null ? undefined : SimplesDiscountDTOFromJSON(json['discount']),
        'totalDiscount': json['totalDiscount'] == null ? undefined : json['totalDiscount'],
        'subTotal': json['subTotal'] == null ? undefined : json['subTotal'],
        'totalPrice': json['totalPrice'] == null ? undefined : json['totalPrice'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'currencySymbol': json['currencySymbol'] == null ? undefined : json['currencySymbol'],
    };
}

export function CartItemDTOToJSON(value?: CartItemDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'itemId': value['itemId'],
        'posterId': value['posterId'],
        'posterDefaultName': value['posterDefaultName'],
        'posterTranslatedName': value['posterTranslatedName'],
        'posterImage': value['posterImage'],
        'posterAltImage': value['posterAltImage'],
        'posterPrice': value['posterPrice'],
        'selectedSize': SizeResponseToJSON(value['selectedSize']),
        'selectedFrame': FrameResponseToJSON(value['selectedFrame']),
        'selectedMaterial': MaterialResponseToJSON(value['selectedMaterial']),
        'sizes': value['sizes'] == null ? undefined : ((value['sizes'] as Array<any>).map(SizeResponseToJSON)),
        'frames': value['frames'] == null ? undefined : ((value['frames'] as Array<any>).map(FrameResponseToJSON)),
        'materials': value['materials'] == null ? undefined : ((value['materials'] as Array<any>).map(MaterialResponseToJSON)),
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'quantity': value['quantity'],
        'discount': SimplesDiscountDTOToJSON(value['discount']),
        'totalDiscount': value['totalDiscount'],
        'subTotal': value['subTotal'],
        'totalPrice': value['totalPrice'],
        'currency': value['currency'],
        'currencySymbol': value['currencySymbol'],
    };
}

